import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function ConfirmationModal ({ show, title, body, onConfirm, onCancel }) {
 
  return (
      <Modal show={show} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title className='title-text'>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <Button className='secondary-cancel-btn'  variant="secondary" onClick={onCancel}>
          Cancel
          </Button>
          <Button className='primary-confirm-btn' variant="primary" onClick={onConfirm}>
          Confirm
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default ConfirmationModal;