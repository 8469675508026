import React, { useEffect, useState, useRef } from "react";
import marker from "../../assests/image/marker-06.svg";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getProjectById, getSubProjects, getProjectFilesById, getSubcontractorByProjectId, updateProjectDescription, deleteProjectFile, addProjectDocument, renameProjectDocument } from '../../services/projectServices';
import getFileIcon from "../../shared/utils/getFileIcon.jsx";
import NotFoundComponent from '../../shared/sharedComponents/NotFoundComponent.jsx';
import ConfirmationModal from "../../shared/sharedComponents/confirmationModal.jsx";
import Business from "../../assests/image/business.svg";
import Files from "../../assests/image/file.svg";
import { toast } from "react-toastify";
import moment from "moment";

const SubProjectBasicDetails = ({ project, setProject }) => {
    const location = useParams();
    const [projectSubContractors, setProjectSubContractors] = useState([]);
    const [projectFiles, setProjectFiles] = useState([]);
    const [renameIndex, setRenameIndex] = useState(-1);
    const [deleteFileId, setDeleteFileId] = useState();
    const [fileData, setFileData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [saveButton, setSaveButton] = useState(false);
    const [descriptionEditable, setDescriptionEditable] = useState();
    const [error, setError] = useState(false);
    const [data, setData] = useState();

    const inputRefs = useRef([]);
    const textareaRef = useRef(null);
    const uploadedFileRef = useRef(null);

    useEffect(() => {
        getProjectFiles();
        getProjectSubContractors();
    }, [])

    const getProjectSubContractors = () => {
        getSubcontractorByProjectId(location.id).then(result => {
            setProjectSubContractors(result);
        }).catch(err => {
            console.log(err);
        })
    }

    const getProjectFiles = () => {
        getProjectFilesById(location.id).then(result => {
            setProjectFiles(result);
        }).catch(err => {
            console.log(err);
        })
    }

    const handleRename = (e, index) => {
        e.preventDefault();
        setRenameIndex(index);

        console.log("index", index);

        setTimeout(() => {
            const inputField = inputRefs.current[index];
            inputField.focus();
            inputField.setSelectionRange(0, 0);
        }, 100);

    }

    const deleteFile = async (e, id) => {
        e.preventDefault();
        setShowModal(true);
        setDeleteFileId(id);
    }

    const handleDelete = async () => {
        try {
            await deleteProjectFile(deleteFileId);
            toast.warn("Document Deleted", { toastId: "warn", });
            getProjectFiles();
        } catch (error) {
            console.error("Error deleting file:", error);
        }
        setShowModal(false);
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const changeOrignalFileName = (e) => {
        let newData = { ...fileData, documentName: e.target.value };
        setFileData(newData);
    }

    const handleFileUpload = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.onchange = handleFileSelect;
        fileInput.click();
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0];

        let data = {
            projectId: project.id,
            documentName: selectedFile.name,
            projectFiles: selectedFile
        }

        setFileData(data);

        setTimeout(() => {
            if (uploadedFileRef.current) {
                uploadedFileRef.current.focus();
                uploadedFileRef.current.setSelectionRange(0, 0)
            }
        }, 100)

        setSaveButton(true);
    };

    const handleSaveFile = (e, fileData) => {

        e.preventDefault();

        addProjectDocument(fileData)
            .then(res => {
                getProjectFiles();
                toast.success(`${res.documentName} Uploaded Successfully`, { toastId: "success", });
            })
            .catch(err => {
                toast.error("Error", { toastId: "error", });
                console.log(err);
            })

        setFileData(null);
        setSaveButton(false);
    }


    const handleDescription = (e) => {
        e.preventDefault();
        setDescriptionEditable(!descriptionEditable);

        if ((project.description).length > 250) {
            setProject({ ...project, description: project.description.slice(0, 250) });
            toast.warn("Project description should be less than 250 characters", {
                toastId: "warn",
            });
            return;
        }

        if (descriptionEditable) {
            let updateData = {
                id: project.id,
                description: project.description
            }
            updateProjectDescription(updateData);
        }
    }

    const handleDescriptionChange = (e) => {
        e.preventDefault();
        setProject({ ...project, description: e.target.value })
    }

    const changeFileName = (e, documentId, documentName, index) => {
        e.preventDefault();

        let newName = e.target.value;
        const updatedFiles = [...projectFiles];
        updatedFiles[index] = { ...updatedFiles[index], documentName: newName };
        setProjectFiles(updatedFiles);

        setData({
            "id": documentId,
            "documentName": newName
        })
    }

    useEffect(() => {
        if (descriptionEditable) {
            textareaRef.current.focus();
        }
    }, [descriptionEditable]);

    useEffect(() => {
        if (data) {
            const debounceTimer = setTimeout(() => {
                renameProjectDocument(data);
            }, 1000);

            return () => {
                console.log('Clearing debounce timer');
                clearTimeout(debounceTimer);
            };
        }
    }, [data])

    if (error) {
        return <NotFoundComponent />
    }


    return (<>

        <ConfirmationModal
            show={showModal}
            title="Confirm Deletion"
            body="Are you sure you want to delete this file?"
            onConfirm={handleDelete}
            onCancel={handleCancel}
        />

        <div className="project-area-client">
            <div className="d-flex">
                <div className="col-md-4 rennovation-heading border-one">
                    <p className="line-height-manage">
                        {project?.projectTitle}
                    </p>
                    <h3><img src={marker} alt="marker" className="margin-r-7" />{project?.lat}, {project?.lng}</h3>
                    <div className="address-part" >
                        <h3 className="mb-1">Sub-contrators</h3>
                    </div>
                    <div className="Sub-contrators-part">
                        {projectSubContractors && projectSubContractors.length > 0 && projectSubContractors.map((item) => {
                            return (
                                <div className="company-name" key={item?.id}>
                                    <p><img src={Business} alt="icon" />{item?.companyName}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="col-md-4 rennovation-heading border-one">
                    <div className="d-flex justify-content-between">
                        <div className="enexis">
                            <h3>Project reference</h3>
                            <p>{project?.projectReference}</p>
                        </div>
                        <div className="enexis">
                            <h3>Project owner</h3>
                            <p>{project?.projectOwner}</p>
                        </div>
                    </div>
                    <div className="attachment">
                        <h3 className="mb-1">Attachments</h3>
                        {(projectFiles && projectFiles.length > 0) || (fileData && Object.keys(fileData).length !== 0) ?
                            <div className="attachment-autoy">
                                {projectFiles && projectFiles.length > 0 && projectFiles.map((item, index) => {
                                    return <p key={item?.id} className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex w-100">
                                            {getFileIcon(item.documentPath)}
                                            <input
                                                className="input-file"
                                                disabled={renameIndex !== index}
                                                ref={el => inputRefs.current[index] = el}
                                                value={(item.documentName)}
                                                onChange={(e) => changeFileName(e, item.id, item.documentName, index)}
                                            />
                                        </div>
                                        <div className="dropdown">
                                            <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <svg width="12" height="14" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                </svg>
                                            </button>
                                            <ul className="dropdown-menu bg-white">
                                                <li><a className="dropdown-item" href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleRename(e, index);
                                                    }}
                                                >Rename</a></li>
                                                <li><a className="dropdown-item " href="#" style={{ color: "#EA503D" }} onClick={(e) => deleteFile(e, item?.id)}>Delete</a></li>
                                            </ul>
                                        </div>
                                    </p>
                                })}

                                {fileData ?
                                    <div className={`${Object.keys(fileData).length !== 0 ? 'd-block relative img-file-input' : 'd-none'}`}>
                                        <input
                                            ref={uploadedFileRef}
                                            className="form-control edit-input-file"
                                            type="text"
                                            value={fileData?.documentName}
                                            onChange={(e) => changeOrignalFileName(e)}
                                        />
                                        <img src={Files} alt="file" />
                                    </div>
                                    : null
                                }
                            </div>
                            :
                            <div className="none-attachment"></div>
                        }
                    </div>
                    {saveButton ?
                        <button className="savefile" onClick={(e) => handleSaveFile(e, fileData)}>Save</button>
                        :
                        <button className="uploadfile" onClick={(e) => handleFileUpload(e)}>Upload file</button>
                    }
                </div>
                <div className="col-md-4 rennovation-heading p-20">
                    <div className="d-flex justify-content-between">
                        <div className="enexis">
                            <h3>Project start date</h3>
                            <p className="date-font">{moment(project?.startDate).format("DD-MM-YYYY")}</p>
                        </div>
                        <div className="enexis">
                            <h3>Project end date</h3>
                            <p className="date-font">{moment(project?.endDate).format("DD-MM-YYYY")}</p>
                        </div>
                        <div className="enexis">
                            <h3>Status</h3>
                            <button className="sub-in-created-btn">{project?.actual_status}</button>
                        </div>
                    </div>
                    <div className="Description-text">
                        <div className="d-flex align-items-center justify-content-between">
                            <h3 className="mb-0">Description</h3>   
                            <button
                                className="btn btn-sm btn-light float-end"
                                onClick={(e) => handleDescription(e)}>
                                {descriptionEditable ? `Save` : `Edit`}
                            </button>
                        </div>
                        <textarea
                            style={{ height: "160px" }}
                            ref={textareaRef}
                            className="form-control"
                            type="text"
                            maxLength="250"
                            value={project?.description}
                            disabled={descriptionEditable ? false : true}
                            onChange={(e) => { handleDescriptionChange(e) }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default SubProjectBasicDetails