import { useState } from "react"
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

function ReplanFollowUpModal(props) {
    const [description, setDescription] = useState("")
    const handleProceed = () => {
        if (!description) {
            toast.warn("please provide justification", { toastId: "warn" })
            return;
        }
        props.proceedToPlanning(description)
    }
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="replan-followup-modal">
                <div className='replan-followup-modal-body'>
                    <h4>Replan a follow-up for this milestone</h4>
                    <p>Explain why the milestone should be replanned</p>
                    <div className="replan-followup-description">
                        <textarea className='w-100' onChange={(e) => setDescription(e.target.value)} />
                    </div>
                </div>

                <div className='d-flex justify-content-between replan-followup-modal-footer'>
                    <button className='btn btn-proceed-to-planning' onClick={handleProceed}>Proceed to planning</button>
                    <button className='btn btn-proceed-to-planning-cancel' onClick={props.onHide}>Cancel</button>
                </div>
            </div>
        </Modal>
    );
}

export default ReplanFollowUpModal;



