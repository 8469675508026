import React, { useState, useEffect } from "react";
import moment from 'moment';
import IconTick from "../../assests/image/icon-tick.svg";
import PlannedCheckMark from "../../assests/image/planned-chekmark.svg";
import DragIcon from '../../assests/image/drag-icon.svg';
import { toast } from "react-toastify";

function Planning({ props }) {
  const onFormValidation = props.onFormValidation;
  const subProject = props.subProject;
  const [dates, setDates] = useState([]);
  const [milestones, setMilestones] = useState(subProject.milestones);
  const [draggedMilestone, setDraggedMilestone] = useState(null);
  const [highlightedDate, setHighlightedDate] = useState(null);

  console.log("subProject", subProject);

  useEffect(() => {
    console.log("milestones-planning-props", props);
    console.log("milestones-planning", milestones);
    setDates(enumerateDaysBetweenDates(subProject.startDate, subProject.endDate));
  }, []);


  useEffect(() => {
    if (subProject.milestones && subProject.milestones.length > 0) {
      subProject.milestones.forEach((obj, index) => {
        obj['index'] = index;
      })
    }
  }, [subProject.milestones])

  const enumerateDaysBetweenDates = (startDate, endDate) => {
    var dates = [];

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    dates = addDates(dates, currDate.clone().toDate());
    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
      dates = addDates(dates, currDate.clone().toDate());
    }

    return dates;
  };

  const addDates = (dates, date) => {
    let monthIndex = dates.findIndex((item) => item.month === moment(date).format('MMMM-YYYY'));
    if (monthIndex !== -1) {
      dates[monthIndex].dates.push(date);
      return dates;
    }

    dates.push({
      month: moment(date).format('MMMM-YYYY'),
      dates: [date]
    });
    return dates;
  }

  const handleDragStart = (milestone) => {
    setDraggedMilestone(milestone);
  };

  const handleDragEnter = (date) => {
    console.log("handleDragEnter", date)
    setHighlightedDate(formateDate(date));
  };

  const handleDragLeave = () => {
    console.log("handleDragLeave");
  };

  const handleDrop = (date) => {
    if (draggedMilestone == 0 || draggedMilestone == (milestones?.length - 1)) {
      if (draggedMilestone == 0 && milestones[milestones?.length - 1]?.deliveryDate) {
        if (moment(formateDate(date)).isAfter(milestones[milestones?.length - 1]?.deliveryDate)) {
          toast("delivery date should be after pre inspection date ", { type: "warning" })
          setHighlightedDate(null)
          setDraggedMilestone(null)
          return null;
        }
      }
      if (draggedMilestone == (milestones?.length - 1) && milestones[0]?.deliveryDate) {
        if (moment(milestones[0]?.deliveryDate).isAfter(formateDate(date))) {
          setHighlightedDate(null)
          setDraggedMilestone(null)
          toast("delivery date should be after pre inspection date ", { type: "warning" })
          return null;
        }
      }
    }

    console.log('draggedMilestone', draggedMilestone, date)
    if (draggedMilestone !== null) {
      const updatedMilestones = [...milestones];
      updatedMilestones[draggedMilestone].deliveryDate = formateDate(date);
      setMilestones(updatedMilestones);
    }

    setHighlightedDate(null);
    setDraggedMilestone(null);
  };

  const formateDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  }

  useEffect(() => {
    for (let milestone of milestones) {
      if (milestone.date === null) {
        return;
      }
    }
    onFormValidation(milestones);
  }, [milestones])


  console.log("highlightedDate", highlightedDate);
  return (
    <div className="map-body">
      <div className="planing-bg">
        <div className="black-ribbon planing-top">
          <span className="form-heading">Drag milestones on the timeline</span>
        </div>
        <div className="manage-left-plan row">
          <div className="milestones-container col-md-3">
            {milestones.map(((milestone, key) => (
              <div className={`preperation-plan  ${!milestone.deliveryDate ? 'dragged-border' : ''}  mb-3 ${draggedMilestone === key ? 'dragged' : ''}`} key={key} draggable onDragStart={() => handleDragStart(key)}>
                <h3><img src={IconTick} alt="icon" />  {milestone.name}</h3>
                {milestone.deliveryDate ? <p>Planned {moment(milestone.deliveryDate).format('YYYY-MM-DD')}</p> :
                  <div className="planned">
                    <img src={DragIcon} className='lock-plain' />
                    <span> Drag on timeline to plan</span>
                  </div>}
              </div>
            )))}
          </div>
          <div className="gantt-chart-container col-md-9">
            <div className="gantt-chart">
              {dates.map((item) => (
                <div className="month-container">
                  <div className="month-name mb-2 mx-3">{item.month}</div>
                  <div className="dates-in-month">
                    {item.dates.map((date, index) => (
                      <div
                        key={index}
                        className={`single-date ${highlightedDate === formateDate(date) ? 'highlighted' : ''}`}
                        onDragEnter={() => handleDragEnter(date)}
                        onDragLeave={handleDragLeave}
                        onDrop={() => handleDrop(date)}
                        onDragOver={(e) => e.preventDefault()}
                        style={{
                          backgroundPosition: `center ${(draggedMilestone + 1) * 80 - 30}px`
                        }}
                      >
                        <span>{moment(date).format('D')}</span>
                        <div className="horizontal-line"></div>
                        <div className="planned-icons" >
                          {milestones.filter(milestone => milestone.deliveryDate === formateDate(date)).map((milestone, key) => (
                            <a href="javascript:void(0)" className="planned-date-checkmark">
                              <img src={PlannedCheckMark} style={{ marginTop: `${(milestone.index) * 80 + 20}px` }} draggable={false} alt="icon" />
                            </a>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Planning;
