import React, { useState, useEffect, useRef } from "react";
import Profile from "../../assests/image/profile.svg";
import Plus from "../../assests/image/plus-icon.svg";
import ArrowSmall from "../../assests/image/arrow-small.svg";
import {
  GoogleMap,
  Marker,
  Polygon,
  Polyline,
  useJsApiLoader,
  OverlayView
} from "@react-google-maps/api";
import Subcontractor from "../../assests/image/sub-contractor.svg";
import Upload from "../../assests/image/upload-icons.svg";
import LockIcon from "../../assests/image/lock-icon.svg";
import PlusMarker from '../../assests/image/PlusMarker.svg';
import { toast } from 'react-toastify';
import MainProject from "../../assests/image/main-project-area.svg";
import { MAP_KEY } from "../../shared/constants/mapKeyConstant";
import bin from '../../assests/image/Bin-icon.svg';


function Milestones({ props }) {

  const subProject = props.subProject;
  const addSubProjectDetails = props.addSubProjectDetails;
  const mainProjectDetails = props.mainProjectDetails;
  const condition = props.condition;
  const setMoveToPlanningBtn = props.setMoveToPlanningBtn;
  const selectedSubContractor = props.allProjectSubcontractor?.find((psc) => psc.subcontractorId == subProject.subcontractorId);
  let areaTypes = ['Point', 'Path', 'Area'];
  const projectAreaType = subProject.areaType;
  const [milestones, setMilestones] = useState([]);
  const [selectedMilestone, setSelectedMilestone] = useState(0);
  const [allMilestonesConfigured, setAllMilestonesConfigured] = useState(false);


  console.log("subProject", subProject);

  useEffect(() => {
    console.log("milestones", milestones)
  }, [])


  useEffect(() => {
    if (milestones.length > 0) {
      milestones.every(milestone => milestone.isConfigured == 1) ? setAllMilestonesConfigured(true) : setAllMilestonesConfigured(false);
    }
  }, [milestones, selectedMilestone])


  useEffect(() => {
    if (allMilestonesConfigured) {
      setMoveToPlanningBtn(true);
    } else {
      setMoveToPlanningBtn(false);
    }
  }, [allMilestonesConfigured])

  useEffect(() => {
    if (subProject) {
      if (subProject?.milestones?.length > 0) {
        setMilestones(subProject.milestones);
      } else {
        console.log("you came into else part");
        let arr = [{
          name: 'Pre inspection',
          subcontractorId: subProject.subcontractorId,
          deliveryDate: null,
          description: '',
          areaType: 'Point',
          areaLatLng: [],
          boundry: 0,
          isConfigured: 0,
          milestoneFiles: [],
        }, {
          name: 'Delivery',
          subcontractorId: subProject.subcontractorId,
          deliveryDate: null,
          description: '',
          areaType: 'Point',
          areaLatLng: [],
          boundry: 0,
          isConfigured: 0,
          milestoneFiles: [],
        }
        ]
        setMilestones(arr);
      }
    }
  }, [subProject])

  const selectMilestone = (index) => {
    //todo::make sure already open form values is valid before opening this milestone
    setSelectedMilestone(index);
  };

  useEffect(() => {
    renderMilestones()
  }, [milestones, selectedMilestone])

  useEffect(() => {
    console.log("milestones chagings", milestones);


  }, [milestones])


  const handleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target; console.log(name, value);
    let updatedMilestones = [...milestones];
    updatedMilestones[selectedMilestone][name] = value;


    if ((milestones[selectedMilestone] == milestones[0]) || (milestones[selectedMilestone] == milestones[milestones.length - 1])) {
      if ((name == 'description' || name == 'name') && value == '') {
        updatedMilestones[selectedMilestone].isConfigured = 0;
      } else {
        updatedMilestones[selectedMilestone].isConfigured = 1;
      }
    }

    if ((milestones[selectedMilestone] !== milestones[0]) && (milestones[selectedMilestone] !== milestones[milestones.length - 1])) {
      checkLatLngConfiguration(updatedMilestones)
    }

    setMilestones(updatedMilestones);
  };





  const renderMilestones = () => {
    return milestones.map((milestone, index) => {
      return (
        <>
          <div key={index} className={`Pre-inspection-box ${index === selectedMilestone ? 'active' : ''}`} onClick={() => selectMilestone(index)}>
            <div className="Pre-inspection-inner">
              <p className="required">{[0, milestone.length - 1].includes(index) ? "REQUIRED" : null}</p>
              {milestone.isConfigured ? <p className="Geconfigureerd">Configured</p> : <p className="Geconfigureerd Te-configureren">To Configure</p>}
            </div>
            <h3>{milestone.name}</h3>
            <p className="name-profile">
              <img src={Profile} alt="icon" /> {`${selectedSubContractor?.firstName} ${selectedSubContractor?.lastName}`}</p>
          </div>

          {index < milestones.length - 1 ?
            <div className="add-new-milestone" onClick={() => addNewMilestone(index + 1)} >Add new milestone
              <img src={Plus} alt="icon" />
            </div>
            : null}

        </>
      )
    })
  }

  const addNewMilestone = (index) => {
    // Add the new tab
    let updatedMilestones = [...milestones.slice(0, index), {
      name: 'New Milestone',
      subcontractorId: subProject.subcontractorId,
      deliveryDate: null,
      description: '',
      areaType: 'Point',
      // areaLatLng: [],
      areaLatLng: (subProject.areaType === "Point" && subProject.areaLatLng) ? subProject.areaLatLng : [],
      boundry: 0,
      isConfigured: 0,
      milestoneFiles: [],
    }, ...milestones.slice(index)];
    setMilestones(updatedMilestones);
    setTimeout(() => {
      selectMilestone(index);
    }, 0)
  }

  const checkValidation = () => {
    console.log("checkValidation called");
    // Check if all required fields are filled
    const requiredFields = [
      "name",
      "description",
      "areaLatLng"
    ];


    for (const field of requiredFields) {

      if ((milestones[selectedMilestone] !== milestones[0]) && (milestones[selectedMilestone] !== milestones[milestones.length - 1])) {
        if (field == "areaLatLng") {

          let updatedMilestones = [...milestones];

          if (updatedMilestones[selectedMilestone].areaType == 'Area') {
            const nonEmptyCount = updatedMilestones[selectedMilestone].areaLatLng.filter(item => item.trim() !== '').length;
            if (nonEmptyCount < 3) {
              console.log("Non empty count is less than 3");
              updatedMilestones[selectedMilestone].isConfigured = 0;
              setMilestones(updatedMilestones);
              toast.warn(`Please add coordinates`, { toastId: "warn" })
              return false;
            }
          }

          if (updatedMilestones[selectedMilestone].areaType == 'Point' || updatedMilestones[selectedMilestone].areaType == 'Path') {
            if (updatedMilestones[selectedMilestone].areaLatLng.some(item => item.trim() == '')) {
              updatedMilestones[selectedMilestone].isConfigured = 0;
              toast.warn(`Please add coordinates`, { toastId: "warn" })
              setMilestones(updatedMilestones);
              return false;
            }
          }
        }
      }

      if (!milestones[selectedMilestone][field]) {
        if (field == 'name') {
          toast.warn(`Please fill in the name`, { toastId: "warn" })
        }
        else {
          toast.warn(`Please fill in the ${field.replace(/([A-Z])/g, " $1").toLowerCase()}`, { toastId: "warn" })
        }
        milestones[selectedMilestone].isConfigured = 0;
        return false;
      } else {
        milestones[selectedMilestone].isConfigured = 1;
      }
    }


    const milestoneLength = milestones.length;
    focusNextDiv();
    if (milestoneLength > selectedMilestone + 1) {
      setSelectedMilestone(selectedMilestone + 1);
    }
    return true;
  };

  // Function to focus on the next div
  const focusNextDiv = () => {
    const divsWithClassName = document.getElementsByClassName('Pre-inspection-box');
    if (selectedMilestone + 1 == milestones.length) {
      divsWithClassName[selectedMilestone].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    } else {
      divsWithClassName[selectedMilestone + 1].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }
  };

  const onSubmit = (e) => {

    e.preventDefault();

    // Check validation before submitting the form
    if (checkValidation()) {
      let updatedMilestones = [...milestones];
      updatedMilestones[selectedMilestone].isConfigured = 1;
      setMilestones(updatedMilestones);
      addSubProjectDetails(milestones);
    }
  };

  const showHideIcon = () => {
    if (selectedMilestone != 0 && (selectedMilestone + 1 != milestones.length)) {
      return { display: "none" };
    }
  }

  const addMilestoneFiles = (files) => {
    //Below we are adding condition by which as user added file it will render on page
    console.log("files", files.target.files[0]);

    if (files.target.files[0] != undefined) {
      if (!["image/png", "image/jpeg"].includes(files.target.files[0]?.type)) {
        toast.error("Please upload valid file type");
        return;
      }

      if (milestones[selectedMilestone].milestoneFiles.length == 5) {
        toast.error("You can upload only 5 files");
        return;
      }

      let updatedMilestones = [...milestones];
      updatedMilestones[selectedMilestone].milestoneFiles.push(files.target.files[0]);
      setMilestones(updatedMilestones);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...milestones];
    console.log('updatedList', updatedList[selectedMilestone].milestoneFiles, milestones[selectedMilestone].milestoneFiles.indexOf(file))
    updatedList[selectedMilestone].milestoneFiles.splice(milestones[selectedMilestone].milestoneFiles.indexOf(file), 1);
    setMilestones(updatedList);
  }

  /*-------MAPS RELATED FUNCTION START HERE-------------*/

  if (projectAreaType == "Path") areaTypes = ['Point', 'Path']
  if (projectAreaType == "Point") areaTypes = ['Point']
  const [areaType, setAreaType] = useState(subProject.areaType || "Point");
  const [selectedInput, setSelectedInput] = useState(-1);
  const [showCloseArea, setShowCloseArea] = useState(false);
  const [showEnterCoordinate, setShowEnterCoordinate] = useState(true);
  const [showError, setShowError] = useState([]);
  const [markerInstance, setMarkerInstance] = useState(null);
  const [showOverlayView, setShowOverlayView] = useState(false);
  const [zoom, setZoom] = useState(15);
  const mapRef = useRef();
  const currentListenerRef = useRef(null);
  const polygonRef = useRef(null);
  const polylineRef = useRef(null);
  let defaultCenter = {
    lat: 22.7196,
    lng: 75.8577,
  };
  const libraries = ['places', 'drawing', 'geometry'];
  if (subProject.areaLatLng?.length) {
    let lastCoordinate = subProject.areaLatLng[subProject.areaLatLng?.length - 1]?.split(",")
    defaultCenter = {
      lat: Number(lastCoordinate[0]),
      lng: Number(lastCoordinate[1])
    }
  }
  const geometryOptions = {
    fillOpacity: 0.3,
    fillColor: "#2BD547",
    strokeColor: "#2BD547",
    strokeWeight: 5,
  };
  const mainProjectGeometryOptions = {
    fillOpacity: 0.3,
    fillColor: "#31FCE3",
    strokeColor: "#31FCE3",
    strokeWeight: 5,
  }
  const center = defaultCenter;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAP_KEY,
    libraries
  });
  const [formData, setFormData] = useState({
    projectLocation: '',
    lat: null,
    lng: null,
    areaType: '',
    areaLatLng: []
  });

  const [markerPosition, setMarkerPosition] = useState(defaultCenter);

  useEffect(() => {
    if (subProject) {
      changeAreaType(areaType);
    }
  }, [milestones.length]);

  useEffect(() => {
    if (condition == true) {
      console.log("condition", condition);
      addSubProjectDetails(milestones)
    }
  }, [condition])

  const changeAreaType = (areaType) => {
    if (currentListenerRef.current) {
      currentListenerRef.current.remove();
    }
    setShowError([]);
    setSelectedInput(-1)
    setAreaType(areaType);

    let areaLatLng = [];
    // switch (areaType) {
    //   case "Point":
    //     areaLatLng.push("");
    //     break;
    //   case "Path":
    //     areaLatLng.push("");
    //     areaLatLng.push("");
    //     break;
    //   case "Area":
    //     areaLatLng.push("");
    //     areaLatLng.push("");
    //     areaLatLng.push("");
    //     break;
    //   default:
    //     areaLatLng = [];
    //     break;
    // }


    let updatedMilestones = [...milestones];
    if (updatedMilestones?.length) {
      updatedMilestones[selectedMilestone].areaType = areaType;
      updatedMilestones[selectedMilestone].areaLatLng = areaLatLng;
      setMilestones(updatedMilestones);
      updatedMilestones[selectedMilestone].isConfigured = 0;
      // checkLatLngConfiguration(updatedMilestones);
    }
  };

  //Below function is created to edit a input field
  const editInputField = (index, milestone) => {
    setSelectedInput(index);
    if (currentListenerRef.current) {
      currentListenerRef.current.remove();
    }

    currentListenerRef.current = mapRef.current.addListener('click', (event) => {
      // Retrieve the latitude and longitude of the clicked location
      const clickedLatLng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };

      //add marker on clicked position
      setMarkerPosition(clickedLatLng)
      updateMarkerPosition(clickedLatLng);
      addLatLng(`${event.latLng.lat().toFixed(5)}, ${event.latLng.lng().toFixed(5)}`, index, milestone);
      // Do something with the clicked latitude and longitude
      // You can perform any action you want with the latitude and longitude here
    })


    //Below functionality help as us to focus on input field 
    let input = document.getElementById(`latLngInput${index}`);
    if (input) {
      input.focus();
    } else {
      console.error("Input field with ID '" + input + "' not found.");
    }
  }

  const addInputField = (e, milestone) => {
    e.preventDefault();
    let updatedMilestones = [...milestones];
    updatedMilestones[selectedMilestone].areaLatLng.push("");
    setMilestones(updatedMilestones);
    setShowCloseArea(true);
    onFocus();
  };

  const onFocus = (index) => {
    if (!index) {
      var index = milestones[selectedMilestone].areaLatLng?.length - 2;
    }
    let input = document.getElementById(`latLngInput${index}`);
    if (input) {
      input.focus();
    } else {
      console.error("Input field with ID '" + input + "' not found.");
    }
  };


  const addLatLng = (latLng, index, type) => {

    console.log("latLng function called", areaType, index);

    const latLngString = latLng;
    const [lat, lng] = latLngString.split(", ");
    const latLngObject = {
      lat: parseFloat(lat),
      lng: parseFloat(lng)
    };
    if (type === "editInput") {
      if (latLngInsideSubProjectvalidation(latLngObject)) {
        let updatedMilestones = [...milestones];
        updatedMilestones[selectedMilestone].areaLatLng[index] = latLng;
        setMilestones(updatedMilestones);
      }
    } else {
      let updatedMilestones = [...milestones];
      updatedMilestones[selectedMilestone].areaLatLng[index] = latLng;
      setMilestones(updatedMilestones);

    }
  };


  useEffect(() => {
    if (formData && path && path.length > 0) {
      calculateArea(path);
    }
  }, [formData])

  // By below we can are able to get area of polygon
  const calculateArea = (polygonCoordiantes) => {

    switch (areaType) {
      case 'Area':
        if (window.google) {
          const area = window.google.maps.geometry.spherical.computeArea(polygonCoordiantes);
          setFormData({ ...formData, boundry: area });

        } else {
          console.error('Google Maps API not loaded');
        }
        break
      case 'Path':
        if (window.google && path) {
          const pathLength = window.google.maps.geometry.spherical.computeLength(
            path.map(point => new window.google.maps.LatLng(point.lat, point.lng))
          );
          setFormData({ ...formData, boundry: pathLength });

        } else {
          console.error('Google Maps API not loaded');
        }
        break
      case 'Point':
        if (window.google && path) {
          setFormData({ ...formData, boundry: null });
        } else {
          console.error('Google Maps API not loaded');
        }
        break
      default:
        return null;
    }
  };



  const onSave = (e, index) => {
    setSelectedInput(-1);
    currentListenerRef.current.remove();

    let latLng = document.getElementById(`latLngInput${index}`).value;

    if (latLng && latLng != '' && latLng !== null) {
      let validation = validationCheck(latLng);
      if (!validation) {
        console.error("error: Validate lat lng");
        setShowError(current => [...current, index])
      } else {
        setShowError(oldValues => {
          return oldValues.filter(showError => showError !== index)
        })
      }
    }

    // Remove the marker instance when the user saves the input field
    if (markerInstance) {
      markerInstance.setMap(null);
      setMarkerInstance(null);
    }
  }

  //Validation function validate Lat Lng provided to it
  //regex is such that string should contain two dots and one comma
  const validationCheck = (latLng) => {
    const regex = /^(?=.*?,)(?=(?:[^.]*?\.){2}[^.]*?$).*$/;
    return regex.test(latLng);
  };

  const closeArea = (e) => {
    e.preventDefault();
    setShowCloseArea(false);
    setShowEnterCoordinate(false);
  };

  useEffect(() => {
    setShowCloseArea(true);
    setShowEnterCoordinate(true);
  }, [areaType, selectedMilestone])

  const checkLatLngConfiguration = (milestones) => {
    console.log("checkLatLngConfiguration called");

    let updatedMilestones = [...milestones];

    const requiredFields = [
      "name",
      "description",
    ];

    if ((milestones[selectedMilestone] !== milestones[0]) && (milestones[selectedMilestone] !== milestones[milestones.length - 1])) {
      if (updatedMilestones[selectedMilestone].areaType == 'Area') {
        const nonEmptyCount = updatedMilestones[selectedMilestone].areaLatLng.filter(item => item.trim() !== '').length;
        if (nonEmptyCount < 3) {
          console.log("Non empty count is less than 3");
          updatedMilestones[selectedMilestone].isConfigured = 0;
          return setMilestones(updatedMilestones);
        }
      }

      if (updatedMilestones[selectedMilestone].areaType == 'Point') {
        if (updatedMilestones[selectedMilestone].areaLatLng.length == 0) {
          console.log('you came into if part');
          updatedMilestones[selectedMilestone].isConfigured = 0;
          return setMilestones(updatedMilestones);
        }
      }
      if (updatedMilestones[selectedMilestone].areaType == 'Path' && updatedMilestones[selectedMilestone].areaLatLng?.length < 2) {
        updatedMilestones[selectedMilestone].isConfigured = 0;
        return setMilestones(updatedMilestones);
      }
    }

    for (const field of requiredFields) {
      if (!updatedMilestones[selectedMilestone][field]) {
        updatedMilestones[selectedMilestone].isConfigured = 0;
        return setMilestones(updatedMilestones);
      }
    }

    updatedMilestones[selectedMilestone].isConfigured = 1;
    setMilestones(updatedMilestones);
  }


  const checkConfiguration = (updatedMilestones) => {

    if (updatedMilestones[selectedMilestone].areaType == 'Area') {
      const nonEmptyCount = updatedMilestones[selectedMilestone].areaLatLng.filter(item => item.trim() !== '').length;
      if (nonEmptyCount < 3) {
        updatedMilestones[selectedMilestone].isConfigured = 0;
      }
    } else {
      if (updatedMilestones[selectedMilestone].areaLatLng.some(item => item.trim() == '')) {
        console.log('you came into if part');
        updatedMilestones[selectedMilestone].isConfigured = 0;
      }
    }

    setMilestones(updatedMilestones);
  }

  const clearInputField = (e, index) => {
    e.preventDefault();

    console.log('Clear input field called');

    let updatedMilestones = [...milestones]
    updatedMilestones[selectedMilestone].areaLatLng?.splice(index, 1)
    // updatedMilestones[selectedMilestone].areaLatLng[index] = "";
    checkConfiguration(updatedMilestones);
    setMilestones(updatedMilestones);

    //After clearing data from input field we will remove error, as there no lat,lng which we have to validate
    setShowError((oldValues) => {
      return oldValues.filter((showError) => showError !== index);
    });
  };


  const latLngInsideSubProjectvalidation = (latLngObject) => {

    if (subProject.areaType == "Area") {
      let existingArea = new window.google.maps.Polygon({ paths: subProjectPath });
      const isInside = window.google.maps.geometry.poly.containsLocation(latLngObject, existingArea)
      if (!isInside) {
        toast("Please locate coordinates inside area", { type: "warning" });
        return false;
      }
      return true;
    }
    else if (subProject.areaType == "Path") {
      let existingLine = new window.google.maps.Polyline({ path: subProjectPath });
      //  0.00001 is value that determine how much far point will considered on polyline
      const isInside = window.google.maps.geometry.poly.isLocationOnEdge(latLngObject, existingLine, 0.00001)
      if (!isInside) {
        toast("Please locate coordinates near line", { type: "warning" })
        return false;
      }
      return true;
    } else if (subProject.areaType == "Point") {
      let distance = window.google.maps.geometry.spherical.computeDistanceBetween(latLngObject, center);
      if (distance > 100) {
        toast("Please locate coordinates near Point", { type: "warning" })
        return false;
      }
      return true;
    }
  };

  const onLoadMap = (map) => {
    map.setMapTypeId("satellite");
    mapRef.current = map;
    map.setOptions({
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: false,
      mapTypeControlOptions: {
        mapTypeIds: [], // To remove options of map view
      },
    });
  };

  const containerStyle = {
    width: "100%",
    height: "100vh",
  };

  const updateMarkerPosition = (latLng) => {
    if (markerInstance) {
      markerInstance.setPosition(latLng);
    }
  }

  const getCoordinates = () => {
    if (subProject && milestones[selectedMilestone]?.areaLatLng.length > 0) {
      return milestones[selectedMilestone].areaLatLng
        .map((latlng, index) => {
          if (typeof latlng === "string") {
            const [lat, lng] = latlng.split(", ");
            if (lat && lng) {
              return { lat: parseFloat(lat), lng: parseFloat(lng) };
            }
          }
          return null;
        })
        .filter(Boolean);
    }
    return [];
  };

  // Call getCoordinates to retrieve path
  const path = getCoordinates();

  const getOverlayCoordinates = () => {
    if (mainProjectDetails.areaLatLng.length > 0) {
      return mainProjectDetails.areaLatLng
        .map((latlng, index) => {
          if (typeof latlng === "string") {
            const [lat, lng] = latlng.split(", ");
            if (lat && lng) {
              return { lat: parseFloat(lat), lng: parseFloat(lng) };
            }
          }
          return null;
        })
        .filter(Boolean);
    }
    return [];
  }

  const overlayPath = getOverlayCoordinates();

  const getSubProjectCoordinates = () => {
    if (subProject?.areaLatLng?.length > 0) {
      return subProject.areaLatLng
        .map((latlng, index) => {
          if (typeof latlng === "string") {
            const [lat, lng] = latlng.split(", ");
            if (lat && lng) {
              return { lat: parseFloat(lat), lng: parseFloat(lng) };
            }
          }
          return null;
        })
        .filter(Boolean);
    }
    return [];
  }

  const subProjectPath = getSubProjectCoordinates();

  useEffect(() => {
    if (markerInstance) {
      setShowOverlayView(true)
    }
  }, [markerInstance])

  useEffect(() => {
    if (milestones) {
      //We are nulify markerInstance here to remove ballon/marker
      setTimeout(() => {
        if (markerInstance) {
          markerInstance.setMap(null);
          setMarkerInstance(null);
        }
      }, [5000]);
    }
  }, [milestones]);


  useEffect(() => {
    if (formData) {
      validateConfirmProjectAreaButton();
    }
  }, [formData, showError, areaType]);

  //Below function help us to validate Confirm-Project-Area-Button i.e. when to disable and when to enable it
  const validateConfirmProjectAreaButton = () => {
    let isValid = false;
    switch (areaType) {
      case "Point":
        isValid =
          formData.areaLatLng.length == 1 &&
          showError.length == 0 &&
          formData.areaLatLng.every((value) => value.trim() !== "");
        break;

      case "Path":
        isValid =
          formData.areaLatLng.length >= 2 &&
          showError.length == 0 &&
          formData.areaLatLng.every((value) => value.trim() !== "");
        break;

      case "Area":
        //In case of areaType=area we checking starting 3 values of array i.e. they should not be blank
        isValid =
          formData.areaLatLng.length >= 3 &&
          showError.length == 0 &&
          formData.areaLatLng.slice(0, 3).every((value) => value.trim() !== "");
        break;

      default:
        break;
    }
  };

  const handleZoomChanged = () => {
    if (mapRef.current) {
      addSubProjectDetails({ currentZoom: mapRef.current.getZoom() })
    }
  };

  const getOverviewBounds = (path) => {
    const bounds = new window.google.maps.LatLngBounds();
    path.forEach((point) => bounds.extend(point));
    return bounds;

  }

  const handleMapClick = (event) => {
    console.log("handleMapClick called")
    const clickedLatLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };

    if (subProject.areaType == "Area") {
      let existingArea = new window.google.maps.Polygon({ paths: subProjectPath });
      const isInside = window.google.maps.geometry.poly.containsLocation(clickedLatLng, existingArea)
      if (!isInside) {
        toast("Please locate coordinates inside area", { type: "warning" });
        return false;
      }
    }
    else if (subProject.areaType == "Path") {
      let existingLine = new window.google.maps.Polyline({ path: subProjectPath });
      //  0.00001 is value that determine how much far point will considered on polyline
      const isInside = window.google.maps.geometry.poly.isLocationOnEdge(clickedLatLng, existingLine, 0.00001)
      if (!isInside) {
        toast("Please locate coordinates near line", { type: "warning" })
        return false;
      }
    } else if (subProject.areaType == "Point") {
      let distance = window.google.maps.geometry.spherical.computeDistanceBetween(clickedLatLng, center);
      if (distance > 100) {
        toast("Please locate coordinates near Point", { type: "warning" })
        return false;
      }
    }
    console.log("after return")
    if (milestones[selectedMilestone]?.areaType === "Point") {
      setMarkerPosition(clickedLatLng)
      addLatLng(`${clickedLatLng.lat?.toFixed(5)}, ${clickedLatLng.lng?.toFixed(5)}`, 0, formData);
    } else if (milestones[selectedMilestone]?.areaType === "Path") {
      setMarkerPosition(clickedLatLng)
      addLatLng(`${clickedLatLng.lat?.toFixed(5)}, ${clickedLatLng.lng?.toFixed(5)}`, milestones[selectedMilestone]?.areaLatLng?.length);
    } else if (milestones[selectedMilestone]?.areaType === "Area") {
      setMarkerPosition(clickedLatLng)
      addLatLng(`${clickedLatLng.lat?.toFixed(5)}, ${clickedLatLng.lng?.toFixed(5)}`, milestones[selectedMilestone]?.areaLatLng?.length);
    }
  }

  /*-------MAPS RELATED FUNCTION END HERE-------------*/

  return (
    <>
      <div className="mt-54">
        <div className="scroll-lines">
          {renderMilestones()}
        </div>
      </div>

      <form onSubmit={(e) => onSubmit(e)}>

        <div className="configure-milestone">
          <div>
            <p className="configuremilestone-text">Configure Milestone</p>
            <div className="d-flex align-items-center">
              <h3 className="my-0">{milestones[selectedMilestone]?.name}</h3>
              {
                !milestones[selectedMilestone]?.isConfigured ? <p className="pre-inspection Te-configureren my-0">Unfinished</p> :
                  <p className="pre-inspection my-0">Finished</p>
              }

            </div>
          </div>
          {milestones[selectedMilestone] !== milestones[milestones.length - 1] ?
            <div className="configuew-next-btn" onClick={() => checkValidation()}>Configure next milestone
              <img src={ArrowSmall} alt="icon" />
            </div>
            :
            null}
        </div>

        {/* <MilestonesForm milestones={milestones} selectedMilestone={selectedMilestone} setMilestones={setMilestones} /> */}

        <div className="row mb-5">
          <div className="col-md-6">
            <div class="mb-24 relative">
              <label className="form-label">Subject <span className="required-text">REQUIRED</span></label>
              <input className="form-control relative dark-inspection" onChange={(e) => { handleChange(e) }} name="name" placeholder="New milestone" value={milestones[selectedMilestone]?.name} readOnly={[0, milestones.length - 1].includes(selectedMilestone)} />
              <img src={LockIcon} alt="icon" className="lock-right" style={showHideIcon()} />
            </div>
            <div class="relative-select mb-24">
              <label className="form-label">Subcontractor</label>
              <input className="form-control" value={`${selectedSubContractor?.firstName} ${selectedSubContractor?.lastName}`} aria-label="Default select example" readOnly={true} />
              <img src={Subcontractor} alt="img" className="icon-select leftmanage" />
            </div>
            <div class="mb-0">
              <label className="form-label d-block">Additional documents</label>
              <div className="d-flex gap-3">
                <div className="upload-file">
                  <img src={Upload} alt="icon" /> Upload files
                </div>
                <input type="file" name="milestoneFiles" onChange={(files) => addMilestoneFiles(files)} className="upload-milestone-file" />
                {
                  //In case of componentId == 3 i.e. ProjectMap, Below part will be hidden
                  (milestones[selectedMilestone]?.milestoneFiles.length > 0) ? (
                    <div className="w-80">
                      {
                        milestones[selectedMilestone]?.milestoneFiles.map((item, index) => (
                          <div key={index} className="">
                            <div className="upload-files-multi">
                              <span>{item.name}</span>
                              < span className="" onClick={() => fileRemove(item)}>
                                <img src={bin} alt="" />
                              </span>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  ) : null
                }
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div class="mb-24 w-100">
              <label className="form-label">Instruction <span className="required-text">REQUIRED</span></label>
              {/* <div className="instruction h-100"></div> */}
              <textarea className="instruction h-100" name="description" onChange={(e) => { handleChange(e) }} value={milestones[selectedMilestone]?.description} />
            </div>
          </div>
        </div>

        {(milestones[selectedMilestone] == milestones[0]) || (milestones[selectedMilestone] == milestones[milestones.length - 1]) || subProject.areaType === "Point" ?
          <div class="col-md-12">
            {/* <label className="form-label d-block">Area</label> */}
            <div className="Pre-inspection">
              Pre-inspection covers the entire subproject area
            </div>
          </div>
          :
          <>
            {/* <div className="col-md-6">
              <div className="single-pointtab">
                <button className="btn btn-lg active" onClick={(e) => e.preventDefault()}>Single point</button>
                <button className="btn btn-lg" onClick={(e) => e.preventDefault()}>Path</button>

              </div>
            </div> */}
            {/* ----------------------------------MAP STARTS FROM HERE-------------------------------------------------- */}

            {milestones.map((milestone, index) => (
              (selectedMilestone === index) ?
                <div className='map-body' key={index}>
                  <div className="map-container">
                    {isLoaded
                      ?
                      <GoogleMap
                        key={index}
                        zoom={zoom}
                        center={center}
                        onLoad={onLoadMap}
                        mapContainerStyle={containerStyle}
                        onClick={handleMapClick}
                      // onTilesLoaded={() => setCenter(null)}
                      >

                        <div className="dark-overlay"></div>
                        {
                          (subProject.areaType == "Point") ?
                            <Marker
                              position={center}
                            /> : null
                        }

                        <Marker
                          position={markerPosition}
                          icon={{
                            url: PlusMarker,
                            anchor: new window.google.maps.Point(12, 12),
                            origin: new window.google.maps.Point(0, 0),
                            scaledSize: new window.google.maps.Size(24, 24)
                          }}
                        />
                        {/* Rendering the Polyline*/}
                        {milestone.areaType === "Path" && (
                          <Polyline
                            path={path}
                            options={{ ...geometryOptions, zIndex: 1000, editable: true }}
                            onMouseUp={(event) => {
                              const newPaths = polylineRef.current.getPath().getArray().map(coord => (
                                `${coord.lat()?.toFixed(5)}, ${coord.lng()?.toFixed(5)}`
                              ));
                              let updatedMilestones = [...milestones];
                              updatedMilestones[selectedMilestone].areaLatLng = newPaths;
                              setMilestones(updatedMilestones);

                              console.log(newPaths)
                              console.log(event, "polygon event")
                            }}
                            onLoad={(poly) => {
                              polylineRef.current = poly
                            }}
                          />
                        )}

                        {/* Rendering the Polygon */}
                        {milestone.areaType === "Area" && (
                          <Polygon
                            paths={path}
                            options={{ ...geometryOptions, zIndex: 1000, editable: true }}
                            onMouseUp={(event) => {
                              const newPaths = polygonRef.current.getPath().getArray().map(coord => (
                                `${coord.lat()?.toFixed(5)}, ${coord.lng()?.toFixed(5)}`
                              ));
                              let updatedMilestones = [...milestones];
                              updatedMilestones[selectedMilestone].areaLatLng = newPaths;
                              setMilestones(updatedMilestones);
                              console.log(newPaths)
                              console.log(event, "polygon event")
                            }}
                            onLoad={(poly) => {
                              polygonRef.current = poly
                            }}
                          />
                        )}
                        {(subProject.areaType == "Path") &&
                          (<Polyline path={subProjectPath}
                            options={{ ...mainProjectGeometryOptions, zIndex: 100, clickable: true }}
                            onClick={(event) => {
                              console.log("clicked path")
                              handleMapClick(event)
                              // const clickedLatLng = {
                              //   lat: event.latLng.lat(),
                              //   lng: event.latLng.lng()
                              // };
                              // setMarkerPosition(clickedLatLng);
                              // updateMarkerPosition(clickedLatLng);
                              // addLatLng(`${event.latLng.lat().toFixed(5)}, ${event.latLng.lng().toFixed(5)}`, selectedInput);
                              checkLatLngConfiguration(milestones);
                            }}
                          />)
                        }
                        {(subProject.areaType == "Area") &&
                          (<Polygon paths={subProjectPath}
                            options={{ ...mainProjectGeometryOptions, zIndex: 100, clickable: true }}
                            onClick={(event) => {
                              handleMapClick(event)
                              // const clickedLatLng = {
                              //   lat: event.latLng.lat(),
                              //   lng: event.latLng.lng()

                              // };

                              //add marker on clicked position
                              // setMarkerPosition(clickedLatLng);
                              // updateMarkerPosition(clickedLatLng);
                              // addLatLng(`${event.latLng.lat().toFixed(5)}, ${event.latLng.lng().toFixed(5)}`, selectedInput, milestone);
                              checkLatLngConfiguration(milestones);
                            }}
                          />)
                        }
                        <OverlayView
                          bounds={getOverviewBounds(overlayPath)}
                          mapPaneName={OverlayView.OVERLAY_LAYER}
                          getPixelPositionOffset={(width, height) => ({
                            // x: -width / 2,
                            // y: -height / 2,
                            x: 0,
                            y: 0
                          })}
                        >
                          <div
                            style={{
                              transform: `rotate(${mainProjectDetails.imageRotation}deg) scale(${1})`,
                              cursor: "pointer",
                              position: "absolute",
                              top: `${mainProjectDetails.imageTop}%`,
                              left: `${mainProjectDetails.imageLeft}%`,
                              width: `${mainProjectDetails.imageSize}%`,
                              height: `${mainProjectDetails.imageSize}%`,
                              backgroundImage: `url(${process.env.REACT_APP_BASE_URL + "/" + mainProjectDetails.projectMap})`,
                              backgroundSize: 'contain',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat'
                            }}
                          >
                          </div>
                        </OverlayView>
                        <div className="d-flex justify-content-between">
                          <div className="Legend">
                            <h3>Legend</h3>
                            <p className="d-flex align-items-center"><span className="blue-line"></span> Subproject area</p>
                            <p className="d-flex align-items-center"><span className="green-line"></span> Milestone area</p>
                          </div>

                          <div className="map-zoom">
                            <button type="button" className="btn zoom-btn" onClick={(e) => { e.preventDefault(); setZoom(zoom + 1) }}>
                              +
                            </button>

                            <button type="button" className="btn zoom-btn" onClick={(e) => { e.preventDefault(); setZoom(zoom - 1) }}>
                              -
                            </button>
                          </div>

                        </div>

                        <div className="map-area map-overlay">
                          <div className="yellow-line green-line"></div>
                          <span className='form-heading'>
                            <img src={MainProject} alt='icon' />
                            Milestone area
                          </span>

                          <label className="mt-3 d-block form-label area-type">Area type</label>
                          <div className="area-tabs">
                            {areaTypes.map(areaType => (
                              <button
                                key={areaType}
                                type="button"
                                className={`btn btn-lg ${milestone.areaType === areaType ? 'active' : ''}`}
                                onClick={() => changeAreaType(areaType)}
                              >
                                {areaType}
                              </button>
                            ))}
                          </div>
                          <div className={milestone.areaType !== "Point" ? `overflow` : null}>
                            <label className="mt-4 d-block form-label">Define milstone area</label>

                            {milestone.areaLatLng.map((latLng, index) => (
                              <div
                                key={index}
                                className={`poi-input ${latLng == "" ? "empty" : null} ${index === selectedInput ? "editable" : ""
                                  } ${showError.includes(index) ? "show-error" : null}`}
                              >
                                {console.log("latlng", latLng)}
                                <span className="prefix">
                                  {(() => {
                                    switch (milestone.areaType) {
                                      case "Point":
                                        return "POI";
                                      case "Path":
                                        return `POINT ${index + 1}`;
                                      // switch (index) {
                                      //     case 0:
                                      //       return "PATH A";
                                      //     case 1:
                                      //       return "PATH B";
                                      //     default:
                                      //       return null;
                                      //   }
                                      case "Area":
                                        return `POINT ${index + 1}`;
                                      default:
                                        return null;
                                    }
                                  })()}

                                  {latLng ? <span className="dot-circle"></span> : null}
                                </span>

                                <input
                                  type="text"
                                  id={`latLngInput${index}`}
                                  value={milestone.areaLatLng[index]}
                                  name="latLng"
                                  autocomplete="off"
                                  onChange={(e) => {
                                    addLatLng(e.target.value, index, "editInput");
                                    handleChange(e);
                                  }}

                                />
                                <button
                                  type="button"
                                  onClick={(e) => onSave(e, index)}
                                  className="btn save-marker-btn"
                                >
                                  <span class="bi bi-save"></span>
                                </button>
                                <button
                                  type="button"
                                  // onClick={() => editInputField(index, milestone)}
                                  onClick={() => setSelectedInput(index)}
                                  className="btn enable-marker-btn"
                                >
                                  Locate on map or enter coordinates
                                </button>
                                <button
                                  type="button"
                                  // onClick={() => editInputField(index, milestone)}
                                  onClick={() => setSelectedInput(index)}
                                  className="btn edit-btn"
                                >
                                  <span class="bi bi-pencil"></span>
                                </button>
                                <button
                                  type="button"
                                  onClick={(e) => clearInputField(e, index)}
                                  className="btn delete-btn"
                                >
                                  <span class="bi bi-x"></span>
                                </button>
                              </div>
                            ))}
                          </div>
                          <div className="d-flex gap-2">
                            {/* Button to add input field */}
                            {milestone.areaType !== "Point" && showEnterCoordinate && (
                              <button
                                type="button"
                                className="btn main-project-area"
                                onClick={(e) => addInputField(e, milestone)}
                              >
                                <i className="bi bi-plus-circle"></i>
                                Enter coordinates
                              </button>
                            )}

                            {/* Button to close area */}
                            {milestone.areaType === "Area" && showCloseArea && (
                              <button
                                type="button"
                                className="btn main-project-area"
                                onClick={(e) => closeArea(e)}
                              >
                                <i className="bi bi-flag-fill"></i>
                                Close area
                              </button>
                            )}
                          </div>
                        </div>
                      </GoogleMap>
                      :
                      null}
                  </div>
                </div>
                :
                null)
            )
            }
            {/* ----------------------------------MAP ENDS FROM HERE-------------------------------------------------- */}
          </>
        }
      </form>
    </>
  );
}

export default Milestones;
