import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Mail from "../../assests/image/mail.svg";
import Quotes from "../../assests/image/quotes.svg";
import RightArrow from "../../assests/image/right-arrow.svg";
import chevronLeft from "../../assests/image/chevron-left.svg";
import chevronRight from "../../assests/image/chevron-right.svg";
import {
  createSubcontractor,
  getSubcontractorType,
} from "../../services/projectServices";
import Sidebar from "../../shared/sharedComponents/sidebar";
import SubContractorCompleteDetails from "./subContractorCompleteDetails";

function CreateSubContractor() {
  const [activeComponent, setActiveComponent] = useState(1);
  const [companyFormIsVaild, setCompanyFormIsVaild] = useState(false);
  const [subContractorType, setSubContractorType] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [showDetailpage, setShowDetailPage] = useState(false);
  const [subContractor, setSubContractor] = useState({
    type: null,
    companyName: "",
    companyEmail: "",
    companyPhone: "",
    declaration: false,
    firstName: "",
    lastName: "",
    email: "",
    phone: null,
    subject: "",
    message: "",
    inviteVia: "",
  });

  useEffect(() => {
    console.log("subContractor", subContractor);
  }, [subContractor])

  useEffect(() => {
    getSubcontractorType().then((result) => {
      setSubContractorType(result);
    });
  }, []);

  const handleEditClick = (e) => {
    e.preventDefault()
    setIsReadOnly((prevState) => !prevState);
  };

  
  const onSubmit = (e) => {
    e.preventDefault();

    // Check validation before submitting the form
    if (checkValidation()) {
      switch (activeComponent) {
        case 1:
          setCompanyFormIsVaild(true);
          break;
        case 2:
          setSubContractor((prevState) => ({
            ...prevState,
            subject: `Invitation to download the InfrAI Civil`,
            message: `Hi ${prevState.firstName},\\n\\nYou have been invited by DENYS to download the Xpect App. Follow the steps below to create a company profile, Good Luck !!\\n\\nStep 1: Download the Xpect App : https://apps.google.com/app/Xpectapp/id12345678\\n\\n Step 2: Follow this link to create a company profile : https://xpectapp.com/initial/login \\n\\nBest Regards, \\nH. Yildiz`,
          }));
          break;
        case 3:
          handleFinalSubmission();
          return;
        default:
          break;
      }
      setActiveComponent(activeComponent + 1);
    }
  };

  const handleFinalSubmission = () => {
    createSubcontractor(subContractor).then((result) => {
      toast.success(`Sub-Contractor Created Successfully !!!`, {
        toastId: "success",
      });
      setShowDetailPage(true);
    }).catch((err) => {
      toast.warn(`${err.response.data.message}`, {
        toastId: "warn",
      });
    });
  };


  const checkValidation = () => {

    console.log("check validation called")

    let requiredFields = [];
    if (activeComponent == 1) {
      requiredFields = ["type", "companyName"];
    } else if (activeComponent == 2) {
      requiredFields = ["firstName", "lastName", "email", "phone"];
    } else if (activeComponent == 3) {
      requiredFields = ["subject", "message", "inviteVia"];
    }

    for (const field of requiredFields) {
      if (!subContractor[field]) {
        toast.warn(
          `Please fill in the ${field
            .replace(/([A-Z])/g, " $1")
            .toLowerCase()}`,
          { toastId: "warn" }
        );
        return false;
      }
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setSubContractor((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {

    let requiredFields = [];
    if (activeComponent == 1) {
      requiredFields = ["type", "companyName"];
    } else if (activeComponent == 2) {
      requiredFields = ["firstName", "lastName", "email", "phone"];
    } else if (activeComponent == 3) {
      requiredFields = ["subject", "message", "inviteVia"];
    }

    for (const field of requiredFields) {
      if (subContractor[field]) {
        setCompanyFormIsVaild(true);
      } else {
        setCompanyFormIsVaild(false);
        return;
      }
    }

  }, [subContractor, activeComponent])

  const changeActiveComponent = (index) => {

    setActiveComponent(index);
  };

  return (
    <>

      <Sidebar />
      {!showDetailpage ?
        <main id="main" className="main">
          <div className="pagetitle">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Sub-contractors </a>
                </li>
                <li className="breadcrumb-item"></li>
                <li className="active">Add a sub-contractor</li>
              </ol>
            </nav>
          </div>
          <div className="page-content">
            <div className="row">
              <div className="col-md-12">
                <h3 className="page-title">Add a sub-contractor</h3>
                <div className="tab-container my-4 mx-0">
                  <button
                    type="button"
                    className={`btn btn-default ${activeComponent === 1 ? "active" : ""
                      }`}
                    onClick={() => changeActiveComponent(1)}
                  >
                    Company details
                  </button>
                  <button
                    type="button"
                    className={`btn btn-default ${activeComponent === 2 ? "active" : ""
                      }`}
                    disabled={!companyFormIsVaild 
                      // || !projectFormIsVaild
                    }
                    onClick={() => changeActiveComponent(2)}
                  >
                    Contact person
                  </button>
                  <button
                    type="button"
                    className={`btn btn-default ${activeComponent === 3 ? "active" : ""
                      }`}
                    disabled={
                      !companyFormIsVaild 
                      // ||
                      // !projectFormIsVaild
                      //  ||
                      // !contactFormIsVaild
                    }
                    onClick={() => changeActiveComponent(3)}
                  >
                    Invitation
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="map-body background-slider">
            {(() => {
              switch (activeComponent) {
                case 1:
                  return (
                    <div className="item">
                      <div className="company-detail">
                        <h2>Company details</h2>
                        <p>
                          Provide some general contact information of the
                          subcontractor for administrative purposes and in case in
                          case that you are unable to get in touch with the
                          contact person of the sub-contractor
                        </p>
                        <form onSubmit={(e) => onSubmit(e)}>
                          {/* Subcontractor Type */}
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <label className="form-label">*Type </label>
                              <select
                                className="form-select"
                                name="type"
                                aria-label="Default select example"
                                value={subContractor.type}
                                onChange={(e) => handleChange(e)}
                              >
                                <option value="">Select</option>
                                {subContractorType &&
                                  subContractorType.length > 0 &&
                                  subContractorType.map((subContract, key) => (
                                    <>
                                      <option key={key} value={subContract.id}>
                                        {subContract.name}
                                      </option>
                                    </>
                                  ))}
                              </select>
                            </div>
                          </div>
                          {/* Company name */}
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <label className="form-label">*Company name</label>
                              <input
                                className="form-control"
                                name="companyName"
                                value={subContractor.companyName}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                          {/* Corporate email */}
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <label className="form-label">
                                Corporate email{" "}
                                <span className="optional-text">optional</span>
                              </label>
                              <input
                                className="form-control"
                                name="companyEmail"
                                type="email"
                                value={subContractor.companyEmail}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                          {/*Corporate phone number */}
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <label className="form-label">
                                Corporate phone number{" "}
                                <span className="optional-text">optional</span>
                              </label>
                              <input
                                className="form-control"
                                name="companyPhone"
                                value={subContractor.companyPhone}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <label
                                for="declaration"
                                className="d-flex align-items-center justify-content-start checkbox-border"
                              >
                                <input
                                  type="checkbox"
                                  id="declaration"
                                  name="declaration"
                                  checked={subContractor.declaration}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setSubContractor((prevFormData) => ({
                                      ...prevFormData,
                                      declaration: e.target.checked,
                                    }));
                                  }}
                                />

                                <span className="declaration-text">
                                  I hereby grant permission to the sub-contractor,
                                  allowing them access to specific licenses and
                                  certificates as required
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="row configure-project">
                            <div className="col-md-12 text-center">
                              <button
                                type="submit"
                                className="btn-primary btn-lg w-100 next-btn-slide"
                              >
                                <div className="d-flex align-items-center">
                                  Next<img src={RightArrow} alt="icon" />
                                </div>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  );
                case 2:
                  return (
                    <div className="item">
                      <div className="company-detail">
                        <h2>Contact persons</h2>
                        <p>
                          Do you already want to assign the sub-contractor to some
                          of your (sub)projects?
                        </p>
                        <form onSubmit={(e) => onSubmit(e)}>
                          {/* *First name */}
                          <div className="row mt-3">
                            <div className="col-md-6">
                              <label className="form-label">*First name</label>
                              <input
                                className="form-control"
                                name="firstName"
                                value={subContractor.firstName}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                            {/* *Last name */}
                            <div className="col-md-6">
                              <label className="form-label">*Last name</label>
                              <input
                                className="form-control"
                                name="lastName"
                                value={subContractor.lastName}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <label className="form-label">*Email</label>
                              <input
                                className="form-control"
                                name="email"
                                type="email"
                                autoComplete="off"
                                value={subContractor.email}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <label className="form-label">*Phone</label>
                              <input
                                className="form-control"
                                name="phone"
                                autoComplete="off"
                                value={subContractor.phone}
                                placeholder="+91"
                                onChange={(e) => handleChange(e)}
                                required
                              />
                            </div>
                          </div>

                          <div className="row configure-project">
                            <div className="col-md-12 text-center">
                              <button
                                type="submit"
                                className="btn-primary btn-lg w-100 next-btn-slide"
                              >
                                <div className="d-flex align-items-center">
                                  Next <img src={RightArrow} alt="icon" />
                                </div>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  );
                case 3:
                  return (
                    <div className="item">
                      <div className="company-detail">
                        <form onSubmit={(e) => onSubmit(e)}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h2>Preview Invitation</h2>
                            <button className="btn btn-light" onClick={(e) => handleEditClick(e)}>
                              {isReadOnly ? "Edit" : "Save"}
                            </button>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <label className="form-label">Subject</label>
                              <input
                                className="form-control placeholder-input"
                                name="subject"
                                placeholder={
                                  isReadOnly ? subContractor.subject : ""
                                }
                                value={isReadOnly ? "" : subContractor.subject}
                                onChange={(e) =>
                                  setSubContractor({
                                    ...subContractor,
                                    subject: e.target.value,
                                  })
                                }
                                readOnly={isReadOnly}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <label className="form-label">Message</label>
                              <div className="App">
                                {/* <h2>Using CKEditor&nbsp;5 build in React</h2> */}
                                <div style={{ height: "300px" }}>
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={subContractor.message.replace(
                                      /\\n/g,
                                      "<br>"
                                    )}
                                    disabled={isReadOnly}
                                    onChange={(event, editor) => {
                                      const newData = editor.getData();
                                      setSubContractor((prevState) => ({
                                        ...prevState,
                                        message: newData,
                                      }));
                                    }}
                                  />
                                </div>
                              </div>
                              <div style={{ display: "flex", gap: "15px" }}>
                                <button
                                  className="invite-mail"
                                  onClick={(e) => {
                                    setSubContractor((prevState) => ({
                                      ...prevState,
                                      inviteVia: "Email",
                                    }));
                                  }}
                                >
                                  <img src={Mail} alt="icon" /> Invite via mail
                                </button>

                                <button
                                  className="invite-mail invite-via"
                                  onClick={(e) => {
                                    setSubContractor((prevState) => ({
                                      ...prevState,
                                      inviteVia: "Message",
                                    }));
                                  }}
                                >
                                  <img src={Quotes} alt="icon" /> Invite via SMS
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  );
                default:
                  return null;
              }
            })()}

            <div className="own-nav">
              < button
                className="nav-btn prev-slide"
                disabled={activeComponent <= 1}
                onClick={() => setActiveComponent(activeComponent - 1)}
              >
                <img src={chevronLeft} alt="icon" />  Previous
              </button>
              <button
                className="nav-btn next-slide"
                disabled={
                  // Check if all required fields are filled for the current active component
                  (activeComponent === 1 && !companyFormIsVaild) ||
                  (activeComponent === 2 && !companyFormIsVaild) ||
                  (activeComponent === 3)
                }
                onClick={() => setActiveComponent(activeComponent + 1)}
              >
                Next<img src={chevronRight} alt="icon" />
              </button>
            </div>
          </div>
        </main >
        :
        <SubContractorCompleteDetails />
      }
    </>
  );
}

export default CreateSubContractor;
