import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./../../shared/sharedComponents/sidebar";
import { getProjects } from "../../services/projectServices";
import ActionMenu from "../../shared/sharedComponents/actionMenu";
import moment from "moment";

const Projects = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getProjects().then(result => {
      setProjects(result);
    });
  }, []);

  const handleAction = (option, projectId) => {
    switch (option) {
      case 'View Project':
        navigate(`/projects/view/${projectId}`);
        break;
      case 'Add Sub Project':
        navigate(`/sub-project/${projectId}`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Sidebar />

      <main id="main" className="main">
        <div className="sub-contractors-heading">
          <h3>Projects</h3>
          <Link to="/projects/add" className="btn-sub-contractor">Add Project</Link>
        </div>

        <div className="flex-container border-0">
          <div className="flex-row">
            <div className="flex-cell th" style={{ width: "20%" }}>
              Project title
            </div>
            <div className="flex-cell th" style={{ width: "15%" }}>
              Project reference
            </div>
            <div className="flex-cell th" style={{ width: "15%" }}>
              Internal reference
            </div>
            <div className="flex-cell th" style={{ width: "15%" }}>
              External reference
            </div>
            <div className="flex-cell th" style={{ width: "15%" }}>
              Start date
            </div>
            <div className="flex-cell th" style={{ width: "15%" }}>
              End date
            </div>
            <div className="flex-cell th" style={{ width: "5%" }}>
              Action
            </div>
          </div>
        </div>

        <div className="overflow-x">
          {projects?.length ? projects.map((project) => (
            <div key={project.id} className="flex-container">
              <div className="flex-row table-top mt-0">
                <div className="flex-cell" style={{ width: "25%" }}>
                  {project.projectTitle}
                </div>
                <div className="flex-cell" style={{ width: "15%" }}>
                  {project.projectReference || '-'}
                </div>
                <div className="flex-cell" style={{ width: "15%" }}>
                  {project.internalReference || '-'}
                </div>
                <div className="flex-cell" style={{ width: "15%" }}>
                  {project.externalReference || '-'}
                </div>
                <div className="flex-cell" style={{ width: "15%" }}>
                  <div className="groundwork text-date">
                    {moment(project.startDate).format('DD-MM-YYYY')}
                  </div>
                </div>
                <div className="flex-cell" style={{ width: "15%" }}>
                  <div className="groundwork text-date">
                    {moment(project.endDate).format('DD-MM-YYYY')}
                  </div>
                </div>

                <div className="flex-cell" style={{ width: "5%" }}>
                    <ActionMenu
                      handleAction={(option) => { handleAction(option, project.id) }}
                      options={project.haveSubprojects ? [
                        'View Project',
                        'Add Sub Project'
                      ]: [
                        'View Project',
                      ]}
                    />
                </div>
              </div>
            </div>
          )) : null}
        </div>
      </main>
    </>
  );
}

export default Projects;
