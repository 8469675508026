import { GET} from "./httpClientServices";

export const getSubProjectCategories = async () => {
    let url = "/admin/api/subprojectCategories";
    try {
        const result = await GET(url);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}

export const getSubProjectCategoryById = async (id) => {
    let url = `/admin/api/subprojectCategories/${id}`;
    try {
        const result = await GET(url);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}