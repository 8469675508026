import Files from "../../assests/image/file.svg";
import Pdf from "../../assests/image/file-pdf.svg";
import Txt from "../../assests/image/file-txt.svg";
import Image from "../../assests/image/Image.svg";

const getFileIcon = (documentPath) => {
  const extension = documentPath.split('.').pop();

  switch (extension) {
    case "pdf":
      return <img src={Pdf} alt="pdf" />;
    case "doc":
    case "docx":
    case "xls":
    case "xlsx":
    case "ppt":
    case "pptx":
      return <img src={Files} alt={extension} />;
    case "txt":
      return <img src={Txt} alt="txt" />;
    case "png":
    case "jpg":
    case "jpeg":
    case "svg":
      return <img src={Image} alt={extension} />;
    default:
      return <img src={Files} alt="file" />;
  }
};

export default getFileIcon;
