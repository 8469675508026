import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import nonConfirmCheck from '../../assests/image/non-comfirm-check.svg';
import CrossIcon from "../../assests/image/mile-cross.svg";
import cross from '../../assests/image/cross.svg';
import { toast } from 'react-toastify';
import { addMilestoneNonConformitiesActivity } from '../../services/milestoneServices'
function NonConformitiesModal(props) {
    const { id } = useParams()
    const [disableConfirmDneyButton, setDisableConfirmDneyButton] = useState(false)
    const [description, setDescription] = useState("")
    // const [mediaUrl, setMediaUrl] = useState("")
    const handleSubmit = () => {
        if (!description) {
            toast.warn("please provide justification", { toastId: "warn" })
            return;
        }
        try {
            setDisableConfirmDneyButton(true)
            const payload = {
                "milestoneId": id,
                "nonConformitiesId": props.data?.nonConformitiesId,
                "confirmExtraWork": props.data?.confirmExtraWork,
                "feedback": description
            }
            addMilestoneNonConformitiesActivity(payload).then(res => {
                if (res.id) {
                    props.handleSuccess()
                    setDisableConfirmDneyButton(false)
                }
            }).catch(err => {
                console.error(err);
                setDisableConfirmDneyButton(false)
                toast.error("something went wrong", { toastId: "error" })
            })
        } catch (err) {
            setDisableConfirmDneyButton(false)
            toast.error("something went wrong", { toastId: "error" })
            console.log(err)
        }
    }
    return (
        <Modal
            {...props}
        >
            <div className="non-confirmities-modal">
                <div>
                    <img className="w-100 source-image" src={props.data?.image} alt="icon" />
                    <img className="modal-cross" src={cross} alt="icon" onClick={props.onHide} />
                </div>

                <div className='non-confirmities-modal-body'>
                    <h4>{props.data?.confirmExtraWork === 1 ? "Accept" : "Deny"} extra work;</h4>
                    <p>Substantiate your {props.data?.confirmExtraWork === 1 ? "acceptance" : "Denial"}</p>
                    <div className="non-confirmities-description">
                        <textarea className='w-100' onChange={(e) => setDescription(e.target.value)} />
                    </div>
                </div>

                <div className='d-flex gap-2 justify-content-between non-confirmities-modal-footer'>
                    <button disabled={disableConfirmDneyButton} className='btn btn-non-confirmities-deny w-100' onClick={handleSubmit}><img src={nonConfirmCheck} alt="icon" className='mx-1 mb-1' />{props.data?.confirmExtraWork === 1 ? "Confirm" : "Deny"}</button>
                    <button className='btn btn-non-confirmities-cancel w-100' onClick={props.onHide}><img src={CrossIcon} alt="icon" className='mx-1' />Cancel</button>
                </div>
            </div>
        </Modal>
    );
}

export default NonConformitiesModal;



