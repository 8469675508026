import { DELETE, GET, POST } from "./httpClientServices";

export const createProject = async (data) => {
  let url = "/admin/api/project/create";
  try {
    let formData = new FormData();

    for (let i = 0; i < data.projectFiles.length; i++) {
      formData.append("projectFiles", data.projectFiles[i]);
    }

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key == "areaLatLng") {
          formData.append("areaLatLng", JSON.stringify(data[key]));
        } else if (key == "projectSubcontractor") {
          formData.append("projectSubcontractor", data[key].join(","));
        } else {
          if (key !== "projectFiles") {
            if (data[key] == null) {
              formData.append(key, "");
            } else {
              formData.append(key, data[key]);
            }
          }
        }
      }
    }

    const result = await POST(url, formData);
    return result;
  } catch (error) {
    console.error("Error posting data:", error);
  }
};

export const getProjectOwner = async () => {
  let url = "/admin/api/get-project-owners";
  try {
    const result = await GET(url);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getClients = async () => {
  let url = "/admin/api/clients";
  try {
    const result = await GET(url);
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getSubcontractor = async () => {
  let url = "/admin/api/subcontractors";
  try {
    const result = await GET(url);
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getSubcontractorType = async () => {
  let url = "/admin/api/subcontractorTypes";
  try {
    const result = await GET(url);
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getProjectById = async (id) => {
  let url = `/admin/api/project/${id}`;
  try {
    const result = await GET(url);
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getProjects = async () => {
  let url = `/admin/api/projects`;
  try {
    const result = await GET(url);
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getSubProjects = async (id) => {
  let url = `/admin/api/subprojects`;
  try {
    const result = await POST(url, { projectId: id });
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const createSubcontractor = async (data) => {
  let url = `/admin/api/subcontractor/create`;
  try {
    const result = await POST(url, data);
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getProjectFilesById = async (id) => {
  let url = `/admin/api/projectDocuments/project/${id}`;
  try {
    const result = await GET(url);
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export const getSubcontractorByProjectId = async (id) => {
  let url = `/admin/api/subcontractors/project/${id}`;
  try {
    const result = await GET(url);
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export const updateProjectDescription = async (data) => {
  let url = `/admin/api/project/updateDescription`;

  try {
    const result = await POST(url, data);
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export const deleteProjectFile = async (id) => {
  let url = `/admin/api/projectDocument/delete/${id}`;

  try {
    const result = await DELETE(url);
    return result
  } catch (error) {
    console.log("Error deleting data:", error);
  }

}

export const addProjectDocument = async (data) => {
  let url = `/admin/api/addProjectDocument`;
  try {
    let formData = new FormData();
    formData.append("projectFiles", data.projectFiles);
    formData.append("projectId", data.projectId);
    formData.append("documentName", data.documentName);
    const result = await POST(url, formData);
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export const renameProjectDocument = async (data) => {
  let url = `/admin/api/projectDocument/rename`;
  try {
    const result = await POST(url, data);
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}