import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../shared/sharedComponents/sidebar";
import { GoogleMap, Polygon, Polyline, Marker, useJsApiLoader, OverlayView, InfoWindow } from '@react-google-maps/api';
import { getProjectById } from '../../services/projectServices';
import { getSubProjectById, updateSubProjectDescription, getSubProjectFilesById, addSubProjectDocument, renameSubProjectDocument, deleteSubProjectDocument, getSubProjectMilestonesById } from '../../services/subProjectServices';
import { getSubContractorById } from '../../services/subContractorServices';
import { MAP_KEY } from "../../shared/constants/mapKeyConstant";
import "react-datepicker/dist/react-datepicker.css";
import { useParams, Link } from "react-router-dom";
import NotFoundComponent from '../../shared/sharedComponents/NotFoundComponent.jsx';
import SubProjectMilestone from "./SubProjectMilestone.jsx";
import SubProjectBasicDetails from "./SubProjectBasicDetails.jsx";
import ArrowBack from "../../assests/image/project-arrow-back.svg";



export const SubProjectView = () => {

    const location = useParams();
    const libraries = ['places', 'drawing', 'geometry'];
    const [project, setProject] = useState();
    const [subProject, setSubProject] = useState();
    const [hoveredItem, setHoveredItem] = useState();
    const [hoveredItemLatLng, setHoveredItemLatLng] = useState();
    const [center, setCenter] = useState();
    const [zoom, setZoom] = useState(15);
    const [subContractor, setSubContractor] = useState();
    const [descriptionEditable, setDescriptionEditable] = useState();
    const [subProjectMilestones, setSubProjectMilestones] = useState([]);
    const [error, setError] = useState(false);

    const mapRef = useRef();
    const textareaRef = useRef(null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: MAP_KEY,
        libraries
    });


    const onLoadMap = (map) => {
        map.setMapTypeId("satellite");
        mapRef.current = map;
        map.setOptions({
            fullscreenControl: true,
            streetViewControl: false,
            zoomControl: false,
            mapTypeControlOptions: {
                mapTypeIds: [],
            },
        });
    };


    useEffect(() => {

        if (project?.areaLatLng?.length) {
            let lastCoordinate = project.areaLatLng[project.areaLatLng?.length - 1]?.split(",");

            let defaultCenter = {
                lat: Number(lastCoordinate[0]),
                lng: Number(lastCoordinate[1])
            }
            setCenter(defaultCenter);
        }

        setZoom(project?.currentZoom)
    }, [project])

    const containerStyle = {
        width: "100%",
        height: "377px"
    };

    console.log("location", location);

    useEffect(() => {
        getProject();
        getSubProject();
        getSubProjectMilestones();
    }, []);

    const getProject = () => {
        getProjectById(location.projectId).then(result => {
            result.areaLatLng = result?.areaLatLng?.split("--") || [];
            setProject(result);
        }).catch(err => {
            console.log(err);
            setError(true);
        });
    }

    const getSubProject = () => {
        getSubProjectById(location.id).then(result => {
            result.areaLatLng = result?.areaLatLng?.split("--") || [];
            setSubProject(result);
            console.log("result", result);
            console.log("subContractorId", result?.subcontractorId);

            getSubContractorById(result?.subcontractorId).then(res => {
                console.log("res", res);

                setSubContractor(res);
            })

        }).catch(err => {
            console.log(err);
            setError(true);
        });
    }


    const getSubProjectMilestones = () => {
        getSubProjectMilestonesById(location.id).then(result => {
            console.log("milestone result ", result);

            for (let item of result) {
                item.areaLatLng = item.areaLatLng?.split("--") || [];
            }

            setSubProjectMilestones(result);
        }).catch(err => {
            console.error(err);
        })
    }


    const getOverlayCoordinates = () => {
        if (project?.areaLatLng.length > 0) {
            return project.areaLatLng
                .map((latlng, index) => {
                    if (typeof latlng === "string") {
                        const [lat, lng] = latlng.split(", ");
                        if (lat && lng) {
                            return { lat: parseFloat(lat), lng: parseFloat(lng) };
                        }
                    }
                    return null;
                })
                .filter(Boolean);
        }
        return [];
    }

    const overlayPath = getOverlayCoordinates();

    const getOverviewBounds = (path) => {
        const bounds = new window.google.maps.LatLngBounds();
        path.forEach((point) => bounds.extend(point));
        return bounds;
    }

    const geometryOptions = {
        fillOpacity: 0.3,
        fillColor: "#35f6de",
        strokeColor: "#35f6de",
        strokeWeight: 5,
    };

    const milestoneGeometryOptions = {
        fillOpacity: .5,
        fillColor: "#2BD547",
        strokeColor: "#2BD547",
        strokeWeight: 5,
    };

    const getCoordinates = () => {
        if (subProject !== undefined && subProject.areaLatLng.length > 0) {
            return subProject.areaLatLng
                .map((latlng, index) => {
                    if (typeof latlng === "string") {
                        const [lat, lng] = latlng.split(", ");
                        if (lat && lng) {
                            return { lat: parseFloat(lat), lng: parseFloat(lng) };
                        }
                    }
                    return null;
                })
                .filter(Boolean);
        }
        return [];
    };

    const paths = getCoordinates();

    const getMileStoneCoordinates = (data) => {
        if (data !== undefined && data.areaLatLng.length > 0) {
            return data.areaLatLng
                .map((latlng, index) => {
                    if (typeof latlng === "string") {
                        const [lat, lng] = latlng.split(", ");
                        if (lat && lng) {
                            return { lat: parseFloat(lat), lng: parseFloat(lng) };
                        }
                    }
                    return null;
                })
                .filter(Boolean);
        }
        return [];
    };


    const handleMouseOver = (e, item, paths) => {
        setHoveredItem(item);
        setHoveredItemLatLng(paths[0]);
    }

    const handleMouseOut = (e, item) => {
        console.log(e, item);
        setHoveredItem(null)
    }


    useEffect(() => {
        if (descriptionEditable) {
            textareaRef.current.focus();
        }
    }, [descriptionEditable]);

    if (error) {
        return <NotFoundComponent />
    }

    console.log("project", project);
    console.log("subProject", subProject);
    console.log("subContractor", subContractor);
    console.log("milestones", subProjectMilestones);

    return (
        <>
            <Sidebar />

            <main id="main" className="main p-0">
                {/* Google map integration */}
                {isLoaded && project && subProject ?
                    <div className="d-flex gap-3 overview-box-top project-overview">
                        <GoogleMap
                            zoom={zoom}
                            center={center}
                            onLoad={onLoadMap}
                            mapContainerStyle={containerStyle}
                        // onTilesLoaded={() => setCenter(null)}
                        >
                            <div className="d-flex align-items-center gap-2 ml-space">
                                <div className="project-overview-box overview-box-top-label mt-0">
                                    <Link to="/projects"> Project overview  </Link>
                                </div>
                                <div className="project-overview-box arrow-breadcum">
                                    <img src={ArrowBack} alt="icon" />
                                </div>
                                <div className="project-overview-box">
                                <Link to={`/projects/view/${project.id}`}><span> {project?.projectTitle}</span></Link>
                                </div>
                                <div className="project-overview-box arrow-breadcum">
                                    <img src={ArrowBack} alt="icon" />
                                </div>
                                <div className="project-overview-box">
                                    <span> {subProject?.title}</span>
                                </div>
                            </div>
                            {(() => {
                                switch (subProject.areaType) {
                                    case "POINT":
                                        return <Marker position={paths[0]} />;
                                    case "PATH":
                                        return <Polyline path={paths} options={{ ...geometryOptions, zIndex: 110 }} />;
                                    case "AREA":
                                        return <Polygon path={paths} options={{ ...geometryOptions, zIndex: 110 }} />;
                                    default:
                                        return null; // or handle the default case as needed
                                }
                            })()}

                            {subProjectMilestones && subProjectMilestones.length > 0 && subProjectMilestones.map((item, key) => {

                                let paths = getMileStoneCoordinates(item);
                                console.log("item", item)

                                switch (item.areaType) {
                                    case "POINT":
                                        return <Marker position={paths[0]} title={item.name} />;
                                    case "PATH":
                                        return <Polyline path={paths} options={{ ...milestoneGeometryOptions, zIndex: 110 }} onMouseOver={(e) => handleMouseOver(e, item, paths)}
                                            onMouseOut={(e) => handleMouseOut(e)} />;
                                    case "AREA":
                                        return <Polygon path={paths} options={{ ...milestoneGeometryOptions, zIndex: 110 }} onMouseOver={(e) => handleMouseOver(e, item, paths)}
                                            onMouseOut={(e) => handleMouseOut(e)} />;
                                    default:
                                        return null; // or handle the default case as needed
                                }

                            })}

                            {hoveredItem && <InfoWindow
                                position={{ lat: hoveredItemLatLng.lat, lng: hoveredItemLatLng.lng }}
                                onCloseClick={() => setHoveredItem(null)}
                            >
                                <div>{hoveredItem?.name}</div>
                            </InfoWindow>}

                            {/* <OverlayView
                                bounds={getOverviewBounds(overlayPath)}
                                mapPaneName={OverlayView.OVERLAY_LAYER}
                                getPixelPositionOffset={(width, height) => ({
                                    x: 0,
                                    y: 0
                                })}
                            >
                                <div
                                    style={{
                                        transform: `rotate(${project?.imageRotation}deg) scale(${1})`,
                                        cursor: "pointer",
                                        position: "absolute",
                                        top: `${project?.imageTop}%`,
                                        left: `${project?.imageLeft}%`,
                                        width: `${project?.imageSize}%`,
                                        height: `${project?.imageSize}%`,
                                        backgroundImage: `url(${process.env.REACT_APP_BASE_URL + "/" + project?.projectMap})`,
                                        backgroundSize: 'contain',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                    }}
                                >
                                </div>
                            </OverlayView> */}
                        </GoogleMap>
                    </div>
                    :
                    null
                }


                {/* Sub-project basic details  */}
                <SubProjectBasicDetails />

                {/* Listing milestones */}
                <SubProjectMilestone
                    subProjectMilestones={subProjectMilestones}
                />

            </main>
        </>
    )
}