import React from "react";
import { Link } from "react-router-dom";
import DashboardIcon from "../../assests/image/dasboard-icon.svg";
import Logout from "../../assests/image/icon-logout.svg";
import Logo from "../../assests/image/logo.svg";
import Milestones from "../../assests/image/milestones.svg";
import Myteam from "../../assests/image/my-team.svg";
import Planing from "../../assests/image/planing.svg";
import ProjectIcon from "../../assests/image/project-icon.svg";

function Sidebar() {
  return (
    <aside id="sidebar" className="sidebar">
      <div className="top-part">
        <img src={Logo} className="logo-left" alt="logo" />
        <div className="welkom">
          <div className="flex-welkom">
            <div className="profile-image"></div>
            <div className="name-profile">Welkom, Martijn</div>
          </div>
          <p>
            <span className="profile-circle-dot"></span>Bekijk notificaties
          </p>
        </div>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link to="/" className="nav-link">
              <img src={DashboardIcon} alt="icon" />
              <span>Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed cover-ul"
              data-bs-target="#tables-nav"
              data-bs-toggle="collapse"
              href="#"
              aria-expanded="false"
            >
              <img src={ProjectIcon} alt="icon" />
              <span>Projects</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="tables-nav"
              className="nav-content submenus collapse show"
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/projects">
                  <span>All projects</span>
                </Link>
              </li>
              <li>
                <Link to="/projects/add">
                  <span>Create new project</span>
                </Link>
              </li>
            </ul>
          </li>

          {/* <li className="nav-item">
            <Link to="/milestones" className="nav-link">
              <img src={Milestones} alt="icon" />
              <span>Milestones</span>
            </Link>
          </li> */}

          <li className="nav-item">
            <a href="#" className="nav-link">
              <img src={Planing} alt="icon" />
              <span>Planning</span>{" "}
              <span className="coming-soon">Coming soon</span>
            </a>
          </li>
        </ul>
      </div>

      <div className="fit-navbar-bottom">
        <ul className="sidebar-nav top-line" id="sidebar-nav">
          <li className="nav-item">
            <a href="#" className="nav-link">
              <img src={Myteam} alt="icon" />
              <span>My team</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="#" className="nav-link">
              <img src={Myteam} alt="icon" />
              <span>Clients</span>
            </a>
          </li>
          <li className="nav-item">
            <Link to="/sub-contractors" className="nav-link">
              <img src={Myteam} alt="icon" />
              <span>Sub-Contractors</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="setting-bottom">
        <p>Settings</p>
        <p>
          <img src={Logout} alt="icon" />
        </p>
      </div>
    </aside>
  );
}

export default Sidebar;
