import { GET, POST, UPDATE, DELETE } from "./httpClientServices";

export const getSubContractorById = async (id) => {
    let url = `/admin/api/subcontractor/${id}`;

    try {
        const result = await GET(url);
        return result;

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}