import React, { useState } from "react";
import moment from "moment";
import TypeIcon from "../../assests/image/type-icon.svg";
import Group5 from "../../assests/image/Group5.svg";
import DarkArrow from "../../assests/image/dark-arrow.svg";
import { useParams, Link } from "react-router-dom";

const SubProjectMilestone = (props) => {
    const { id, projectId } = useParams()
    const { subProjectMilestones } = props
    const [activeTab, setActiveTab] = useState("tab1");
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };



    return (
        <>
            {/* Listing milestones */}
            <div className="all-milestones mb-3">
                <div className="d-flex justify-content-between">
                    <h2>Milestones</h2>
                </div>
                <div className="tab-content">
                    {activeTab === "tab1" && (
                        <div>
                            <table>
                                <tr>
                                    <th className="milestone-text-left">Name</th>
                                    <th>GPS Verified</th>
                                    <th>Non-conformities</th>
                                    <th>Planned date</th>
                                    <th>Execution date</th>
                                    <th style={{ width: "125px" }}>status</th>
                                </tr>

                                {
                                    (subProjectMilestones && subProjectMilestones?.length > 0) ?
                                        subProjectMilestones?.map((milestone) => {
                                            return (
                                                <tr className="milestone-row" key={milestone.id}>
                                                    <td className="milestone-text-left">
                                                        <span><img src={TypeIcon} alt="icon" /></span>
                                                        <span className="p-3">{milestone?.name}</span>
                                                    </td>
                                                    <td><img src={Group5} /></td>
                                                    <td><span className={`${milestone.nonConformitive == 0 ? "non-confirmitive-milestones" : "confirmitive-milestones"}`}>{milestone.nonConformitive}</span></td>
                                                    <td>{moment(milestone?.deliveryDate).format("DD-MM-YYYY")}</td>
                                                    <td>-</td>
                                                    <td><span
                                                        className={` 
                                                            ${milestone.actual_status == "Completed" ? "btn-completed"
                                                                : milestone.actual_status == "Non-Confirmitive" ? "non-confirmitive-btn"
                                                                    : milestone.actual_status == "Planned" ? "planned-btn"
                                                                        : "btn-completed"}`}>
                                                        {milestone.actual_status}
                                                    </span>
                                                    </td>
                                                    <td> <Link to={`/project/${projectId}/sub-project/${id}/milestone/${milestone.id}`}><img src={DarkArrow} alt="icon" /></Link></td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td colSpan="6" className="text-center">No Milestone found</td>
                                        </tr>
                                }
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default SubProjectMilestone;
