import { GET, POST, UPDATE } from "./httpClientServices";

export const getMilestoneDetailById = async (id) => {
    let url = `/admin/api/milestoneDetails?id=${id}`;

    try {
        const result = await GET(url);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}

export const getMilestoneNonConformities = async (id) => {
    let url = `/admin/api/milestoneNonConformities?milestoneId=${id}`;

    try {
        const result = await GET(url);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}

export const addMilestoneNonConformitiesActivity = async (payload) => {
    let url = `/admin/api/addMilestoneActivity`
    try {
        const result = await POST(url, payload)
        return result;
    } catch (error) {
        console.log("error adding activity", error)
    }
}

export const getMileStoneActivitiesById = async (id) => {
    let url = `/admin/api/getMilestoneActivity?milestoneId=${id}`;

    try {
        const result = await GET(url);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}

export const updateMilestoneDetail = async (data) => {
    let url = `/admin/api/updateMilestoneDetail`;

    try {
        const result = await UPDATE(url, data);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
        let message = "something went wrong"
        if (error?.response?.data?.error?.message) message = error?.response?.data?.error?.message
        else if (error?.response?.data?.message) message = error?.response?.data?.message;
        throw { error: true, message }

    }
}