import React, { useRef, useState, useEffect } from 'react';
import '../../assests/scss/drop-file-input.css';
import uploadImg from '../../assests/image/Upload-icon.svg';
import bin from '../../assests/image/Bin-icon.svg';
import { toast } from 'react-toastify';

function DropFileInput(props) {

    const componentId = props.componentId;
    const wrapperRef = useRef(null);

    const [fileList, setFileList] = useState(props.projectFiles || []);
    const [editableNames, setEditableNames] = useState({});
    useEffect(() => {
        if (props.projectFiles?.length) {
            let nameObj = {}
            props.projectFiles.forEach((item) => nameObj[item.name] = item.name)
            setEditableNames(nameObj)
        }
    }, [])
    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const onFileDrop = (e) => {
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'application/pdf', 'image/vnd.adobe.photoshop', 'application/postscript', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint'];
        
        const newFile = e.target.files[0];
        if(fileList.length >= 5) {
            toast.error("You can upload only 5 files");
            return;
        }
        if (componentId === 3 && !["image/png", "image/jpeg", 'application/pdf'].includes(newFile.type)) {
            toast.error("Please upload valid file type");
            return;
        }
        if(!allowedFileTypes.includes(newFile.type)) {
            toast.error("Please upload only supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT");
            return;
        }

        if (newFile) {
            const updatedList = [...fileList, newFile];
            setFileList(updatedList);
            const newEditableNames = { ...editableNames };
            newEditableNames[newFile.name] = newFile.name;
            setEditableNames(newEditableNames);
            props.onFileChange(updatedList, componentId);
        }
    }

    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        const newEditableNames = { ...editableNames };
        delete newEditableNames[file.name];
        setEditableNames(newEditableNames);
        props.onFileChange(updatedList, componentId);
    }

    // const handleNameChange = (e, file) => {
    //     const newEditableNames = { ...editableNames };
    //     newEditableNames[file.name] = e.target.value;
    //     setEditableNames(newEditableNames);
    //     const updatedList = fileList.map((item) => (item === file ? { ...item, name: e.target.value } : item));
    //     props.onFileChange(updatedList, componentId);
    // };

    const handleNameChange = (e, file, index) => {
        // Make a copy of the file object
        const updatedFile = new File([file], e.target.value, { type: file.type });
        // Update the file in the projectFiles array
        const updatedProjectFiles = [...props.projectFiles];
        updatedProjectFiles[index] = updatedFile;
        setEditableNames(updatedProjectFiles);
        props.onFileChange(updatedProjectFiles, componentId);
    };
    

    return (
        <>
            <div
                ref={wrapperRef}
                className="drop-file-input"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div className="drop-file-input__label">
                    <img src={uploadImg} alt="icon" className='uploadimage' />
                    <p className='drag-drop-file'>Drag & drop files or <span>Browse</span></p>
                    <p className="drop-file-input__label_msg">
                        {componentId === 3 ? "Supported formates: JPEG,PNG"
                            : "Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT"}
                    </p>
                </div>
                {componentId === 3 ? <input type="file" value="" onChange={onFileDrop} accept="image/png, image/jpeg, application/pdf" /> :
                    <input type="file" value="" onChange={onFileDrop} />
                }

            </div>
            {
                //In case of componentId == 3 i.e. ProjectMap, Below part will be hidden
                (fileList.length > 0 && componentId !== 3) ? (
                    <div className="drop-file-preview">
                        <p className="drop-file-preview__title">
                            Uploaded
                        </p>
                        {
                            fileList.map((item, index) => (
                                <div key={index} className="drop-file-preview__item">
                                    <div className="drop-file-preview__item__info w-100">
                                        <input
                                            type="text"
                                            value={editableNames[item.name]}
                                            onChange={(e) => handleNameChange(e, item,index)}
                                        />
                                    </div>
                                    < span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>
                                        <img src={bin} alt="" />
                                    </span>
                                </div>
                            ))
                        }
                    </div >
                ) : null
            }
        </>
    );
}

export default DropFileInput;
