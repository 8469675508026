import { GET, POST, UPDATE, DELETE } from "./httpClientServices";

export const getProjectSubContractor = async (id) => {
    let url = `/admin/api/subcontractors/project/${id}`
    try {
        const result = await GET(url)
        console.log("result", result);
        return result;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const createSubProject = async (data) => {
    let url = "/admin/api/subproject/create";
    try {
        let formData = new FormData();
        for (let i = 0; i < data.subprojectFiles?.length; i++) {
            formData.append("subprojectFiles", data.subprojectFiles[i])
        }
        for (const key in data) {
            if (data.hasOwnProperty(key)) {

                if (key == "areaLatLng") {
                    formData.append("areaLatLng", JSON.stringify(data[key]));

                } else {
                    if (!["subprojectMilestones", "subprojectFiles"].includes(key)) {
                        if (data[key] == null) {
                            formData.append(key, "");
                        } else {
                            formData.append(key, data[key]);
                        }
                    }
                }
            }
        }

        const result = await POST(url, formData);
        return result;

    } catch (error) {
        console.error('Error posting data:', error);
    }
}

export const createMileStones = async (data) => {
    let url = "/admin/api/milestones/create";
    let formData = new FormData();
    for (let i = 0; i < data.milestoneFiles?.length; i++) {
        formData.append("milestoneFiles", data.milestoneFiles[i])
    }
    for (const key in data) {
        if (data.hasOwnProperty(key)) {

            if (key == "areaLatLng") {
                formData.append("areaLatLng", JSON.stringify(data[key]));
            } else {
                if (!["milestoneFiles"].includes(key)) {
                    if (data[key] == null) {
                        formData.append(key, "");
                    } else {
                        formData.append(key, data[key]);
                    }
                }
            }
        }
    }
    try {
        const result = await POST(url, formData);
        return result;
    } catch (error) {
        console.error('Error posting data:', error);
    }
}

export const getSubProjectById = async (id) => {
    let url = `/admin/api/subproject/${id}`

    try {
        const result = await GET(url);
        return result
    } catch (error) {
        console.log('Error fetching data:', error);
    }
}

export const updateSubProjectDescription = async (data) => {
    let url = `/admin/api/subproject/updateDescription`;

    try {
        const result = await POST(url, data);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}


export const getSubProjectFilesById = async (id) => {
    let url = `/admin/api/subprojectDocuments/subproject/${id}`;
    try {
        const result = await GET(url);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}

export const addSubProjectDocument = async (data) => {
    let url = `/admin/api/subproject/add/document`;
    try {
        let formData = new FormData();
        formData.append("subprojectFiles", data.subprojectFiles);
        formData.append("subprojectId", data.subprojectId);
        formData.append("documentName", data.documentName);
        const result = await POST(url, formData);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}


export const renameSubProjectDocument = async (data) => {
    let url = `/admin/api/subproject/document/rename`;

    try {
        const result = await POST(url, data);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}

export const deleteSubProjectDocument = async (id) => {
    let url = `/admin/api/subprojectDocument/delete/${id}`;
    try {
        const result = await DELETE(url);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}

export const getSubProjectMilestonesById = async (id) => {
    let url = `/admin/api/milestones/subproject/${id}`;

    try {
        const result = await GET(url);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
    }

}

