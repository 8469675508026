import axios from 'axios';

// const BASE_URL = 'https://staging-api.civil.infrai.app/';

export const GET = async (url, params) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${url}`, { params });
        return response.data;
    } catch (error) {
        console.error('GET request error:', error);
        throw error;
    }

}

export const POST = async (url, data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${url}`, data);
        return response.data;
    } catch (error) {
        console.error('POST request error:', error);
        throw error;
    }

} 

export const UPDATE = async (url, data) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}${url}`, data);
        return response.data;
    } catch (error) {
        console.error('UPDATE request error:', error);
        throw error;
    }

}

export const DELETE = async (url) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}${url}`);
        return response.data;
    } catch (error) {
        console.error('DELETE request error:', error);
        throw error;
    }

}