import {
  GoogleMap,
  OverlayView,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import { MAP_KEY } from "../../shared/constants/mapKeyConstant";
import DropFileInput from "../../shared/sharedComponents/documentUploader";
import Lock from '../../assests/image/Lock.svg'
import Arrow from "../../assests/image/arrow-up-line.svg";
import Plus from "../../assests/image/plus.svg";
import Minus from "../../assests/image/subtract-fill.svg";
import RotateRight from "../../assests/image/Arrow-right.svg";
import RotateLeft from "../../assests/image/rotate-left.svg";
import { CreateProjectScreenTypes } from "../../shared/enums/screenTypeEnum";
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import * as pdfjsLib from 'pdfjs-dist/webpack';

const libraries = ["places", "drawing", "geometry"];
const createBounds = (center, width, height) => {
  const sw = {
    lat: center.lat - height / 2,
    lng: center.lng - width / 2
  };
  const ne = {
    lat: center.lat + height / 2,
    lng: center.lng + width / 2
  };
  return new window.google.maps.LatLngBounds(sw, ne);
};
function ProjectMap({ props }) {
  // console.log("props", props);
  const componentId = 3;
  const projectDetails = props.project;
  const addProjectDetails = props.addProjectDetails;
  const changeActiveComponent = props.changeActiveComponent;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAP_KEY,
    libraries,
  });

  const containerStyle = {
    width: "100%",
    height: "90vh",
  };

  const geometryOptions = {
    fillOpacity: 0.3,
    fillColor: "#FCC331",
    strokeColor: "#FCC331",
    strokeWeight: 5,
  };

  const [projectMapConfirmed, setProjectMapConfirmed] = useState(false);
  const [center, setCenter] = useState({});
  const [path, setPath] = useState();
  const [projectMap, setProjectMap] = useState(projectDetails.projectMap);

  const mapRef = useRef();
  const polygonRefs = useRef([]);
  const [markerInstance, setMarkerInstance] = useState(null);
  const areaLatLng = projectDetails.areaLatLng || [];
  const [imageRotation, setImageRotation] = useState(projectDetails.imageRotation)
  const [showOverlayView, setShowOverlayView] = useState(false)
  const [zoom, setZoom] = useState(18)
  const [polygonCenter, setPolygonCenter] = useState({})
  const [imageTop, setImageTop] = useState(projectDetails.imageTop)
  const [imageLeft, setImageLeft] = useState(projectDetails.imageLeft)
  const [imageSize, setImageSize] = useState(projectDetails.imageSize)
  const [showImage, setShowImage] = useState(true)
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [bounds, setBounds] = useState(createBounds({ lat: 0, lng: 0 }, 0.01, 0.01));
  const [isDragging, setIsDragging] = useState(false)
  const [overlayCenter, setOverlayCenter] = useState({})
  useEffect(() => {
    const coordinatesString = areaLatLng.length ? areaLatLng[0] : "";

    // Split the string into an array using the comma as the delimiter
    const coordinatesArray = coordinatesString?.split(", ");

    // Extract the latitude and longitude values
    const latitude = parseFloat(coordinatesArray[0]);
    const longitude = parseFloat(coordinatesArray[1]);
    const lat = latitude;
    const lng = longitude;
    setCenter({
      lat: lat,
      lng: lng,
    });
    setBounds(createBounds({ lat: lat, lng: lng }, 0.001 * projectDetails.currentZoom, 0.001 * projectDetails.currentZoom))
    setOverlayCenter({ lat: lat, lng: lng })
  }, [areaLatLng]);

  const changeImageScale = (value) => {
    if (value < 0 || value > 200) {
      toast.error(`It should not be between 0 to 200`, { toastId: "warn" });
      return;
    }

    setImageSize(value);
  }

  const projectMapConfirm = (type) => {
    setProjectMapConfirmed(true);
    addProjectDetails({
      projectMap: projectMap,
      imageRotation: imageRotation,
      imageSize: imageSize,
      imageTop: imageTop,
      imageLeft: imageLeft
    })
    if (type === 'placement') changeActiveComponent(4);
  };

  // console.log("path", path);
  // console.log("areaLatLng", areaLatLng);


  const onLoadMap = (map) => {
    map.setMapTypeId("satellite");
    mapRef.current = map;
    map.setOptions({
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: false,
      mapTypeControlOptions: {
        mapTypeIds: [], // To remove options of map view
      },
    });

    // If there are coordinates in the path array, create a Polygon or Polyline component
    if (path && path.length > 0) {
      if (projectDetails.areaType == 'Point') {
        // Single point, create a marker
        const newMarkerInstance = new window.google.maps.Marker({
          position: center,
          map: mapRef.current, // Use the map reference from useRef
          title: "Marker Title", // Optionally, set a title for the marker
        });
        setMarkerInstance(newMarkerInstance);
      } else if (path.length > 1) {
        // Path or area, create a Polygon or Polyline component
        if (projectDetails.areaType === "Path") {
          // Path, create a Polyline component
          const polyline = new window.google.maps.Polyline({
            path: path,
            map: mapRef.current,
            options: geometryOptions,
          });
          polygonRefs.current.push(polyline);
          setMarkerInstance(polyline)
        } else if (path.length > 2) {
          // Area, create a Polygon component
          const polygon = new window.google.maps.Polygon({
            paths: path,
            map: mapRef.current,
            options: geometryOptions,
          });
          polygonRefs.current.push(polygon);
          setMarkerInstance(polygon)
        }
      }
    }
  };


  useEffect(() => {
    if (markerInstance) {
      setShowOverlayView(true)
    }
  }, [markerInstance])


  useEffect(() => {
    if (projectMap) setProjectMapConfirmed(true)
  }, [])


  const getCoordinates = () => {
    if (areaLatLng && areaLatLng.length > 0) {

      if (areaLatLng.length == 1) {
        let arr = []
        const [lat, lng] = areaLatLng[0].split(", ")
        arr.push({ lat: parseFloat(lat) - 0.005, lng: parseFloat(lng) - .005 })
        arr.push({ lat: parseFloat(lat) - 0.005, lng: parseFloat(lng) + 0.005 })
        arr.push({ lat: parseFloat(lat) + 0.005, lng: parseFloat(lng) + 0.005 })
        arr.push({ lat: parseFloat(lat) + 0.005, lng: parseFloat(lng) - 0.005 })
        return arr;
      }

      return areaLatLng
        .map((latlng, index) => {
          if (typeof latlng === "string") {
            const [lat, lng] = latlng.split(", ");
            if (lat && lng) {
              return { lat: parseFloat(lat), lng: parseFloat(lng) };
            }
          }
          return null;
        })
        .filter(Boolean);
    }
    return [];
  };

  useEffect(() => {
    if (areaLatLng) {
      const coordinates = getCoordinates();
      setPath(coordinates);
      const center = coordinates.reduce((acc, cur) => {
        acc.lat += cur.lat;
        acc.lng += cur.lng;
        return acc;
      }, { lat: 0, lng: 0 });
      center.lat /= coordinates.length;
      center.lng /= coordinates.length;
      setPolygonCenter(center)
    }
  }, [areaLatLng]);

  const uploadProjectMap = async (files, component_Id) => {
    //Below we are adding condition by which as user added file it will render on page
    if (component_Id == 3) {
      const file = files[0];
      if (file.type === "application/pdf") {
        const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({ canvasContext: context, viewport }).promise;

        // Convert canvas to image URL
        const imageData = canvas.toDataURL('image/png');
        setProjectMap(imageData);
        // to send pdf image to backend
        // const blob = await fetch(imageData).then(res => res.blob());
        // const formData = new FormData();
        // formData.append('image', blob, 'image.png');

      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          //from here only we will send projectMap to formData
          setProjectMap(reader.result);
        };
        if (file) {
          // Read the file as data URL
          reader.readAsDataURL(file);
        }
      }
    }
  };
  const getPolygonBounds = (path) => {
    const bounds = new window.google.maps.LatLngBounds();
    path.forEach((point) => bounds.extend(point));
    return bounds;
  };
  const handleZoomChanged = () => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom());
    }
  }
  const handleMouseDown = (e) => {
    console.log("called",mapRef.current)
    setIsDragging(true)
    const startX = e.clientX;
    const startY = e.clientY;

    const handleMouseMove = (moveEvent) => {
      // console.log("called")
      const newX = moveEvent.clientX;
      const newY = moveEvent.clientY;
      setOffset({ x: newX - startX, y: newY - startY });
      console.log({ x: newX - startX, y: newY - startY }, "up")
    };

    const handleMouseUp = (moveEvent) => {

      // console.log("handlemout ups", offset, moveEvent)
      setIsDragging(false)
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      const newX1 = moveEvent.clientX;
      const newY1 = moveEvent.clientY;
      let offset1 = { x: newX1 - startX, y: newY1 - startY };
      // console.log(offset1, "offset1", zoom)
      const newCenter = {
        lat: overlayCenter.lat - offset1.y * (1.1 / Math.pow(2, zoom)),
        lng: overlayCenter.lng + offset1.x * (1.1 / Math.pow(2, zoom))
      };
      setOverlayCenter(newCenter);
      setBounds(createBounds(newCenter, 0.001 * projectDetails.currentZoom, 0.001 * projectDetails.currentZoom));
      setOffset({ x: 0, y: 0 });
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };
  return (
    <div className="map-body">
      {/* Upload Map */}
      {!projectMap && !projectMapConfirmed ? (
        <>
          <div className="black-ribbon d-flex align-items-center justify-content-between">
            <span className="form-heading">Upload your project map</span>
          </div>
          <div className="project-map">
            <DropFileInput
              onFileChange={(files, componentId) =>
                uploadProjectMap(files, componentId)
              }
              componentId={componentId}
            />
          </div>
        </>
      ) : null}

      {/* Uploaded Map image rendered */}
      {projectMap && !projectMapConfirmed ? (
        <>
          <div className="black-ribbon d-flex align-items-center justify-content-between">
            <span className="form-heading">Confirm your project map</span>
            <button
              type="submit"
              className="btn"
              onClick={(e) => projectMapConfirm('area')}
            >
              <img src={Lock} alt="lock" className="mb-1 mx-1" />
              <span className="mx-1">Confirm project area</span>
            </button>
          </div>
          <div className="row image-upload-map">
            <div className="col-md-8">
              <div className="project-map-image">
                <img src={projectMap} className="img-fluid" alt="image" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="map-scale">
                <h3>Define map scale (%)</h3>
                <div className="number-to">
                  <div className="relative-select">
                    <input
                      type="text"
                      className="form-control"
                      value={imageSize}
                      onChange={(e) => changeImageScale(e.target.value)}
                    />
                    <span>1 to</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* Third show map*/}

      {projectMap && projectMapConfirmed ? (
        <>
          <div className="black-ribbon d-flex align-items-center justify-content-between">
            <span className="form-heading">
              Place your uploaded map in the designated area
            </span>
            <div>
              <a onClick={() => changeActiveComponent(CreateProjectScreenTypes.SubContractors)} className="link-secondary skip-steps-right">
                Skip this step
              </a>
              <button
                type="submit"
                className="btn"
                onClick={(e) => projectMapConfirm('placement')}
              >
                <span className="bi bi-lock-fill"></span>
                <span className="mx-1">Confirm map placement</span>
              </button>
            </div>
          </div>
          <div className="map-container">
            <div className="row m-0">
              <div className="col-md-9 p-0">
                <div className="map" id="map">
                  {/* Map will be loaded here by using google map package */}
                  {isLoaded && path ? (
                    <GoogleMap
                      zoom={projectDetails.currentZoom}
                      onZoomChanged={handleZoomChanged}
                      center={polygonCenter}
                      onLoad={onLoadMap}
                      mapContainerStyle={containerStyle}
                      onTilesLoaded={() => { setCenter(null); setPolygonCenter(null) }}
                      options={{ draggable: !isDragging }}
                      onMouseDown={(e) => {
                       
                        // console.log("Mouse Down at:",e.latLng.lat());

                      }}
                    >
                      {/* {console.log(bounds)} */}
                      {showOverlayView && (
                        <OverlayView
                          bounds={getPolygonBounds(path)}
                          // bounds={bounds}
                          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                          getPixelPositionOffset={(width, height) => ({
                            // x: -width / 2,
                            // y: -height / 2,
                            x: 0,
                            y: 0
                          })}                     
                        >
                          <div
                            style={{
                              cursor: 'move',
                              // position: 'relative',
                              // transform: `rotate(${imageRotation}deg) scale(1) translate(${offset.x}px, ${offset.y}px)`,
                              // width: `${imageSize}%`,
                              // height: `${imageSize}%`,
                              zIndex: 110,
                              backgroundImage: `url(${projectMap})`,
                              backgroundSize: 'contain',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              transform: `rotate(${imageRotation}deg) scale(1)`,
                              position: "absolute",
                              top: `${imageTop}%`,
                              left: `${imageLeft}%`,
                              width: `${imageSize}%`,
                              height: `${imageSize}%`,                            
                            }}
                            onMouseDown={handleMouseDown}
                          >
                          </div>
                        </OverlayView>
                      )}
                      <div className="map-section-button map-overlay d-flex justify-content-between w-100">
                        <div className="arrow-btns">
                          <button onClick={() => setImageRotation(imageRotation + 2)}>
                            <img src={RotateRight} alt="icon" />
                          </button>
                          <button className="" onClick={() => setImageRotation(imageRotation - 2)}>
                            <img src={RotateLeft} alt="icon" />
                          </button>
                        </div>
                        <div className="d-flex">
                          <section className="arrow-btns">
                            <button onClick={() => setImageTop(imageTop - 1)}>
                              <img src={Arrow} alt="icon" />
                            </button>
                            <button onClick={() => setImageTop(imageTop + 1)}>
                              <img src={Arrow} alt="icon" className="down-arrow" />
                            </button>
                          </section>
                          <section className="arrow-btns">
                            <button onClick={() => setImageLeft(imageLeft - 1)}>
                              <img src={Arrow} alt="icon" className="left-arrow" />
                            </button>
                            <button onClick={() => setImageLeft(imageLeft + 1)}>
                              <img src={Arrow} alt="icon" className="right-arrow" />
                            </button>
                          </section>
                        </div>
                        <div className="mx-5">
                          <section className="arrow-btns">
                            <button onClick={() => changeImageScale(imageSize + 1)}>
                              <img src={Plus} alt="icon" />
                            </button>
                            <button onClick={() => changeImageScale(imageSize - 1)}>
                              <img src={Minus} alt="icon" />
                            </button>
                          </section>
                        </div>
                      </div>
                    </GoogleMap>
                  ) : null}
                </div>
              </div>
              <div className="col-md-3 p-0">
                <div className="switch-section">
                  <label>Show map: </label>
                  <Switch
                    checked={showOverlayView}
                    onChange={() => setShowOverlayView(!showOverlayView)}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={0}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 1px 5px rgba(35, 192, 232, 0.6)"
                    height={17}
                    width={30}
                    className="react-switch"
                    id="material-switch"
                  />
                </div>
                <div className="uploaded-project-map">
                  <h3>Uploaded project map:</h3>
                  <div className="map-visible">
                    <img src={projectMap} className="img-fluid" alt="image" />
                  </div>

                  <div className="mt-5">
                    <h3>Define map scale (%)</h3>
                    <div className="number-to-map">
                      <div className="relative-select">
                        <input
                          type="text"
                          className="form-control"
                          value={imageSize}
                          onChange={(e) => changeImageScale(e.target.value)}
                        />
                        <span>1 to</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ProjectMap;
