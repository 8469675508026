import {
  Autocomplete,
  GoogleMap,
  Marker,
  Polygon,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import MainProject from "../../assests/image/main-project-area.svg";
import { MAP_KEY } from "../../shared/constants/mapKeyConstant";
import VectorBlack from '../../assests/image/VectorBlack.svg';
import PlusMarker from '../../assests/image/PlusMarker.svg';
const libraries = ["places", "drawing", "geometry"];

function ProjectLocation({ props }) {
  const project = props.project;
  const addProjectDetails = props.addProjectDetails;
  const changeActiveComponent = props.changeActiveComponent;
  const areaTypes = ["Point", "Path", "Area"];
  const [areaType, setAreaType] = useState(project.areaType || "Point");
  const [selectedInput, setSelectedInput] = useState(-1);
  const [showError, setShowError] = useState([]);
  const [markerInstance, setMarkerInstance] = useState(null);
  const [confirmProjectAreaButton, setConfirmProjectAreaButton] =
    useState(false);
  const mapRef = useRef();
  const currentListenerRef = useRef(null);
  const autocompleteRef = useRef();
  const polygonRef = useRef(null);
  const polylineRef = useRef(null);
  const [zoom, setZoom] = useState(15);
  let defaultCenter = {
    lat: 22.7196,
    lng: 75.8577,
  };
  if (project.areaLatLng?.length) {
    let lastCoordinate = project.areaLatLng[project.areaLatLng?.length - 1]?.split(",")
    defaultCenter = {
      lat: Number(lastCoordinate[0]),
      lng: Number(lastCoordinate[1])
    }
  }

  const geometryOptions = {
    fillOpacity: 0.3,
    fillColor: "#FCC331",
    strokeColor: "#FCC331",
    strokeWeight: 5,
  };

  const [center, setCenter] = useState(defaultCenter);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAP_KEY,
    libraries,
  });
  const [formData, setFormData] = useState({
    projectLocation: "",
    lat: null,
    lng: null,
    areaType: "",
    areaLatLng: [],
  });
  const [markerPosition, setMarkerPosition] = useState(defaultCenter)

  useEffect(() => {
    changeAreaType(areaType);
    if (project.areaLatLng?.length) {
      setFormData({ ...formData, areaLatLng: project.areaLatLng, areaType: project.areaType })
    }
  }, []);


  const changeAreaType = (areaType) => {
    console.log(areaType)
    if (currentListenerRef.current) {
      currentListenerRef.current.remove();
    }
    setShowError([]);
    setSelectedInput(-1)
    setAreaType(areaType);
    setConfirmProjectAreaButton(false);
    let areaLatLng = [];
    // switch (areaType) {
    //   case "Point":
    //     areaLatLng.push("");
    //     break;
    //   case "Path":
    //     areaLatLng.push("");
    //     areaLatLng.push("");
    //     break;
    //   case "Area":
    //     areaLatLng.push("");
    //     areaLatLng.push("");
    //     areaLatLng.push("");
    //     break;
    //   default:
    //     areaLatLng = [];
    //     break;
    // }

    setFormData({
      ...formData,
      areaType,
      areaLatLng,
    });
  };

  //Below function is created to edit a input field
  const editInputField = (index) => {
    setSelectedInput(index);

    if (currentListenerRef.current) {
      currentListenerRef.current.remove();
    }

    currentListenerRef.current = mapRef.current.addListener('click', (event) => {
      const latLng = event.latLng;
      // used below to handle custom cursor 8 / Math.pow(2, mapRef.current.getZoom())
      // const clickedLatLng = {
      //   lat: latLng.lat() - 8 / Math.pow(2, mapRef.current.getZoom()),
      //   lng: latLng.lng() + 8 / Math.pow(2, mapRef.current.getZoom())
      // };

      let markerCoordinate = {
        lat: latLng.lat(),
        lng: latLng.lng()
      }
      // console.log("clickedLatLng", clickedLatLng);

      //add marker on clicked position
      setMarkerPosition(markerCoordinate);
      updateMarkerPosition(markerCoordinate);
      addLatLng(`${markerCoordinate.lat.toFixed(5)}, ${markerCoordinate.lng.toFixed(5)}`, index, formData);
    })

    //Below functionality help as us to focus on input field 
    let input = document.getElementById(`latLngInput${index}`);
    if (input) {
      input.focus();
    } else {
      console.error("Input field with ID '" + input + "' not found.");
    }
  }

  const addInputField = (e, formData) => {
    e.preventDefault();
    formData.areaLatLng.push("");
    setFormData({ ...formData });
    onFocus();
  };

  const onFocus = (index) => {
    if (!index) {
      var index = formData.areaLatLng?.length - 2;
    }
    let input = document.getElementById(`latLngInput${index}`);
    if (input) {
      input.focus();
    } else {
      console.error("Input field with ID '" + input + "' not found.");
    }
  };


  useEffect(() => {
    if (formData.areaLatLng && formData.areaLatLng.length > 0) {

      let latLng = formData.areaLatLng[0];
      let lat = latLng.split(',')[0];
      let lng = latLng.split(', ')[1];  //for second index put space after comma (', ')

      setFormData({ ...formData, lat: lat, lng: lng })
    }
  }, [formData.areaLatLng[0]])


  const addLatLng = (latLng, index, formData) => {

    let areaLatLng = formData.areaLatLng
    areaLatLng[index] = latLng;
    setFormData({ ...formData, areaLatLng: areaLatLng });

  };

  const onSave = (e, index) => {
    setSelectedInput(-1);
    currentListenerRef.current?.remove();
    let latLng = document.getElementById(`latLngInput${index}`).value;

    if (latLng && latLng != '' && latLng !== null) {
      let validation = validationCheck(latLng);
      if (!validation) {
        console.error("error: Validate lat lng");
        setShowError(current => [...current, index])
      } else {
        setShowError(oldValues => {
          return oldValues.filter(showError => showError !== index)
        })
      }
    }

    // Remove the marker instance when the user saves the input field
    if (markerInstance) {
      markerInstance.setMap(null);
      setMarkerInstance(null);
    }
  }

  //Validation function validate Lat Lng provided to it
  //regex is such that string should contain two dots and one comma
  const validationCheck = (latLng) => {
    const regex = /^(?=.*?,)(?=(?:[^.]*?\.){2}[^.]*?$).*$/;
    return regex.test(latLng);
  };



  const clearInputField = (e, index, formData) => {
    e.preventDefault();
    // formData.areaLatLng[index] = "";
    formData.areaLatLng.splice(index, 1)

    setFormData({ ...formData });

    setNewMarkerPosition(index);

    //After clearing data from input field we will remove error, as there no lat,lng which we have to validate
    setShowError((oldValues) => {
      return oldValues.filter((showError) => showError !== index);
    });
  };

  const setNewMarkerPosition = (index) => {

    let nearestValue = null;

    for (let i = index; i >= 0; i--) {
      if (formData.areaLatLng[i] && formData.areaLatLng[i] !== '') {
        nearestValue = formData.areaLatLng[i];
        break;
      }
    }

    if (nearestValue === null) {
      for (let i = index + 1; i < formData.areaLatLng.length; i++) {
        if (formData.areaLatLng[i] && formData.areaLatLng[i] !== '') {
          nearestValue = formData.areaLatLng[i];
          break;
        }
      }
    }

    // Convert nearestValue into an object with lat and lng properties
    if (nearestValue != null) {
      const [lat, lng] = nearestValue.split(',').map(parseFloat);
      const result = { lat, lng };
      setMarkerPosition(result);
    }
  }

  const confirmProjectArea = (e) => {
    e.preventDefault();

    let updatedFormData = { ...formData, areaLatLng: formData.areaLatLng.filter(item => Boolean(item)) };
    setFormData(updatedFormData);

    calculateArea(path);
    addProjectDetails(updatedFormData);
    changeActiveComponent(3);
  };

  // By below we can are able to get area of polygon
  const calculateArea = (polygonCoordinates) => {

    switch (areaType) {
      case 'Area':
        if (window.google) {
          const area = window.google.maps.geometry.spherical.computeArea(polygonCoordinates);
          addProjectDetails({ ...formData, boundry: area })
        } else {
          console.error('Google Maps API not loaded');
        }
        break;
      case 'Path':
        if (window.google && path) {
          const pathLength = window.google.maps.geometry.spherical.computeLength(
            path.map(point => new window.google.maps.LatLng(point.lat, point.lng))
          );
          addProjectDetails({ ...formData, boundry: pathLength })
        } else {
          console.error('Google Maps API not loaded');
        }
        break;
      case 'Point':
        if (window.google && path) {
          addProjectDetails({ ...formData, boundry: null })
        } else {
          console.error('Google Maps API not loaded');
        }
        break;
      default:
        break;
    }

  };


  const onLoadMap = (map) => {
    map.setMapTypeId("satellite");
    mapRef.current = map;
    map.setOptions({
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: false,
      mapTypeControlOptions: {
        mapTypeIds: [], // To remove options of map view
      },
      draggableCursor: `url(${VectorBlack}), auto`
      // draggableCursor: "crosshair"
    });
  };


  const containerStyle = {
    width: "100%",
    height: "90vh",
  };

  const onLoadAutocomplete = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    const getGeometry = autocompleteRef.current?.getPlace();
    if (!getGeometry?.geometry) return null;
    const { geometry } = autocompleteRef.current?.getPlace();
    const bounds = new window.google.maps.LatLngBounds();
    if (geometry.viewport) {
      bounds.union(geometry.viewport);
    } else {
      bounds.extend(geometry.location);
    }
    mapRef.current.fitBounds(bounds);

    //add marker on clicked position
    updateMarkerPosition(geometry.location);
  };

  const updateMarkerPosition = (latLng) => {
    if (markerInstance) {
      markerInstance.setPosition(latLng);
    }
  }

  const getCoordinates = () => {
    if (formData.areaLatLng.length > 0) {
      return formData.areaLatLng
        .map((latlng, index) => {
          if (typeof latlng === "string") {
            const [lat, lng] = latlng.split(", ");
            if (lat && lng) {
              return { lat: parseFloat(lat), lng: parseFloat(lng) };
            }
          }
          return null;
        })
        .filter(Boolean);
    }
    return [];
  };

  // Call getCoordinates to retrieve path
  const path = getCoordinates();

  useEffect(() => {
    if (formData) {
      //We are nulify markerInstance here to remove ballon/marker
      setTimeout(() => {
        if (markerInstance) {
          markerInstance.setMap(null);
          setMarkerInstance(null);
        }
      }, [5000]);
    }
  }, [formData]);

  useEffect(() => {
    if (formData) {
      validateConfirmProjectAreaButton();
    }
  }, [formData, showError, areaType]);

  //Below function help us to validate Confirm-Project-Area-Button i.e. when to disable and when to enable it
  const validateConfirmProjectAreaButton = () => {
    let isValid = false;
    switch (areaType) {
      case "Point":
        isValid =
          formData.areaLatLng.length == 1 &&
          showError.length == 0 &&
          formData.areaLatLng.every((value) => value.trim() !== "");
        break;

      case "Path":
        isValid =
          formData.areaLatLng.length >= 2 &&
          showError.length == 0 &&
          formData.areaLatLng.every((value) => value.trim() !== "");
        break;

      case "Area":
        //In case of areaType=area we checking starting 3 values of array i.e. they should not be blank
        isValid =
          formData.areaLatLng.length >= 3 &&
          showError.length == 0 &&
          formData.areaLatLng.slice(0, 3).every((value) => value.trim() !== "");
        break;

      default:
        break;
    }

    setConfirmProjectAreaButton(isValid);
  };


  const handleZoomChanged = () => {
    if (mapRef.current) {

      addProjectDetails({ currentZoom: mapRef.current.getZoom() })
    }
  };
  const handleAutoCompleteKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  }

  const handleMapClick = (event) => {
    console.log("handleMapClick called")
    if (areaType === "Point") {
      const markerCoordinate = event.latLng;
      setMarkerPosition({
        lat: markerCoordinate.lat(),
        lng: markerCoordinate.lng()
      })
      addLatLng(`${markerCoordinate.lat()?.toFixed(5)}, ${markerCoordinate.lng()?.toFixed(5)}`, 0, formData);
    } else if (areaType === "Path") {
      const markerCoordinate = event.latLng;
      setMarkerPosition({
        lat: markerCoordinate.lat(),
        lng: markerCoordinate.lng()
      })
      addLatLng(`${markerCoordinate.lat()?.toFixed(5)}, ${markerCoordinate.lng()?.toFixed(5)}`, formData.areaLatLng?.length, formData);
    } else if (areaType === "Area") {
      const markerCoordinate = event.latLng;
      setMarkerPosition({
        lat: markerCoordinate.lat(),
        lng: markerCoordinate.lng()
      })
      addLatLng(`${markerCoordinate.lat()?.toFixed(5)}, ${markerCoordinate.lng()?.toFixed(5)}`, formData.areaLatLng?.length, formData);
    }
  }
  return (
    <form className="map-body">
      <div className="black-ribbon d-flex align-items-center justify-content-between q">
        <span className="form-heading">Draw your project area on the map</span>
        <button
          type="submit"
          className={`btn ${confirmProjectAreaButton == true ? null : "disabled"
            }`}

          onClick={(e) => confirmProjectArea(e)}
        >
          {/* <span className="bi bi-lock-fill"></span> */}
          {confirmProjectAreaButton == true ?
            <span className="mx-1">Confirm project area</span>
            :
            <span className="mx-1 btn-disabled">Define project area to proceed</span>
          }
        </button>
      </div>
      <div className="map-container">
        {/* Map will be loaded here */}
        {isLoaded ? (
          <GoogleMap
            zoom={zoom || project.currentZoom}
            center={center}
            onLoad={onLoadMap}
            mapContainerStyle={containerStyle}
            onTilesLoaded={() => setCenter(null)}
            onZoomChanged={handleZoomChanged}
            onClick={handleMapClick}
          >
            <div className="dark-overlay"></div>
            <Autocomplete
              onLoad={onLoadAutocomplete}
              onPlaceChanged={onPlaceChanged}
              className="map-overlay"
            >
              <div className="search-field">
                <span class="bi bi-search"></span>
                <input type="text" placeholder="Navigate to location" onKeyDown={handleAutoCompleteKeyDown} />
              </div>
            </Autocomplete>
            <div className="d-flex justify-content-between">
              <div>

              </div>
              <div className="map-zoom" style={{ marginTop: -20 }}>
                <button type="button" className="btn zoom-btn" onClick={(e) => { e.preventDefault(); setZoom(zoom + 1) }}>
                  +
                </button>

                <button type="button" className="btn zoom-btn" onClick={(e) => { e.preventDefault(); setZoom(zoom - 1) }}>
                  -
                </button>
              </div>
            </div>
            <Marker
              position={markerPosition}
              icon={{
                url: PlusMarker,
                anchor: new window.google.maps.Point(12, 12),
                origin: new window.google.maps.Point(0, 0),
                scaledSize: new window.google.maps.Size(24, 24)
              }}
            />
            {/* Rendering the Polyline*/}
            {areaType === "Path" && (
              <Polyline
                path={path}
                options={{ ...geometryOptions, editable: true, zIndex: 1000 }}
                onMouseUp={(event) => {
                  const newPaths = polylineRef.current.getPath().getArray().map(coord => (
                    `${coord.lat()?.toFixed(5)}, ${coord.lng()?.toFixed(5)}`
                  ));
                  setFormData({ ...formData, areaLatLng: newPaths })
                  console.log(newPaths)
                  console.log(event, "polygon event")
                }}
                onLoad={(poly) => {
                  polylineRef.current = poly
                }}
              />
            )}

            {/* Rendering the Polygon*/}
            {areaType === "Area" && (
              <Polygon
                ref={polygonRef}
                paths={path}
                options={{ ...geometryOptions, editable: true, zIndex: 1000 }}
                onMouseUp={(event) => {
                  const newPaths = polygonRef.current.getPath().getArray().map(coord => (
                    `${coord.lat()?.toFixed(5)}, ${coord.lng()?.toFixed(5)}`
                  ));
                  setFormData({ ...formData, areaLatLng: newPaths })
                  console.log(newPaths)
                  console.log(event, "polygon event")
                }}
                onLoad={(poly) => {
                  polygonRef.current = poly
                }}
              />
            )}

            {/* Form to fill lat lng starts from here */}
            <div className="map-area map-overlay">
              <div className="yellow-line"></div>
              <span className="form-heading">
                <img src={MainProject} alt="icon" />
                Main project area
              </span>

              <label className="mt-3 d-block form-label area-type">
                Area type
              </label>
              <div className="area-tabs">
                {areaTypes.map((areaType) => (
                  <button
                    key={areaType}
                    type="button"
                    className={`btn btn-lg ${formData.areaType === areaType ? "active" : ""
                      }`}
                    onClick={() => changeAreaType(areaType)}
                  >
                    {areaType}
                  </button>
                ))}
              </div>

              <label className="mt-4 d-block form-label text-yellow">
                Define Project area
              </label>
              <div className={areaType !== "Point" ? `overflow` : null}>
                {formData.areaLatLng.map((latLng, index) => (
                  <div
                    key={index}
                    className={`poi-input ${latLng == "" ? "empty" : null} ${index === selectedInput ? "editable" : ""
                      } ${showError.includes(index) ? "show-error" : null}`}
                  >
                    <span className="prefix">
                      {(() => {
                        switch (areaType) {
                          case "Point":
                            return "POI";
                          case "Path":
                            return `POINT ${index + 1}`
                          // switch (index) {
                          //   case 0:
                          //     return "PATH A";
                          //   case 1:
                          //     return "PATH B";
                          //   default:
                          //     return null;
                          // }
                          case "Area":
                            return `POINT ${index + 1}`;
                          default:
                            return null;
                        }
                      })()}

                      {latLng ? <span className="dot-circle"></span> : null}
                    </span>

                    <input
                      type="text"
                      id={`latLngInput${index}`}
                      value={latLng}
                      name="latLng"
                      autocomplete="off"
                      onChange={(e) => addLatLng(e.target.value, index, formData)}
                    />
                    <button
                      type="button"
                      onClick={(e) => onSave(e, index)}
                      className="btn save-marker-btn"
                    >
                      <span class="bi bi-save"></span>
                    </button>
                    <button
                      type="button"
                      // onClick={() => editInputField(index)}
                      onClick={() => setSelectedInput(index)}
                      className="btn enable-marker-btn"
                    >
                      Locate on map or enter coordinates
                    </button>
                    <button
                      type="button"
                      // onClick={() => editInputField(index)}
                      onClick={() => setSelectedInput(index)}
                      className="btn edit-btn"
                    >
                      <span class="bi bi-pencil"></span>
                    </button>
                    <button
                      type="button"
                      onClick={(e) => clearInputField(e, index, formData)}
                      className="btn delete-btn"
                    >
                      <span class="bi bi-x"></span>
                    </button>
                  </div>
                ))}
              </div>
              <div className="d-flex gap-2">
                {/* Button to add input field */}
                {areaType !== "Point" && (
                  <button
                    type="button"
                    className="btn main-project-area"
                    onClick={(e) => addInputField(e, formData)}
                  >
                    <i className="bi bi-plus-circle"></i>
                    Enter coordinates
                  </button>
                )}
              </div>
            </div>


          </GoogleMap>
        ) : null}
      </div>
    </form>
  );
}

export default ProjectLocation;
