import React, { useEffect, useState } from "react";
import { CreateProjectScreenTypes } from "../../shared/enums/screenTypeEnum";
import Sidebar from "../../shared/sharedComponents/sidebar";
import ProjectContractors from "./projectContractors";
import ProjectDetails from "./projectDetails";
import ProjectLocation from "./projectLocation";
import ProjectMap from "./projectMap";
import ProjectSummary from "./projectSummary";
import { createProject } from "../../services/projectServices"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function CreateProject() {
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState(
    CreateProjectScreenTypes.Details
  );

  const [project, setProject] = useState({
    clientId: null,
    internalReference: "",
    externalReference: "",
    projectTitle: "",
    projectReference: "",
    description: "",
    startDate: null,
    endDate: null,
    productOwnerId: null,
    haveSubprojects: 1,
    projectLocation: null,
    lat: "22.71819",
    lng: "75.85179",
    areaType: null,
    areaLatLng: [],
    boundry: 10,
    boundryUnit: "meter",
    projectMap: null,
    imageTop: 0,
    imageLeft: 0,
    imageSize: 100,
    imageRotation: 0,
    currentZoom: 15,
    projectFiles: [],
    projectSubcontractor: [],
    status: ""
  });

  const [completedSteps, setCompletedSteps] = useState([0]);

  useEffect(() => {
    console.log("Project", project);
  }, [project])


  const addProjectDetails = (data) => {
    console.log("data", data);
    setProject({ ...project, ...data });
  };

  useEffect(() => {

    (function sanetizeLatLng() {
      let updatedProject = { ...project, areaLatLng: project.areaLatLng.filter(item => Boolean(item)) };
      setProject({ ...project, ...updatedProject });
    })();

  }, [activeComponent])



  const changeActiveComponent = (componentName) => {

    if (componentName === 0) {
      setActiveComponent(componentName);

    } else if (componentName === 1) {
      setActiveComponent(componentName);
      setCompletedSteps([...completedSteps])
    } else if (componentName === 2) {
      setActiveComponent(componentName);
      setCompletedSteps([...completedSteps, 1])
    } else if (componentName === 3) {
      setActiveComponent(componentName);
      setCompletedSteps([...completedSteps, 2])
    } else if (componentName === 4) {
      setActiveComponent(componentName);
      setCompletedSteps([...completedSteps, 3])
    } else if (componentName === 5) {
      setActiveComponent(componentName);
      setCompletedSteps([...completedSteps, 4])
    }
  };

  const submitProject = (data) => {
    createProject({ ...project, ...data }).then(result => {
      toast.success(`Project has been created successfully`, { toastId: "warn" });
      navigate(`/projects/view/${result.id}`);
    });
  };

  return (
    <>
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Projects </a>
              </li>
              <li className="breadcrumb-item active">{project.projectTitle == '' ? `Create New project` : project.projectTitle}</li>
            </ol>
          </nav>
        </div>

        {
          activeComponent !== CreateProjectScreenTypes.Summary
            ?
            <div className="page-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <h3 className="page-title">Project configuration</h3>
                    {
                      activeComponent == CreateProjectScreenTypes.SubContractors ?
                        <div><a onClick={() => changeActiveComponent(CreateProjectScreenTypes.Summary)} className="skip-for">Skip for now</a>
                          <button onClick={() =>
                            changeActiveComponent(CreateProjectScreenTypes.Summary)
                          } className="create-btn-project">Create project</button>
                        </div>
                        : null
                    }
                  </div>

                  <div className="tab-container my-4 mx-0">
                    {Object.keys(CreateProjectScreenTypes).map((key) => (
                      CreateProjectScreenTypes[key] !== CreateProjectScreenTypes.Summary && (
                        <button
                          key={CreateProjectScreenTypes[key]}
                          type="button"
                          className={`btn btn-default ${activeComponent === CreateProjectScreenTypes[key]
                            ? "active"
                            : ""
                            }`}
                          disabled={!completedSteps.includes(Object.keys(CreateProjectScreenTypes).indexOf(key))}
                          onClick={() =>
                            changeActiveComponent(CreateProjectScreenTypes[key])
                          }
                        >
                          {key}
                        </button>
                      )
                    ))}
                  </div>

                </div>
              </div>
            </div>
            :
            null
        }
        {(() => {
          switch (activeComponent) {
            case CreateProjectScreenTypes.Details:
              return (
                <ProjectDetails
                  props={{
                    project: project,
                    addProjectDetails: addProjectDetails,
                    changeActiveComponent: changeActiveComponent,
                  }}
                />
              );
            case CreateProjectScreenTypes.Location:
              return (
                <ProjectLocation
                  props={{
                    project: project,
                    addProjectDetails: addProjectDetails,
                    changeActiveComponent: changeActiveComponent,
                  }}
                />
              );
            case CreateProjectScreenTypes.Map:
              return (
                <ProjectMap
                  props={{
                    project: project,
                    addProjectDetails: addProjectDetails,
                    changeActiveComponent: changeActiveComponent,
                  }}
                />
              );
            case CreateProjectScreenTypes.SubContractors:
              return <ProjectContractors
                props={{
                  project: project,
                  addProjectDetails: addProjectDetails,
                  submitProject: submitProject,
                  changeActiveComponent: changeActiveComponent,
                }}
              />;
            case CreateProjectScreenTypes.Summary:
              return (
                <ProjectSummary
                  props={{
                    project: project,
                    addProjectDetails: addProjectDetails,
                    submitProject: submitProject,
                    changeActiveComponent: changeActiveComponent,
                  }} />
              );
            default:
              return null;
          }
        })()}
      </main>
    </>
  );
}

export default CreateProject;
