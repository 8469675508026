import { backdropClasses } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundComponent = () => {


    console.log("Not Found Component");
    const navigate = useNavigate()

    const redirectToHomePage = (e) => {
        e.preventDefault();
        navigate('/');
    };

    const centeredDivStyle = {
        backgroundColor: '#1C2041E5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        color: 'white',
    };

    return (
        <div style={centeredDivStyle}>
            <div className="container">
                <h1>404</h1>
                <h3>Data Not Found</h3>
                <p>The requested data was not found.</p>
                <button className="btn btn-primary" onClick={(e) => redirectToHomePage(e)}>Go to Home Page</button>
            </div>
        </div>
    );

};

export default NotFoundComponent;
