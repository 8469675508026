import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import CreateProject from "./components/createProject/index";
import AllProjects from "./components/allProjects/index";
import SubprojectDetail from './components/SubProject/SubprojectSummary.jsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SubProject from "./components/SubProject/Index.jsx";
import SubContractors from "./components/SubContractors/index.jsx";
import CreateSubContractor from "./components/SubContractors/CreateSubContractor";
import ProjectView from './components/allProjects/ProjectView';
import {SubProjectView} from './components/viewSubProjects/SubProjectView';
import NotFoundComponent from './shared/sharedComponents/NotFoundComponent.jsx';
import ViewMilestones from './components/viewMilestones';
import Milestone from './components/viewMilestones/Milestone.jsx';


function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<CreateProject />} />
          <Route path="projects" element={<AllProjects />} />
          <Route path="projects/add" element={<CreateProject />} />
          <Route path='projects/view/:id' element={< ProjectView />} />
          <Route path='sub-project/:id' element={<SubProject />} />
          <Route path='project/:projectId/sub-project/:id' element={<SubProjectView />} />
          <Route path='sub-project-detail' element={< SubprojectDetail />} />
          <Route path='sub-contractors' element={< SubContractors />} />
          <Route path="sub-contractors/add" element={< CreateSubContractor />} />
          <Route path='milestones' element={<ViewMilestones />} />
          <Route path='project/:projectId/sub-project/:subProjectId/milestone/:id' element={<Milestone />} />
          <Route path='milestone/:id' element={<Milestone />} />
          <Route component={NotFoundComponent} /> 
        </Routes>
      </BrowserRouter>
      <ToastContainer position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={true}
        theme='colored'
      />
    </div>
  );
}

export default App;
