import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import marker from "../../assests/image/marker-06.svg";
import SubContractor from "../../assests/image/Subcontractor.svg";
import Files from "../../assests/image/file.svg";
import { useParams, Link } from "react-router-dom";
import ConfirmationModal from "../../shared/sharedComponents/confirmationModal.jsx";
import { toast } from "react-toastify";
import { getSubContractorById } from '../../services/subContractorServices';
import NotFoundComponent from '../../shared/sharedComponents/NotFoundComponent.jsx';
import { getSubProjectById, updateSubProjectDescription, getSubProjectFilesById, addSubProjectDocument, renameSubProjectDocument, deleteSubProjectDocument, getSubProjectMilestonesById } from '../../services/subProjectServices';
import { getSubProjectCategories } from "../../services/subProjectCategories";
import getFileIcon from "../../shared/utils/getFileIcon.jsx"

const SubProjectBasicDetails = () => {
    // const { subProject, subContractor, subProjectFiles } = props;
    const location = useParams();

    const [renameIndex, setRenameIndex] = useState(-1);
    const [deleteFileId, setDeleteFileId] = useState();
    const [fileData, setFileData] = useState({});
    const [saveButton, setSaveButton] = useState(false);
    const [descriptionEditable, setDescriptionEditable] = useState();
    const [showModal, setShowModal] = useState(false);
    const [subProject, setSubProject] = useState();
    const [subContractor, setSubContractor] = useState();
    const [subProjectFiles, setSubProjectFiles] = useState();
    const [data, setData] = useState();
    const [error, setError] = useState(false);
    const [subProjectCategories, setSubProjectCategories] = useState([]);


    const inputRefs = useRef([]);
    const textareaRef = useRef(null);
    const uploadedFileRef = useRef(null);
    // const [subProjectFiles, setSubProjectFiles] = useState();

    useEffect(() => {
        getSubProject();
        getSubProjectFiles();
        subProjectCategory();
    }, [])

    const getSubProject = () => {
        getSubProjectById(location.id).then(result => {
            result.areaLatLng = result?.areaLatLng?.split("--") || [];
            setSubProject(result);
            console.log("result", result);
            console.log("subContractorId", result?.subcontractorId);

            getSubContractorById(result?.subcontractorId).then(res => {
                console.log("res", res);

                setSubContractor(res);
            })

        }).catch(err => {
            console.log(err);
            setError(true);
        });
    }

    const subProjectCategory = () => {
        getSubProjectCategories().then(result => {
            const names = result.map(item => item.name);
            setSubProjectCategories(names);
        });
    }

    const changeFileName = (e, documentId, documentName, index) => {
        e.preventDefault();

        let newName = e.target.value;

        // if (newName.split(".")[1] !== documentName.split(".")[1]) {
        //     toast.warn("Please enter file name in same format", { toastId: "warn", });
        //     return;
        // }

        const updatedFiles = [...subProjectFiles];
        updatedFiles[index] = { ...subProjectFiles[index], documentName: newName };
        setSubProjectFiles(updatedFiles);

        setData({
            "id": documentId,
            "documentName": newName
        })
    }

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            renameSubProjectDocument(data);
        }, 1000);

        return () => {
            console.log('Clearing debounce timer');
            clearTimeout(debounceTimer);
        };
    }, [data])

    const handleBlur = () => {
        setRenameIndex(-1);
    };

    const changeOrignalFileName = (e, file) => {
        let newData = { ...fileData, documentName: e.target.value };
        setFileData(newData);
    }

    const handleRename = (e, index) => {
        e.preventDefault();
        setRenameIndex(index);

        setTimeout(() => {
            const inputField = inputRefs.current[index];
            inputField.focus();
            inputField.setSelectionRange(0, 0);
        }, 100);
    }

    const deleteFile = async (e, id) => {
        e.preventDefault();
        setShowModal(true);
        setDeleteFileId(id);
    }

    const handleFileUpload = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.onchange = handleFileSelect;
        fileInput.click();
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0];
        console.log("selectedFile", selectedFile);

        let data = {
            subprojectId: subProject.id,
            documentName: selectedFile.name,
            subprojectFiles: selectedFile
        }

        setFileData(data);

        setTimeout(() => {
            if (uploadedFileRef.current) {
                uploadedFileRef.current.focus();
                uploadedFileRef.current.setSelectionRange(0, 0)
            }
        }, 100)

        setSaveButton(true);
    };


    const handleSaveFile = (e, fileData) => {
        e.preventDefault();

        addSubProjectDocument(fileData)
            .then(res => {
                getSubProjectFiles();
                toast.success(`${res.documentName} Uploaded Successfully`, { toastId: "success", });
            })
            .catch(err => {
                toast.error("Error", { toastId: "error", });
                console.log(err);
            })


        setFileData(null);
        setSaveButton(false);
    }


    const getSubProjectFiles = () => {
        getSubProjectFilesById(location.id).then(result => {
            setSubProjectFiles(result);
        }).catch(err => {
            console.log(err);
        })
    }
    const handleDescription = (e) => {
        e.preventDefault();

        setDescriptionEditable(!descriptionEditable);

        if ((subProject?.description).length > 250) {
            setSubProject({ ...subProject, description: subProject.description.slice(0, 250) });
            toast.warn("Project description should be less than 250 characters", {
                toastId: "warn",
            });
            return;
        }

        if (descriptionEditable) {
            let updateData = {
                id: subProject.id,
                description: subProject.description
            }
            console.log("updateData", updateData);
            updateSubProjectDescription(updateData);
        }
    }

    const handleDescriptionChange = (e) => {
        e.preventDefault();
        setSubProject({ ...subProject, description: e.target.value })
    }

    const handleDelete = async () => {
        try {
            await deleteSubProjectDocument(deleteFileId);
            toast.warn("Document Deleted", { toastId: "warn", });
            getSubProjectFiles();
        } catch (error) {
            console.error("Error deleting file:", error);
        }
        setShowModal(false);
    }

    const handleCancel = () => {
        setShowModal(false);
    };

    if (error) {
        return <NotFoundComponent />
    }

    console.log("fileData", fileData);

    return (
        <>
            <ConfirmationModal
                show={showModal}
                title="Confirm Deletion"
                body="Are you sure you want to delete this file?"
                onConfirm={handleDelete}
                onCancel={handleCancel}
            />

            <div className="project-area-client">
                <div className="d-flex">
                    <div className="col-md-4 rennovation-heading border-one">
                        <h3>Project &nbsp;/&nbsp; Area </h3>
                        <p className="project-detail-address font-lap">
                        <img src={marker} alt="image" className="margin-r-7" />{subProject?.title}</p>
                        <div className="address-part" >
                            <h3 className="mb-1">Area details</h3>
                        </div>
                        <div className="Sub-contrators-part part-sub-project">
                            {subProject && subProject.areaLatLng.length > 0 && subProject?.areaLatLng.map((latlng, index) => {
                                return (
                                    <div className="company-name" key={index}>
                                        <p>{latlng}</p>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>

                    <div className="col-md-4 rennovation-heading border-one">
                        <div className="d-flex justify-content-between">
                            <div className="enexis project-detail-enexis">
                                <h3 className="mb-20-sapce">Category</h3>
                                <p className="sub-contractor-lap">{subProjectCategories[subProject?.category] ?? "NA"}</p>
                            </div>
                            <div className="enexis  project-detail-enexis">
                                <h3 >Sub-contractor</h3>
                                <p className="mb-0 sub-contractor-lap">
                                    <img src={SubContractor} className="SubContractor-img" alt="icon" />
                                    {`${subContractor?.firstName} ${subContractor?.lastName}`}</p>
                            </div>
                        </div>
                        <div className="attachment detail-attachment">
                            <h3 className="mb-1">Attachments</h3>
                            {(subProjectFiles && subProjectFiles.length > 0) || (fileData && Object.keys(fileData).length !== 0) ?
                                <div className="attachment-autoy">


                                    {subProjectFiles && subProjectFiles.length > 0 && subProjectFiles.map((item, index) => {
                                        return <p key={item?.id} className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex w-100">
                                                {getFileIcon(item.documentPath)}
                                                <input
                                                    className="input-file"
                                                    disabled={renameIndex !== index}
                                                    ref={el => inputRefs.current[index] = el}
                                                    value={(item.documentName)}
                                                    onChange={(e) => changeFileName(e, item.id, item.documentName, index)}
                                                    onBlur={handleBlur}
                                                    // handleKeyDown ={(e) => handleKeyPress(e, index)}
                                                    autoFocus
                                                />

                                            </div>

                                            <div className="dropdown">
                                                <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <svg width="12" height="14" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                    </svg>
                                                </button>
                                                <ul className="dropdown-menu bg-white">
                                                    <li><a className="dropdown-item" href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleRename(e, index);
                                                        }}
                                                    >Rename</a></li>
                                                    <li><a className="dropdown-item " href="#" style={{ color: "#EA503D" }} onClick={(e) => deleteFile(e, item?.id)}>Delete</a></li>
                                                </ul>
                                            </div>

                                        </p>
                                    })}

                                    {fileData ?
                                        <div className={`${Object.keys(fileData).length !== 0 ? 'd-block relative img-file-input' : 'd-none'}`}>
                                            <input
                                                ref={uploadedFileRef}
                                                className="form-control edit-input-file"
                                                type="text"
                                                value={fileData?.documentName}
                                                onChange={(e) => changeOrignalFileName(e)}
                                            />
                                            <img src={Files} alt="file" />
                                        </div>
                                        : null
                                    }

                                </div>
                                :
                                <div className="none-attachment"></div>
                            }
                        </div>
                        {saveButton ?
                            <button className="savefile" onClick={(e) => handleSaveFile(e, fileData)}>Save</button>
                            :
                            <button className="uploadfile" onClick={(e) => handleFileUpload(e)}>Upload file</button>
                        }
                    </div>

                    <div className="col-md-4 rennovation-heading p-20">
                        <div className="d-flex justify-content-between">
                            <div className="enexis">
                                <h3>Project start date</h3>
                                <p className="date-font ">{moment(subProject?.startDate).format("DD-MM-YYYY")}</p>
                            </div>
                            <div className="enexis">
                                <h3>Project end date</h3>
                                <p className="date-font">{moment(subProject?.endDate).format("DD-MM-YYYY")}</p>
                            </div>
                            <div className="enexis">
                                <h3>Status</h3>
                                <button className="sub-in-created-btn">{subProject?.actual_status}</button>
                            </div>
                        </div>

                        <div className="Description-text align-d">
                            <div className="d-flex align-items-center justify-content-between">
                                <h3 className="mb-0">Description</h3>
                                <button
                                    className="btn btn-sm btn-light float-end"
                                    onClick={(e) => handleDescription(e)}>
                                    {descriptionEditable ? `Save` : `Edit`}
                                </button>
                            </div>
                            <textarea
                                style={{ height: "160px" }}
                                ref={textareaRef}
                                className="form-control"
                                type="text"
                                maxlength="250"
                                value={subProject?.description}
                                disabled={descriptionEditable ? false : true}
                                onChange={(e) => { handleDescriptionChange(e) }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SubProjectBasicDetails                      