import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import PlannedCheckMark from "../../assests/image/planned-chekmark.svg";
import LockPlain from '../../assests/image/lock-plain.svg';
import DragIcon from '../../assests/image/drag-icon.svg';
import { getSubProjectMilestonesById, getSubProjectById } from '../../services/subProjectServices'
import { updateMilestoneDetail } from '../../services/milestoneServices'
import { toast } from 'react-toastify';

function MilestoneTimelineModal(props) {
    const { subProjectId, id } = useParams();
    console.log("props", props);
    const [milestones, setMilestones] = useState([]);
    const [dates, setDates] = useState([]);
    const [draggedMilestone, setDraggedMilestone] = useState(null);
    const [highlightedDate, setHighlightedDate] = useState(null);
    const [disableConfirmButton, setDisableConfirmButton] = useState(true)
    useEffect(() => {
        getSubProjectMilestones();
        getSubProject();
    }, [])

    const getSubProjectMilestones = () => {
        getSubProjectMilestonesById(subProjectId).then(result => {
            if (Array.isArray(result)) {             
                result.forEach((item, i) => {
                    item.areaLatLng = item.areaLatLng?.split("--") || [];
                    if (item.id != id) item.deliveryDate = item.deliveryDate ? moment(item.deliveryDate).format("YYYY-MM-DD") : "";
                    else item.deliveryDate = null;
                    item.index = i
                })
                setMilestones(result)
            }
        }).catch(err => {
            console.error(err);
        })
    }

    const getSubProject = () => {
        getSubProjectById(subProjectId).then(result => {
            if (result.startDate && result.endDate) setDates(enumerateDaysBetweenDates(moment(result.startDate)?.format("YYYY-MM-DD"), moment(result.endDate)?.format("YYYY-MM-DD")))
        }).catch(err => {
            console.log(err);
        });
    }

    const updateMilestoneDeliveryDateById = () => {
        let payload = {
            milestoneId: id,
            deliveryDate: milestones?.find((item) => item.id == id)?.deliveryDate
        }
        if (!payload.deliveryDate) {
            toast.warn("please provide delivery date", { toastId: "warn" })
            return;
        }
        try {
            setDisableConfirmButton(true)
            updateMilestoneDetail(payload).then(res => {
                if (res.id) {
                    props.handleSuccess()
                    setDisableConfirmButton(true)
                    getSubProjectMilestones()
                    toast.success("milestone re-planned", { toastId: "success" })
                }
            }).catch(err => {
                console.error(err);
                setDisableConfirmButton(false)
                toast.error(err.message || "something went wrong", { toastId: "error" })
            })
        } catch (err) {
            setDisableConfirmButton(false)
            toast.error(err.message || "something went wrong", { toastId: "error" })
            console.log(err)
        }
    }
    const enumerateDaysBetweenDates = (startDate, endDate) => {
        var dates = [];

        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');

        dates = addDates(dates, currDate.clone().toDate());
        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            dates = addDates(dates, currDate.clone().toDate());
        }
        return dates;
    };

    const addDates = (dates, date) => {
        let monthIndex = dates.findIndex((item) => item.month === moment(date).format('MMMM-YYYY'));
        if (monthIndex !== -1) {
            dates[monthIndex].dates.push(date);
            return dates;
        }

        dates.push({
            month: moment(date).format('MMMM-YYYY'),
            dates: [date]
        });
        return dates;
    }

    const handleDragStart = (milestone) => {
        console.log("handleDragStart");
        setDraggedMilestone(milestone);
    }

    const handleDragEnter = (date) => {
        console.log("handleDragEnter");
        setHighlightedDate(formateDate(date));
    }

    const handleDragLeave = () => {
        console.log("handleDragLeave");
    }

    const handleDragOver = () => {
        console.log("handleDragOver");
    }

    const handleDrop = (date) => {
        console.log("handleDrop", date);
        console.log("draggedMilestone", draggedMilestone);

        if (draggedMilestone !== null) {
            const updatedMilestones = [...milestones];

            console.log("updatedMilestones", updatedMilestones);
            console.log(updatedMilestones[draggedMilestone])
            updatedMilestones[draggedMilestone].deliveryDate = formateDate(date);
            setMilestones(updatedMilestones);
            setDisableConfirmButton(false)
        }

        setHighlightedDate(null);
        setDraggedMilestone(null);
        setHighlightedDate(null);
    }

    const formateDate = (date) => {
        return moment(date).format("YYYY-MM-DD");
    }



    console.log("dates", dates);


    return (
        <Modal {...props} size="lg">
            <div>
                <div className='d-flex justify-content-between align-items-center deliverable-header'>
                    <div className='deliverable-on-timeline'>
                        <p className='mb-0'>Drag deliverable on the timeline</p>
                    </div>

                    <div >
                        <button disabled={disableConfirmButton} className={`deliverable-to-confirm ${!disableConfirmButton ? "enable-button" : ""}`} onClick={() => updateMilestoneDeliveryDateById()}>Plan all deliverable to confirm</button>
                    </div>
                </div>

                <div className='d-flex'>
                    <div className='col-md-4 deliverable-list'>
                        {milestones?.map((milestone, index) => {
                            return (
                                <div
                                    className="deliverable"
                                    key={index}
                                    onDragStart={() => handleDragStart(index)}
                                    draggable={milestone.id == id} >
                                    {milestone.id != id ? <img src={LockPlain} className='lock-plain' /> : null}
                                    <h4 className='d-inline'> {milestone.name}</h4>
                                    {!milestone.deliveryDate ? <p>
                                        <img src={DragIcon} className='lock-plain' />
                                        <span>Drag on timeline to re-plan</span>
                                    </p> : <p>Planned {milestone.deliveryDate}</p>}
                                </div>
                            )
                        })
                        }
                    </div>
                    <div className='gantt-chart-container col-md-8'>
                        <div className="gantt-chart" style={{ height: '435px' }}>
                            {dates.map((item, index) => {
                                return <div className="month-container">
                                    <div className="month-name mb-2 mx-3">{item.month}</div>
                                    {console.log("month", item.month)}
                                    <div className="dates-in-month">
                                        {item.dates.map((date, index) => (
                                            <div
                                                key={index}
                                                className={`single-date ${highlightedDate === formateDate(date) ? 'highlighted' : ''}`}


                                                onDragEnter={() => handleDragEnter(date)}
                                                onDragLeave={() => handleDragLeave()}
                                                onDrop={() => handleDrop(date)}
                                                onDragOver={(e) => e.preventDefault()}
                                                style={{
                                                    backgroundPosition: `center ${(draggedMilestone + 1) * 80 - 30}px`
                                                }}
                                            >
                                                <span>{moment(date).format('D')}</span>
                                                <div className="horizontal-line" style={{ height: '330px' }} ></div>
                                                <div className="planned-icons" >
                                                    {milestones?.filter(milestone => milestone.deliveryDate === formateDate(date)).map((milestone, key) => (
                                                        <a href="javascript:void(0)" className="planned-date-checkmark">
                                                            <img src={PlannedCheckMark} style={{ marginTop: `${(milestone.index) * 80 + 20}px` }} draggable={false} alt="icon" />
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

            </div>
        </Modal>
    )
}

export default MilestoneTimelineModal;