import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import Sidebar from "../../shared/sharedComponents/sidebar";
import { GoogleMap, Polygon, Polyline, Marker, useJsApiLoader, OverlayView, InfoWindow } from '@react-google-maps/api';
import { getProjectById, getSubProjects, getProjectFilesById, getSubcontractorByProjectId, updateProjectDescription, deleteProjectFile, addProjectDocument, renameProjectDocument } from '../../services/projectServices';
import { MAP_KEY } from "../../shared/constants/mapKeyConstant";
import marker from "../../assests/image/marker-06.svg";
import subProjectNull from "../../assests/image/subProject-null.svg"
import "react-datepicker/dist/react-datepicker.css";
import TypeIcon from "../../assests/image/type-icon.svg";
import DarkArrow from "../../assests/image/dark-arrow.svg";
import point from "../../assests/image/point.svg";
import area from "../../assests/image/area.svg";
import Business from "../../assests/image/business.svg"
import Files from "../../assests/image/file.svg";
import { useParams, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Check from "../../assests/image/check.svg";
import whiteCheck from "../../assests/image/white-check.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ArrowBack from "../../assests/image/project-arrow-back.svg";
import NotFoundComponent from '../../shared/sharedComponents/NotFoundComponent.jsx';
import ConfirmationModal from "../../shared/sharedComponents/confirmationModal.jsx";
import { getSubProjectCategories } from "../../services/subProjectCategories";
import getFileIcon from "../../shared/utils/getFileIcon.jsx"
import ProjectBasicDetails from "./ProjectBasicDetails.jsx";
import ThreeDots from "../../assests/image/threeDots.svg";


function ProjectView() {
  const location = useParams();
  const libraries = ['places', 'drawing', 'geometry'];
  const [activeTab, setActiveTab] = useState("tab1");
  const [project, setProject] = useState();
  const [subProjects, setSubProjects] = useState([]);
  const [hoveredItem, setHoveredItem] = useState();
  const [hoveredItemLatLng, setHoveredItemLatLng] = useState();
  const [projectFiles, setProjectFiles] = useState([]);
  const [projectSubContractors, setProjectSubContractors] = useState([]);
  const [descriptionEditable, setDescriptionEditable] = useState();
  const [subProjectPopUp, setSubProjectPopUp] = useState(-1);
  const [isSubProject, setIsSubProject] = useState(true);
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState(15);
  const [renameIndex, setRenameIndex] = useState(-1);
  const [data, setData] = useState();
  const [dates, setDates] = useState([]);
  const [error, setError] = useState(false);
  const [height, setHeight] = useState(0);
  const [haveSubprojects, setHaveSubProjects] = useState(1);
  const [saveButton, setSaveButton] = useState(false);
  const [fileData, setFileData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState();
  const [subProjectCategories, setSubProjectCategories] = useState([]);

  const inputRefs = useRef([]);
  const mapRef = useRef();
  const textareaRef = useRef(null);
  const uploadedFileRef = useRef(null);

  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAP_KEY,
    libraries
  });

  const onLoadMap = (map) => {
    map.setMapTypeId("satellite");
    mapRef.current = map;
    map.setOptions({
      fullscreenControl: true,
      streetViewControl: false,
      zoomControl: false,
      mapTypeControlOptions: {
        mapTypeIds: [], // To remove options of map view
      },
    });
  };

  const containerStyle = {
    width: "100%",
    height: "377px"
  };

  useEffect(() => {
    getProject();
    getSubProjectsOfProject();
    getProjectFiles();
    getProjectSubContractors();
    subProjectCategory();
  }, [])


  const getProject = () => {
    getProjectById(location.id).then(result => {
      result.areaLatLng = result?.areaLatLng?.split("--") || [];
      setProject(result);
      setDates(enumerateDaysBetweenDates(result?.startDate, result?.endDate));
      setHaveSubProjects(result?.haveSubprojects);
    }).catch(err => {
      console.log(err);
      console.log("Not Found Component rendering");
      setError(true);
    });
  }



  const getSubProjectsOfProject = () => {
    getSubProjects(location.id).then(result => {

      for (let item of result) {
        item.areaLatLng = item.areaLatLng?.split("--") || [];
        item.width = moment.duration(moment(item?.endDate).diff(moment(item?.startDate))).asDays() * 10;
      }
      setSubProjects(result)

    }).catch(err => {
      if (err.response.data.message == "Data not found") {
        setIsSubProject(false);
      }
      console.log("There is some error in while getting subproject!!!")
      console.log(err);
    })
  }

  const getProjectFiles = () => {
    getProjectFilesById(location.id).then(result => {
      setProjectFiles(result);
    }).catch(err => {
      console.log(err);
    })
  }

  const getProjectSubContractors = () => {
    getSubcontractorByProjectId(location.id).then(result => {
      setProjectSubContractors(result);
    }).catch(err => {
      console.log(err);
    })
  }

  const getOverlayCoordinates = () => {
    if (project?.areaLatLng.length > 0) {
      return project.areaLatLng
        .map((latlng, index) => {
          if (typeof latlng === "string") {
            const [lat, lng] = latlng.split(", ");
            if (lat && lng) {
              return { lat: parseFloat(lat), lng: parseFloat(lng) };
            }
          }
          return null;
        })
        .filter(Boolean);
    }
    return [];
  }

  const subProjectCategory = () => {
    getSubProjectCategories().then(result => {
      const names = result.map(item => item.name);
      setSubProjectCategories(names);
    });
  }

  const overlayPath = getOverlayCoordinates();

  const getOverviewBounds = (path) => {
    const bounds = new window.google.maps.LatLngBounds();
    path.forEach((point) => bounds.extend(point));
    return bounds;
  }

  console.log("center", center);

  useEffect(() => {

    if (project?.areaLatLng?.length) {
      let lastCoordinate = project.areaLatLng[project.areaLatLng?.length - 1]?.split(",");

      let defaultCenter = {
        lat: Number(lastCoordinate[0]),
        lng: Number(lastCoordinate[1])
      }
      setCenter(defaultCenter);
    }

    setZoom(project?.currentZoom)
  }, [project])

  const geometryOptions = {
    fillOpacity: 0.3,
    fillColor: "#35f6de",
    strokeColor: "#35f6de",
    strokeWeight: 5,
  };

  const projectGeometryOptions = {
    fillOpacity: 0.3,
    fillColor: "#FCC331",
    strokeColor: "#FCC331",
    strokeWeight: 5,
  }


  const getCoordinates = (formData) => {
    if (formData.areaLatLng.length > 0) {
      return formData.areaLatLng
        .map((latlng, index) => {
          if (typeof latlng === "string") {
            const [lat, lng] = latlng.split(", ");
            if (lat && lng) {
              return { lat: parseFloat(lat), lng: parseFloat(lng) };
            }
          }
          return null;
        })
        .filter(Boolean);
    }
    return [];
  };

  const handleMouseOver = (e, item, paths) => {
    setHoveredItem(item);
    setHoveredItemLatLng(paths[0]);
  }

  const handleMouseOut = (e, item) => {
    // console.log(e, item);
    setHoveredItem(null)
  }


  useEffect(() => {
    if (descriptionEditable) {
      textareaRef.current.focus();
    }
  }, [descriptionEditable]);


  const enumerateDaysBetweenDates = (startDate, endDate) => {
    let dates = [];

    let currDate = moment(startDate).startOf('day');
    let lastDate = moment(endDate).startOf('day');

    dates = addDates(dates, currDate.clone().toDate());
    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
      dates = addDates(dates, currDate.clone().toDate());
    }

    return dates;
  };

  const addDates = (dates, date) => {
    let monthIndex = dates.findIndex((item) => item.month === moment(date).format('MMMM-YYYY'));
    if (monthIndex !== -1) {
      dates[monthIndex].dates.push(date);
      return dates;
    }

    dates.push({
      month: moment(date).format('MMMM-YYYY'),
      dates: [date]
    });
    return dates;
  }

  const formateDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  }

  const currentDate = formateDate(moment().format());


  useEffect(() => {
    if (dates && subProjects && subProjects.length > 0) {
      dates.forEach((month) => {
        month.dates.forEach((date, index) => {
          const formattedDate = formateDate(date);
          const subProjectfounded = findSubProjectByStartDate(subProjects, formattedDate);

          if (subProjectfounded !== null && subProjectfounded.length > 0) {

            if (!month.dates[index].subProjects) {
              month.dates[index].subProjects = [];
            } else {
              month.dates[index].subProjects.length = 0;
            }

            subProjectfounded.forEach((item, key) => {

              console.log("item", item);

              const startDate = moment(item.startDate);
              const endDate = moment(item.endDate);
              const duration = endDate.diff(startDate, 'days');
              const width = duration * 50;

              let indexOfSubProject = subProjects.indexOf(item);
              let topMargin = ((indexOfSubProject + 1) * 25) + (indexOfSubProject * 10);

              month.dates[index].subProjects.push({
                haveSubProject: item,
                indexOfSubProject: indexOfSubProject,
                width: width,
                top: topMargin,
              });

              setHeight((height) => { return height + 36.25; })
            })
          } else {
            month.dates[index] = { date: date };
          }
        });
      });
    }
  }, [dates, subProjects]);

  const findSubProjectByStartDate = (subProjects, formattedDate) => {
    const matchingSubProjects = [];
    for (const subProject of subProjects) {
      if (formateDate(subProject?.startDate) === formattedDate) {
        matchingSubProjects.push(subProject);
      }
    }
    return matchingSubProjects.length > 0 ? matchingSubProjects : null;
  };

  const subProjectInfo = (index) => {
    setSubProjectPopUp(index);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickedInsideDiv = event.target.closest('.subProjectPopUp');

      if (!isClickedInsideDiv) {
        // Clicked outside the div, call function to restore state
        setSubProjectPopUp(-1);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const changeFileName = (e, documentId, documentName, index) => {
    e.preventDefault();

    let newName = e.target.value;

    const updatedFiles = [...projectFiles];
    updatedFiles[index] = { ...updatedFiles[index], documentName: newName };
    setProjectFiles(updatedFiles);

    setData({
      "id": documentId,
      "documentName": newName
    })
  }

  useEffect(() => {

    if (data) {
      const debounceTimer = setTimeout(() => {
        renameProjectDocument(data);
      }, 1000);

      return () => {
        console.log('Clearing debounce timer');
        clearTimeout(debounceTimer);
      };
    }
  }, [data])

  const handleRename = (e, index) => {
    e.preventDefault();
    setRenameIndex(index);

    console.log("index", index);

    setTimeout(() => {
      const inputField = inputRefs.current[index];
      inputField.focus();
      inputField.setSelectionRange(0, 0);
    }, 100);

  }

  const handleBlur = () => {
    setRenameIndex(-1);
  };

  const handleKeyPress = (e, index) => {
    if (e.key === 'Enter') {
      setRenameIndex(-1);
    }
  };

  const deleteFile = async (e, id) => {
    e.preventDefault();
    setShowModal(true);
    setDeleteFileId(id);
  }
  const handleFileUpload = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.onchange = handleFileSelect;
    fileInput.click();
  };

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];

    let data = {
      projectId: project.id,
      documentName: selectedFile.name,
      projectFiles: selectedFile
    }

    setFileData(data);

    setTimeout(() => {
      if (uploadedFileRef.current) {
        uploadedFileRef.current.focus();
        uploadedFileRef.current.setSelectionRange(0, 0)
      }
    }, 100)

    setSaveButton(true);
  };

  const handleSaveFile = (e, fileData) => {

    e.preventDefault();

    addProjectDocument(fileData)
      .then(res => {
        getProjectFiles();
        toast.success(`${res.documentName} Uploaded Successfully`, { toastId: "success", });
      })
      .catch(err => {
        toast.error("Error", { toastId: "error", });
        console.log(err);
      })

    setFileData(null);
    setSaveButton(false);
  }


  const handleDescription = (e) => {
    e.preventDefault();
    setDescriptionEditable(!descriptionEditable);

    if ((project.description).length > 250) {
      setProject({ ...project, description: project.description.slice(0, 250) });
      toast.warn("Project description should be less than 250 characters", {
        toastId: "warn",
      });
      return;
    }

    if (descriptionEditable) {
      let updateData = {
        id: project.id,
        description: project.description
      }
      updateProjectDescription(updateData);
    }
  }

  const handleDescriptionChange = (e) => {
    e.preventDefault();
    setProject({ ...project, description: e.target.value })
  }

  if (error) {
    return <NotFoundComponent />
  }


  const handleDelete = async () => {
    try {
      await deleteProjectFile(deleteFileId);
      toast.warn("Document Deleted", { toastId: "warn", });
      getProjectFiles();
    } catch (error) {
      console.error("Error deleting file:", error);
    }
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const changeOrignalFileName = (e) => {
    let newData = { ...fileData, documentName: e.target.value };
    setFileData(newData);
  }

  const getProgressBarValue = (completedMileStone, totalMilestones) => {
    return ((completedMileStone / totalMilestones) * 100).toFixed(2)
  }

  const handleMarkerClick = (id) => {
    console.log("marker click", id);
    navigate(`/project/${project.id}/sub-project/${id}`)
  }


  // console.log("project", project);
  // console.log("subProject", subProjects);
  // console.log("projectfile", projectFiles);
  // console.log("projectSubContractors", projectSubContractors);
  // console.log("dates", dates);
  // console.log("subProjectPopUp", subProjectPopUp);
  // console.log("fileData", fileData);
  // console.log("subProjectCategories", subProjectCategories);


  return (
    <>
      <Sidebar />

      <ConfirmationModal
        show={showModal}
        title="Confirm Deletion"
        body="Are you sure you want to delete this file?"
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />

      <main id="main" className="main p-0">
        {isLoaded && project && subProjects ?
          <div className="d-flex gap-3 overview-box-top project-overview">
            <GoogleMap
              zoom={zoom}
              center={center}
              onLoad={onLoadMap}
              mapContainerStyle={containerStyle}
            // onTilesLoaded={() => setCenter(null)} 
            >
              <div className="d-flex align-items-center gap-2 ml-space">
                <div className="project-overview-box overview-box-top-label mt-0">
                  <Link to="/projects"> Project overview  </Link>
                </div>
                <div className="project-overview-box arrow-breadcum">
                  <img src={ArrowBack} alt="icon" />
                </div>
                <div className="project-overview-box">
                  <span> {project?.projectTitle}</span>
                </div>
              </div>

              {/* Main project map */}

              {project.areaType === "Area" &&
                <Polygon paths={overlayPath} options={{ ...projectGeometryOptions, zIndex: 100, clickable: true }}
                />
              }

              {project.areaType === "Path" &&
                <Polyline path={overlayPath} options={{ ...projectGeometryOptions, zIndex: 100, clickable: true }} />
              }

              {project.areaType === "Point" &&
                <Marker position={center} />
              }


              {subProjects && subProjects.length > 0 && subProjects.map((item, key) => {
                { console.log("item id ", item.id) }
                let paths = getCoordinates(item);
                switch (item.areaType) {
                  case "POINT":
                    return <Marker position={paths[0]} title={item.title} onClick={() => handleMarkerClick(item.id)} />;
                  case "PATH":
                    return <Polyline path={paths} options={{ ...geometryOptions, zIndex: 110 }} onClick={() => handleMarkerClick(item.id)} onMouseOver={(e) => handleMouseOver(e, item, paths)}
                      onMouseOut={(e) => handleMouseOut(e)} />;
                  case "AREA":
                    return <Polygon path={paths} options={{ ...geometryOptions, zIndex: 110 }} onClick={() => handleMarkerClick(item.id)} onMouseOver={(e) => handleMouseOver(e, item, paths)}
                      onMouseOut={(e) => handleMouseOut(e)} />;
                  default:
                    return null; // or handle the default case as needed
                }
              })}

              {hoveredItem && <InfoWindow
                position={{ lat: hoveredItemLatLng.lat, lng: hoveredItemLatLng.lng }}
                onCloseClick={() => setHoveredItem(null)}
              >
                <div>{hoveredItem?.title}</div>
              </InfoWindow>}

              {/* OverlayView is hidden for now i.e. Project map image */}

              {/* <OverlayView
                bounds={getOverviewBounds(overlayPath)}
                mapPaneName={OverlayView.OVERLAY_LAYER}
                getPixelPositionOffset={(width, height) => ({
                  x: 0,
                  y: 0
                })}
              >
                <div
                  style={{
                    transform: `rotate(${project?.imageRotation}deg) scale(${1})`,
                    cursor: "pointer",
                    position: "absolute",
                    top: `${project?.imageTop}%`,
                    left: `${project?.imageLeft}%`,
                    width: `${project?.imageSize}%`,
                    height: `${project?.imageSize}%`,
                    backgroundImage: `url(${process.env.REACT_APP_BASE_URL + "/" + project?.projectMap})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                  }}
                >
                </div>
              </OverlayView> */}

            </GoogleMap>
          </div>
          :
          null
        }


        <ProjectBasicDetails
          project={project}
          setProject={setProject}
        />

        {/* <div className="project-area-client">
          <div className="d-flex">
            <div className="col-md-4 rennovation-heading border-one">
              <p className="line-height-manage">
                {project?.projectTitle}
              </p>
              <h3><img src={marker} alt="image" /> {project?.lat}, {project?.lng}</h3>
              <div className="address-part" >
                <h3 className="mb-1">Sub-contrators</h3>
              </div>
              <div className="Sub-contrators-part">
                {projectSubContractors && projectSubContractors.length > 0 && projectSubContractors.map((item) => {
                  return (
                    <div className="company-name">
                      <p key={item?.id}><img src={Business} alt="icon" />{item?.companyName}</p>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="col-md-4 rennovation-heading border-one">
              <div className="d-flex justify-content-between">
                <div className="enexis">
                  <h3>Project reference</h3>
                  <p>{project?.projectReference}</p>
                </div>
                <div className="enexis">
                  <h3>Project owner</h3>
                  <p>{project?.projectOwner}</p>
                </div>
              </div>
              <div className="attachment">
                <h3 className="mb-1">Attachments</h3>
                {(projectFiles && projectFiles.length > 0) || (fileData && Object.keys(fileData).length !== 0) ?
                  <div className="attachment-autoy">
                    {projectFiles && projectFiles.length > 0 && projectFiles.map((item, index) => {
                      return <p key={item?.id} className="d-flex align-items-center justify-content-between">
                        <div className="d-flex">
                          {getFileIcon(item.documentPath)}
                          <input
                            className="input-file"
                            disabled={renameIndex !== index}
                            ref={el => inputRefs.current[index] = el}
                            value={(item.documentName)}
                            onChange={(e) => changeFileName(e, item.id, item.documentName, index)}
                          />
                        </div>
                        <div className="dropdown">
                          <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg width="12" height="14" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                            </svg>
                          </button>
                          <ul className="dropdown-menu bg-white">
                            <li><a className="dropdown-item" href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleRename(e, index);
                              }}
                            >Rename</a></li>
                            <li><a className="dropdown-item " href="#" style={{ color: "#EA503D" }} onClick={(e) => deleteFile(e, item?.id)}>Delete</a></li>
                          </ul>
                        </div>
                      </p>
                    })}

                    {fileData ?
                      <div className={`${Object.keys(fileData).length !== 0 ? 'd-block relative img-file-input' : 'd-none'}`}>
                        <input
                          ref={uploadedFileRef}
                          className="form-control edit-input-file"
                          type="text"
                          value={fileData?.documentName}
                          onChange={(e) => changeOrignalFileName(e)}
                        />
                        <img src={Files} alt="file" />
                      </div>
                      : null
                    }
                  </div>
                  :
                  <div className="none-attachment"></div>
                }
              </div>
              {saveButton ?
                <button className="savefile" onClick={(e) => handleSaveFile(e, fileData)}>Save</button>
                :
                <button className="uploadfile" onClick={(e) => handleFileUpload(e)}>Upload file</button>
              }
            </div>
            <div className="col-md-4 rennovation-heading p-20">
              <div className="d-flex justify-content-between">
                <div className="enexis">
                  <h3>Project start date</h3>
                  <p className="date-font">{moment(project?.startDate).format("DD-MM-YYYY")}</p>
                </div>
                <div className="enexis">
                  <h3>Project end date</h3>
                  <p className="date-font">{moment(project?.endDate).format("DD-MM-YYYY")}</p>
                </div>
                <div className="enexis">
                  <h3>Status</h3>
                  <button className="sub-in-created-btn">{project?.actual_status}</button>
                </div>
              </div>
              <div className="Description-text">
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="mb-0">Description</h3>
                  <button
                    className="btn btn-sm btn-light float-end"
                    onClick={(e) => handleDescription(e)}>
                    {descriptionEditable ? `Save` : `Edit`}
                  </button>
                </div>
                <textarea
                  style={{ height: "160px" }}
                  ref={textareaRef}
                  className="form-control"
                  type="text"
                  maxLength="250"
                  value={project?.description}
                  disabled={descriptionEditable ? false : true}
                  onChange={(e) => { handleDescriptionChange(e) }}
                />
              </div>
            </div>
          </div>
        </div>  */}

        {haveSubprojects ?
          <div className="project-timeline" >
            <div>
              <h2>Project timeline</h2>
              <p>Start Date</p>
              <p className="form-control line-heights">{moment(project?.startDate).format("DD-MM-YYYY")}</p>
              <p className="end-date">End date</p>
              <p className="form-control line-heights mb-0">{moment(project?.endDate).format("DD-MM-YYYY")}</p>
            </div>

            <div className="timeline-chart-container w-100 d-flex align-items-center">
              {/* <div class="ten"></div> */}
              <div className="timeline-chart">
                {isSubProject ? dates && dates.length > 0 && dates.map((item) => (
                  <div className="month-container" key={item.id}>
                    <div className="timeline-dates-in-month">
                      {item?.dates && item?.dates.length > 0 && item?.dates.map((date, index) => (
                        moment(date.date).isValid() ? (
                          <div
                            key={index}
                            className="timeline-single-date"
                          >
                            <span>{moment(date?.date ?? date).format('D')}</span>
                            {currentDate === formateDate(date?.date ?? date) ? (
                              <div className="timeline-today" style={{ height: height, minHeight: "175px" }}></div>
                            ) : (
                              <div className="timeline-horizontal-line" style={{ height: height, minHeight: "175px" }}></div>
                            )}
                            {date.hasOwnProperty('subProjects') && date.subProjects.length > 0 ? (
                              <div className="subProjectPopUp">
                                {date.subProjects.map((subProjectData, subIndex) => (
                                  <div
                                    key={subIndex}
                                    className={`${(subProjectPopUp === subProjectData?.indexOfSubProject) ? "time-line-subProject-clicked" : "time-line-subProject"}`}
                                    onClick={() => subProjectInfo(subProjectData?.indexOfSubProject)}
                                    style={{ position: "absolute", width: subProjectData?.width, top: `${subProjectData?.top}px`, left: "25px" }}
                                  >
                                    <p>
                                      <span>{subProjectData?.haveSubProject?.actual_status === "Completed" ? <img src={`${(subProjectPopUp === subProjectData?.indexOfSubProject)? whiteCheck : Check}`} alt="check" /> : null}</span>
                                      {subProjectData?.haveSubProject?.title }
                                    </p>
                                  </div>
                                ))}
                                {date.subProjects.map((subProjectData, subIndex) => (
                                  (subProjectPopUp === subProjectData?.indexOfSubProject) ? (
                                    <div
                                      key={`${subIndex}-popup`}
                                      className="time-line-subProject-popup"
                                      style={{ top: `${subProjectData?.top + 35}px`, left: "25px" }}
                                    >
                                      <div className="time-line-subProject-popup-status">
                                        <p>Status:<span>{subProjectData?.haveSubProject?.actual_status}</span></p>
                                        <div className="time-line-subProject-popup-info">
                                          <div>
                                            <div className="time-line-subProject-popup-milestone">
                                              <div style={{ marginRight: '9px', display: 'inline' }}>{subProjectData?.haveSubProject?.milestoneCount}</div>
                                              <CircularProgressbar
                                                value={getProgressBarValue(subProjectData?.haveSubProject?.completedMilestonesCount, subProjectData?.haveSubProject?.milestoneCount)}
                                                strokeWidth={50}
                                                styles={buildStyles({
                                                  strokeLinecap: "butt"
                                                })}
                                              />
                                            </div>
                                            <p className="milestones-font">Milestones</p>
                                          </div>
                                          <div>
                                            <div className="time-line-subProject-popup-milestone" style={{ minWidth: '36px' }}>
                                              {subProjectData?.haveSubProject?.nonConformitiesCount}
                                            </div>
                                            <p className="milestones-font">Non conformities</p>
                                          </div>
                                        </div>
                                        <div className="time-line-subProject-link">
                                          <Link to={`/project/${project?.id}/sub-project/${subProjectData?.haveSubProject?.id}`}>View details</Link>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null
                                ))}
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div>Waiting for date...</div>
                        )
                      ))}
                    </div>
                    <div className="timeline-month-name  mx-1">{item.month}</div>
                  </div>
                )) : (
                    <>             
                  <div className="w-100 subProject-null-new-btn">
                    <img src={subProjectNull} className="align-center-image" alt="No Subprojects" />
                     {haveSubprojects ? (
                      <Link to={`/sub-project/${project?.id}`}>
                        <button className="btn add-subprojectbtn">Add a subproject</button>
                      </Link>
                    ) : null}
                    </div>
                   </>

                )}
              </div>
            </div>
          </div>
          :
          null
        }

        {haveSubprojects ?
          <div className="all-subprojects mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Subprojects</h2>
              <Link to={`/sub-project/${project?.id}`}><button className="add-a-subproject">Add a subproject</button></Link>
            </div>

            {/* <div className="tab-buttons"> */}

            {/* <button
                onClick={() => handleTabClick("tab1")}
                className={activeTab === "tab1" ? "active" : ""}
              >
                <span>{subProjects.length}</span> All subprojects
              </button> */}


            {/* <button
              onClick={() => handleTabClick("tab2")}
              className={activeTab === "tab2" ? "active" : ""}
            >
              <span>4</span> Civil work
            </button>
            <button
              onClick={() => handleTabClick("tab3")}
              className={activeTab === "tab3" ? "active" : ""}
            >
              <span>1</span> Installation
            </button>
            <button
              onClick={() => handleTabClick("tab4")}
              className={activeTab === "tab4" ? "active" : ""}
            >
              <span>1</span> Maintenance
            </button>
*/}
            {/* </div> */}

            <div className="tab-content">
              {activeTab === "tab1" && (
                <div>
                  <table >
                    <tr>
                      <th style={{ width: "20px" }}>Type</th>
                      <th>Name</th>
                      <th>Category</th>
                      <th>Planned end date</th>
                      <th style={{textAlign:"center"}}>Total milestones</th>
                      <th style={{ width: "100px", textAlign:"center" }}>Non conformities</th>
                      <th>status</th>
                    </tr>

                    {(subProjectCategories && subProjectCategories.length > 0) && (subProjects && subProjects.length > 0) ? subProjects.map((item, index) => {
                      return (
                        <tr className="subproject-row" key={item?.id}>
                          <td>{(item?.areaType == "AREA") ?
                            <img src={area} alt="icon" /> :
                            (item?.areaType == "PATH") ?
                              <img src={TypeIcon} alt="icon" /> :
                              (item?.areaType == "POINT") ?
                                <img src={point} alt="icon" /> :
                                null
                          }
                          </td>
                          <td>{item.title}</td>
                          <td>{subProjectCategories[item.category] ?? "NA"}</td>
                          <td>{moment(item.endDate).format("DD-MM-YYYY")}</td>
                          <td align="center" className="text-center"><span className="total-milestones">
                            <CircularProgressbar
                              value={getProgressBarValue(item.completedMilestonesCount, item.milestoneCount)}
                              strokeWidth={50}
                              styles={buildStyles({
                                strokeLinecap: "butt"
                              })}
                            />
                            <span style={{ marginLeft: '4.68px' }}>{item.milestoneCount}</span></span></td>
                          <td align="center" className="text-center"><span className="non-conformities">{item.nonConformitiesCount}</span></td>
                          <td><span className={`${item.actual_status == "In-Progress" ? "btn-in-progress"
                            : item.actual_status == "Completed" ? "btn-completed"
                              : item.actual_status == "Planned" ? "planned-btn" : ""}`}>
                            {item.actual_status}</span></td>
                          <td className="relative sub-status"><Link to={`/project/${project?.id}/sub-project/${item?.id}`}><img src={ThreeDots} alt="icon" className="three-dots" />
                            <img src={DarkArrow} alt="icon" className="right-move" /></Link></td>
                        </tr>
                      )
                    }) :
                      <tr><td className="text-center" colSpan={8} >No subprojects yet</td></tr>
                    }
                  </table>
                </div>
              )}
              {activeTab === "tab2" && <div>Content for Tab 2</div>}
              {activeTab === "tab3" && <div>Content for Tab 3</div>}
            </div>
          </div>
          :
          <div className="all-subprojects mb-3">
            <div className="d-flex justify-content-between">
              <h2>Milestones</h2>
            </div>
            <div className="tab-content">
              {activeTab === "tab1" && (
                <div>
                  <table>
                    <tr>
                      <th>Name</th>
                      <th>GPS Verified</th>
                      <th>Non-conformities</th>
                      <th>Planned date</th>
                      <th>Execution date</th>
                      <th>status</th>
                    </tr>
                    <tr><td className="text-center" colSpan={6} >No Milestone yet</td></tr>
                  </table>
                </div>
              )}
            </div>
          </div>
        }
      </main >
    </>
  );
}

export default ProjectView;
