import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import YumConstruction from "../../assests/image/yum-contruction-icon.svg";
import TickIcon from "../../assests/image/tick-icon.svg";
import Renovation from "../../assests/image/project-icon.svg";
import TabMenu from "../../assests/image/bulleted-list.svg";
import GridIcon from "../../assests/image/grid-icon.svg";
import { getSubcontractor } from "../../services/projectServices"

function ProjectContractors({ props }) {
  const projectDetails = props.project;
  const addProjectDetails = props.addProjectDetails;
  const submitProject = props.submitProject;
  const [projectSubcontractor, setProjectSubcontractor] = useState(projectDetails.projectSubcontractor)
  const [listView, setListView] = useState(true)
  const [allProjectSubcontractor, setAllProjectSubcontractor] = useState([])

  const handleSelectedSubContractor = (id) => {
    let selectedSubContractor = projectSubcontractor.slice();
    if (selectedSubContractor.includes(id)) selectedSubContractor = selectedSubContractor.filter((item) => item !== id)
    else selectedSubContractor = [...selectedSubContractor, id]
    setProjectSubcontractor(selectedSubContractor)
    addProjectDetails({ projectSubcontractor: selectedSubContractor })
  }

  useEffect(() => {

    getSubcontractor().then(result => {
      console.log("subContractors", result)
      setAllProjectSubcontractor(result);
    })

  }, [])

  return (
    <>
      <div className="selected-sub-contractors">
        <div className="heading-main d-flex">
          <span>{projectSubcontractor.length}</span> Selected sub-contractors
        </div>

        {projectSubcontractor.length ? <div className="yum-construction-out-part d-flex flex-wrap">
          {projectSubcontractor.map((item) => {
            let detail = allProjectSubcontractor.find((psc) => psc.id === item)
            return (
              < div className="construction-btns m-2" key={item} >
                <p>
                  <img src={YumConstruction} alt="icon" /> {detail?.firstName} {detail?.lastName}
                </p>
              </div>
            )
          })}
        </div> : null}
      </div >

      <div className="heading-main top-50 d-flex">
        All sub-contractors
        <div className="tab-change">
          <span className={`Grid-box ${listView && "active"}`} onClick={() => setListView(true)}>
            <img src={TabMenu} alt="icon" />
          </span>
          <span className={`Grid-box ${!listView && "active"}`} onClick={() => setListView(false)}>
            <img src={GridIcon} alt="icon" />
          </span>
        </div>
      </div>

      {
        listView ?
          <div>
            <div className="flex-container border-0">
              <div className="flex-row">
                <div className="flex-cell th" style={{ width: "50%" }}>Name</div>
                <div className="flex-cell th" style={{ width: "50%" }}>Verification</div>
                <div className="flex-cell th" style={{ width: "30%" }}>Type</div>
                <div className="flex-cell th" style={{ width: "100%" }}>Assigned in</div>
                <div className="flex-cell th" style={{ width: "30%" }}>Acton</div>
              </div>
            </div>

            {allProjectSubcontractor.length ? allProjectSubcontractor?.map((subContractor) => {
              return (
                <div key={subContractor.id} className={`flex-container ${projectSubcontractor.includes(subContractor.id) && "active-tr-border"}`}>
                  <div className="flex-row table-top mt-0">
                    <div className="flex-cell" style={{ width: "50%" }}>
                      <div className="yvm-td">
                        <img src={YumConstruction} alt="icon" /> {subContractor.firstName} {subContractor.lastName}</div>
                    </div>
                    <div className="flex-cell" style={{ width: "50%" }}>

                      <span className="verified">
                        <img src={TickIcon} alt="icon" />
                        Verified by Veri <strong>Checks</strong>
                      </span>
                    </div>
                    <div className="flex-cell" style={{ width: "30%" }}>
                      <div class="groundwork">{subContractor.type}</div>
                    </div>
                    <div className="flex-cell" style={{ width: "100%" }}>
                      <div className="bg-color-renovation">
                        <div className="d-flex gap-1">
                          {subContractor.assignedSubprojectNames ?
                            subContractor.assignedSubprojectNames && subContractor.assignedSubprojectNames.split(",").map((item) => (
                              <>
                                <span className="renovation">
                                  <img src={Renovation} alt="icon" />
                                  {item}
                                </span>
                              </>
                            ))
                            :
                            <span className="renovation">
                              Projects are not assigned yet!
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="flex-cell" style={{ width: "30%" }}>
                      <button className="btn btn-primary select-btn" onClick={() => handleSelectedSubContractor(subContractor.id)}>
                        {projectSubcontractor.includes(subContractor.id) ? "Selected" : "Select contractor"}
                      </button>                    </div>
                  </div>
                </div>
              )
            }) : null}
            <Link to="sub-contractors/add">
              <button className="btn btn-primary create-another-btn" onClick={() => submitProject({ status: 'CONCEPT' })} >
                Create another sub-contractor
              </button>
            </Link>
          </div> :
          <div>
            <div className="row">

              {allProjectSubcontractor.length ? allProjectSubcontractor?.map((subContractor) => {
                return (
                  <div className="table-top col-md-3 mt-0" key={subContractor.id}>
                    <div className={`border-highlight new-grid p-3 ${projectSubcontractor.includes(subContractor.id) && "active-tr-border"}`}>
                      <div className="yvm-td">
                        <h3 style={{ marginTop: "0px" }}>Name</h3>
                        <img src={YumConstruction} alt="icon" /> {subContractor.firstName} {subContractor.lastName}
                      </div>
                      <div>
                        <h3>Verification</h3>
                        <span className="verified">
                          <img src={TickIcon} alt="icon" />
                          Verified by Veri <strong>Checks</strong>
                        </span>
                      </div>
                      <h3>Type</h3>

                      <p className="groundwork">{subContractor.type}</p>

                      <h3>Assigned in</h3>
                      <div className="bg-color-renovation">
                        <div className="d-flex flex-wrap gap-0">

                          {subContractor.assignedSubprojectNames ?
                            subContractor.assignedSubprojectNames && subContractor.assignedSubprojectNames.split(",").map((item) => (
                              <>
                                <span className="renovation">
                                  <img src={Renovation} alt="icon" />
                                  {item}
                                </span>
                              </>
                            ))
                            :
                            <span className="renovation">
                              Projects are not assigned yet!
                            </span>
                          }


                        </div>
                      </div>
                      <button className="btn btn-primary select-btn mt-3 text-center select-inline"
                        onClick={() => handleSelectedSubContractor(subContractor.id)}>
                        {projectSubcontractor.includes(subContractor.id) ? "Selected" : "Select contractor"}
                      </button>
                    </div>
                  </div>
                )
              }) : null}
            </div>
            <Link to="/sub-contractors/add">
              <button className="btn btn-primary create-another-btn" onClick={() => submitProject({ status: 'CONCEPT' })}>
                Create another sub-contractor
              </button>
            </Link>
          </div>
      }
    </>
  )
}

export default ProjectContractors;