import React from "react";
import { Link } from "react-router-dom";
import DarkArrow from "../../assests/image/dark-arrow.svg";
import Check from "../../assests/image/check.svg";
import Folder from "../../assests/image/folder.svg";
import greenCheck from "../../assests/image/greenCheck.svg";

const AllMilestone = () => {
    return (
        <main id="main" className="main milestone-back-color">
            <div className="d-flex justify-content-between">
                <h2 className="milestone-heading">Milestones</h2>
            </div>

            <div className="milestone-table">
                <table>
                    <tr>
                        <th>Name</th>
                        <th>Relation</th>
                        <th>Planned date</th>
                        <th>Non-conformities</th>
                        <th>Requires verification</th>
                        <th>status</th>
                    </tr>

                    <tr className="milestone-row-details">
                        <td className="milestone-name"> Site Preparation</td>
                        <td><div className="d-flex gap-2">
                            <span className="milestone-relation"><img src={Folder} alt="icon"/> Avenue Dubo...</span>
                            <span className="milestone-relation">Groundwork</span>
                        </div></td>
                        <td className="milestone-planned-date">01-01-2022</td>
                        <td><span className="milestone-zero-non-conformities">0</span></td>
                        <td><img src={greenCheck} alt="icon" /></td>
                        <td><button className="btn milestone-done-btn">Done</button></td>
                        <td><Link to="/milestone/1"><img src={DarkArrow} alt="icon" /></Link></td>
                    </tr>

                    <tr className="milestone-row-details">
                        <td className="milestone-name"> Site Preparation</td>
                        <td><div className="d-flex gap-2">
                            <span className="milestone-relation"><img src={Folder} alt="icon"/> Avenue Dubo...</span>
                            <span className="milestone-relation">Groundwork</span>
                        </div></td>
                        <td className="milestone-planned-date">01-01-2022</td>
                        <td><span className="milestone-zero-non-conformities">0</span></td>
                        <td><img src={greenCheck} alt="icon" /></td>
                        <td> <button className="btn milestone-done-btn">Done</button></td>
                        <td><Link to="/milestone/1"><img src={DarkArrow} alt="icon" /></Link></td>
                    </tr>

                    <tr className="milestone-row-details">
                        <td className="milestone-name"> Site Preparation</td>
                        <td>
                            <div className="d-flex gap-2">
                                <span className="milestone-relation"><img src={Folder} alt="icon"/> Avenue Dubo...</span>
                                <span className="milestone-relation">Groundwork</span>
                            </div>
                        </td>
                        <td className="milestone-planned-date">01-01-2022</td>
                        <td><span className="milestone-zero-non-conformities">0</span></td>
                        <td><img src={greenCheck} alt="icon" /></td>
                        <td><button className="btn milestone-overdue-btn">Overdue</button></td>
                        <td><Link to="/milestone/1"><img src={DarkArrow} alt="icon" /></Link></td>
                    </tr>

                    <tr className="milestone-row-details">
                        <td className="milestone-name"> Site Preparation</td>
                        <td><div className="d-flex gap-2">
                            <span className="milestone-relation"><img src={Folder} alt="icon"/> Avenue Dubo...</span>
                            <span className="milestone-relation">Groundwork</span>
                        </div></td>
                        <td className="milestone-planned-date">01-01-2022</td>
                        <td><span className="milestone-one-non-conformities">1</span></td>
                        <td><img src={greenCheck} alt="icon" /></td>
                        <td><button className="btn milestone-planned-btn">Planned</button></td>
                        <td><Link to="/milestone/1"><img src={DarkArrow} alt="icon" /></Link></td>
                    </tr>

                </table>
            </div>
        </main>
    );
};

export default AllMilestone;