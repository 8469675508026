import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IconCalender from "../../assests/image/icon-calender.svg";
import IconSelect from "../../assests/image/icon-select.svg";
import DropFileInput from "../../shared/sharedComponents/documentUploader";
import { getProjectOwner, getClients } from "../../services/projectServices"
import { toast } from 'react-toastify';

function ProjectDetails({ props }) {
  const project = props.project;
  const addProjectDetails = props.addProjectDetails;
  const changeActiveComponent = props.changeActiveComponent;

  const [formData, setFormData] = useState(project);
  const [projectOwners, setProjectOwners] = useState([]);
  const [clients, setClients] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    const containsScriptTag = /<script.*?>|<\/script>/i.test(value);

    if (!containsScriptTag) {
      console.log("true");
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      console.log("Value contains <script> tag!");
    }

  };

  // Function to format the date
  const formatDate = (date) => {

    if (date !== null) {
      const day = date?.getDate();
      const month = date?.getMonth() + 1;
      const year = date?.getFullYear();
      return `${year}-${month}-${day}`;

    } else {
      return null
    }
  };


  useEffect(() => {

    getClients().then(result => {
      setClients(result);
    })

    getProjectOwner().then(result => {
      setProjectOwners(result);
    });

  }, [])


  const checkValidation = () => {
    // Check if all required fields are filled
    const requiredFields = {
      "clientId": "Client is required",
      "projectTitle": "Project title is required",
      "startDate": "Start date is required",
      "endDate": "End date is required",
      "productOwnerId": "Product owner is required"
    };

    for (const field of Object.keys(requiredFields)) {
      if (!formData[field]) {
        toast.warn(requiredFields[field], { toastId: "warn" })
        return false;
      }
    }

    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // Check validation before submitting the form
    if (checkValidation()) {
      addProjectDetails(formData);
      console.log(formData);
      changeActiveComponent(2);
    }
  };

  const addProjectFiles = (files) => {
    //Below we are adding condition by which as user added file it will render on page
    console.log("files", files);
    setFormData({ ...formData, projectFiles: files })
  };



  return (
    <div className="row">
      <div className="col-md-6">
        <div className="box-primary">
          <div className="row">
            <div className="col-md-12">
              <h5 className="form-heading">Describe the </h5>
            </div>
          </div>
          <form onSubmit={(e) => onSubmit(e)}>
            {/* client */}
            <div className="row mt-3">
              <div className="col-md-12">
                <label htmlFor="abc" className="form-label">Client<span className="text-danger">*</span></label>
                <select
                  className="form-select"
                  name="clientId"
                  aria-label="Default select example"
                  value={formData.clientId}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option value={0}>Select Client</option>
                  {clients && clients.length > 0 && clients.map(client => (
                    <option key={client.id} value={client.id}>
                      {client.name}
                    </option>
                  )
                  )}
                  
                </select>
              </div>
            </div>

            {/* References */}
            <div className="row mt-3">
              <div className="col-md-6">
                <label className="form-label">Internal reference</label>
                <input
                  className="form-control"
                  name="internalReference"
                  value={formData.internalReference}
                  maxLength={50}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">External reference</label>
                <input
                  className="form-control"
                  name="externalReference"
                  value={formData.externalReference}
                  maxLength={50}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>
            </div>

            {/* Project Details */}
            <div className="row mt-3">
              <div className="col-md-6">
                <label className="form-label">Project title<span className="text-danger">*</span></label>
                <input
                  className="form-control"
                  name="projectTitle"
                  value={formData.projectTitle}
                  maxLength={50}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Project reference</label>
                <input
                  className="form-control"
                  name="projectReference"
                  value={formData.projectReference}
                  maxLength={50}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>
            </div>

            {/* Description */}
            <div className="row mt-3">
              <div className="col-md-12">
                <label className="form-label">Description</label>
                <textarea
                  className="form-control textarea-height"
                  name="description"
                  value={formData.description}
                  maxLength={250}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                ></textarea>
              </div>
            </div>

            {/* Start date */}
            <div className="row mt-3">
              <div className="col-md-6  relative-select">
                <label className="form-label start-date">Start date<span className="text-danger">*</span></label>
                <DatePicker
                  name="startDate"
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                  selected={formData?.startDate}
                  minDate={new Date()}
                  autoComplete="off"
                  onChange={(value) =>
                    handleChange({ target: { name: "startDate", value: formatDate(value) } })
                  }
                  onKeyDown={(e)=>e.preventDefault()}
                />
                <img src={IconCalender} alt="icon" className="icon-select" />
              </div>

              {/* End Date */}
              <div className="col-md-6  relative-select">
                <label className="form-label start-date">End date<span className="text-danger">*</span></label>
                <DatePicker
                  name="endDate"
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                  autoComplete="off"
                  selected={formData?.endDate}
                  minDate={formData?.startDate}
                  onChange={(value) =>
                    handleChange({ target: { name: "endDate", value: formatDate(value) } })
                  }
                  onKeyDown={(e)=>e.preventDefault()}
                />
                <img src={IconCalender} alt="icon" className="icon-select" />
              </div>
            </div>

            {/* Product owner */}
            <div className="row mt-3">
              <div className="col-md-12 relative-select">
                <label className="form-label">Product owner<span className="text-danger">*</span></label>
                <select
                  className="form-select"
                  name="productOwnerId"
                  aria-label="Default select example"
                  value={formData.productOwnerId}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option>Select Product owner</option>
                  {projectOwners && projectOwners.length > 0 && projectOwners.map(projectOwner => (
                    <option key={projectOwner.id} value={projectOwner.id}> {projectOwner.name}</option>
                  ))}

                </select>
                <img src={IconSelect} alt="icon" className="icon-select" />
              </div>
            </div>

            {/* Check Box */}
            <div className="row mt-4">
              <div className="col-md-12">
                <label
                  htmlFor="declaration"
                  className="d-flex justify-content-start checkbox-border"
                >
                  <input
                    type="checkbox"
                    id="declaration"
                    name="haveSubprojects"
                    checked={!formData.haveSubprojects}
                    onChange={(e) => {
                      handleChange(e);
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        haveSubprojects: e.target.checked ? 0 : 1,
                      }));
                    }}
                  />

                  <span className="declaration-text">
                    This project does not consist of sub-projects, but is
                    executable on its own.
                  </span>
                </label>
              </div>
            </div>

            {/* Submit Form */}
            <div className="row configure-project">
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn-primary btn-lg w-100 configure-project-btn"
                >
                  Configure project
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Document Upload */}
      <div className="col-md-6">
        <div className="box-primary drag-drop">
          <DropFileInput onFileChange={(files) => addProjectFiles(files)} projectFiles={formData.projectFiles} />
        </div>
      </div>
    </div>
  );
}

export default ProjectDetails;
