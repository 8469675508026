import React, { useRef, useState } from "react";
import Sidebar from "../../shared/sharedComponents/sidebar";
import DateIcon from "../../assests/image/date-icon.svg";
import AttachedIcon from "../../assests/image/attached-icon.svg";
import {
  GoogleMap,
  useJsApiLoader,
} from "@react-google-maps/api";
import { MAP_KEY } from "../../shared/constants/mapKeyConstant";
import SubProjectCompleteDetails from "./subProjectCompleteDetails";

function SubprojectDetail({ props }) {
  const libraries = ["places", "drawing", "geometry"];
  const { title, startDate, endDate, description, projectFiles, subprojectMilestones, currentZoom, areaType, areaLatLng } = props.subProject;
  const mapRef = useRef(null)
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAP_KEY,
    libraries,
  });
  const [geometryInstance, setGeometryInstance] = useState(null)
  const [center, setCenter] = useState(null)
  const containerStyle = {
    width: "100%",
    height: "50vh",
  };
  const geometryOptions = {
    fillOpacity: 0.3,
    fillColor: "#35f6de",
    strokeColor: "#35f6de",
    strokeWeight: 5,
  };
  let defaultCenter = {
    lat: 22.7196,
    lng: 75.8577,
  };
  if (areaLatLng?.length) {
    let lastCoordinate = areaLatLng[areaLatLng?.length - 1]?.split(",")
    defaultCenter = {
      lat: Number(lastCoordinate[0]),
      lng: Number(lastCoordinate[1])
    }
  }
  const [showProjectCompleteDetails, setShowProjectCompleteDetails] = useState(false);
  const onLoadMap = (map) => {
    map.setMapTypeId("satellite");
    mapRef.current = map;
    map.setOptions({
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: false,
      rotateControl: false,
      mapTypeControlOptions: {
        mapTypeIds: [], // To remove options of map view
      },
    });
    let coordinatesArray = []
    areaLatLng?.forEach((item, i) => {
      let arr = item.split(",");
      if (arr?.length > 1) coordinatesArray = [...coordinatesArray, { lat: Number(arr[0]), lng: Number(arr[1]) }]
    })
    console.log(areaType, coordinatesArray)
    if (areaType === "Point") {
      const newMarkerInstance = new window.google.maps.Marker({
        position: defaultCenter,
        map: mapRef.current,
        title: "Marker Title",
      });
      setGeometryInstance(newMarkerInstance);
    } else if (areaType === "Path") {
      const polyline = new window.google.maps.Polyline({
        path: coordinatesArray,
        map: mapRef.current,
        options: geometryOptions,
      });
      setGeometryInstance(polyline)
    } else if (areaType === "Area") {
      const polygon = new window.google.maps.Polygon({
        paths: coordinatesArray,
        map: mapRef.current,
        options: geometryOptions,
      });
      setGeometryInstance(polygon)
    }
  }

  return (
        <>
          <Sidebar />
          <main className="main">
            <div className="page-content">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h3 className="page-title">Confirm new subproject: {title}</h3>
                </div>
                <div>
                  <p>
                    <button type="submit" class="btn create-btn" onClick={() => { props?.submitSubProject()}}>
                      <span>Create subproject </span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">
                <div className="confirm-new-project-box">
                  <div className="Project-details-text margin-sub-24">
                    Subproject details
                  </div>
                  <div className="title-detail">
                    <div>
                      <h3>Title</h3>
                      <p className="renovation-avenue">{title}</p>
                    </div>
                    <div>
                      <h3>Start date</h3>
                      <p>
                        <img src={DateIcon} alt="icon" /> {startDate}
                      </p>
                    </div>
                    <div>
                      <h3>End date</h3>
                      <p>
                        <img src={DateIcon} alt="icon" /> {endDate}
                      </p>
                    </div>
                  </div>
                  <div className="discription-project-detail">
                    <h2>Description</h2>
                    <p>
                      {description}
                    </p>
                  </div>
                  <div className="d-flex flex-wrap">
                    {projectFiles?.length ? projectFiles.map((file, i) => {
                      return (
                        <div key={i} className="file-name-attached mx-1">
                          <img src={AttachedIcon} alt="icon" />
                          <p>{file?.name}</p>
                        </div>
                      )
                    }) : null}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5">
                <div className="confirm-new-project-box h-100">
                  <div className="Project-details-text margin24">
                    Planned milestones
                  </div>
                  {subprojectMilestones?.length ? subprojectMilestones.map((milestone, i) => {
                    return (
                      <div key={i} className="contractors margin10">
                        <div>
                          <div className="yum-construction">
                            <p className="text-black">{milestone.name}</p>
                          </div>
                        </div>
                        <div className="ground-work">
                          <p>{milestone.deliveryDate}</p>
                        </div>
                      </div>
                    )
                  }) : null}
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7  project-map-top ">
                <div className="confirm-new-project-box h-100">
                  <div className="Project-details-text">Subproject area</div>
                  <div className="map-project-area subproject-area">
                    <div className="map-project-area">
                      {isLoaded ?
                        <GoogleMap
                          zoom={currentZoom}
                          center={defaultCenter}
                          onLoad={onLoadMap}
                          mapContainerStyle={containerStyle}
                          onTilesLoaded={() => setCenter(null)}
                        >

                        </GoogleMap>
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </> 
  );
}

export default SubprojectDetail;
