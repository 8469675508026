import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./../../shared/sharedComponents/sidebar";
import TickIcon from "./../../assests/image/tick-icon.svg";
import Renovation from "./../../assests/image/project-icon.svg";
import YumConstruction from "./../../assests/image/yum-contruction-icon.svg";
import { getSubcontractor } from "../../services/projectServices"

function SubContractors() {
  const [subcontractors, setSubcontractors] = useState([])

  useEffect(() => {
    getSubcontractor().then(result => {
      setSubcontractors(result);
    })
  }, [])

  return (
    <>
      <Sidebar />

      <main id="main" className="main">
        <div className="sub-contractors-heading">
          <h3>Sub-contractors</h3>
          <Link to="/sub-contractors/add" className="btn-sub-contractor">Add sub-contractor</Link>
        </div>

        <div className="flex-container border-0">
          <div className="flex-row">
            <div className="flex-cell th" style={{ width: "50%" }}>
              Name
            </div>
            <div className="flex-cell th" style={{ width: "50%" }}>
              Verification
            </div>
            <div className="flex-cell th " style={{ width: "50%" }}>
              Last milestone completion
            </div>
            <div className="flex-cell th" style={{ width: "40%" }}>
              Completed milestones
            </div>
            <div className="flex-cell th" style={{ width: "80%" }}>
              Assigned to
            </div>
          </div>
        </div>

        <div className="overflow-x">
          {subcontractors.length ? subcontractors?.map((subContractor) => {
            return (
              <div key={subContractor.id} className="flex-container">
                <div className="flex-row table-top mt-0">
                  <div className="flex-cell" style={{ width: "50%" }}>
                    <div className="yvm-td">
                      <img src={YumConstruction} alt="icon" className="mr-1" />
                      {subContractor?.firstName} {subContractor?.lastName}
                    </div>
                  </div>
                  <div className="flex-cell" style={{ width: "50%" }}>
                    <span className="verified">
                      <img src={TickIcon} alt="icon" />
                      Verified by Veri <strong>Checks</strong>
                    </span>
                  </div>
                  <div className="flex-cell" style={{ width: "50%" }}>
                    <div class="groundwork text-date">{subContractor?.executionDate ? subContractor.executionDate : "-"}</div>
                  </div>
                  <div className="flex-cell mx-auto" style={{ width: "40%" }}>
                    <span className="number-text-background">{subContractor?.completedMilestonesCount}</span>
                  </div>
                  <div className="flex-cell" style={{ width: "80%" }}>
                    <div className="bg-color-renovation">
                      <div className="d-flex gap-1">

                        {subContractor.assignedSubprojectNames ?
                          subContractor.assignedSubprojectNames && subContractor.assignedSubprojectNames.split(",").map((item) => (
                            <>
                              <span className="renovation">
                                <img src={Renovation} alt="icon" />
                                {item}
                              </span>
                            </>
                          ))
                          :
                          <span className="renovation">
                           Projects are not assigned yet!
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }) : null}

        </div>
      </main>
    </>
  );
}

export default SubContractors;
