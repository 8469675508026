import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import Sidebar from "../../shared/sharedComponents/sidebar";
import SubProjectDetails from "./SubprojectDetail"
import Planning from "./Planning";
import Milestones from "./Milestones";
import SubProjectSummary from './SubprojectSummary';
import Area from "./Area";
import LockBtn from "../../assests/image/Lock-icon-btn.svg";
import { getProjectById } from '../../services/projectServices';
import { createSubProject, createMileStones, getProjectSubContractor } from '../../services/subProjectServices';
import { useParams, useNavigate } from "react-router-dom";
import { CreateSubProjectScreenTypes } from "../../shared/enums/screenTypeEnum";

function CreateSubProject() {
  const location = useParams()
  const [activeComponent, setActiveComponent] = useState(1);
  const [planningFormIsvalid, setPlanningFormIsValid] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([0]);
  const [project, setProject] = useState({})
  const [condition, setCondition] = useState(false);
  const [allProjectSubcontractor, setAllProjectSubcontractor] = useState([]);
  const [moveToPlanningBtn, setMoveToPlanningBtn] = useState(false)
  const navigate = useNavigate();
  const [subProject, setSubProject] = useState({
    projectId: null,
    title: "",
    projectReference: "",
    subcontractorId: "",
    productOwnerId: "",
    description: "",
    startDate: null,
    endDate: null,
    subprojectFiles: [],
    projectLocation: null,
    areaType: null,
    areaLatLng: [],
    boundry: 10,
    boundryUnit: "meter",
    currentZoom: 15,
    milestones: [],
    category: ""
  })

  const addSubProjectDetails = (data) => {
    if (activeComponent === 3) {
      setSubProject({ ...subProject, milestones: data });
      setActiveComponent(4)
    }
    else setSubProject({ ...subProject, ...data });
  };

  useEffect(() => {
    console.log("subProject", subProject);
  }, [subProject])


  useEffect(() => {
    (function sanetizeLatLng() {
      let updatedSubProject = {
        ...subProject, areaLatLng: subProject.areaLatLng.filter(item => Boolean(item))
      };
      setSubProject({ ...subProject, ...updatedSubProject });
    })();

  }, [activeComponent])


  const changeActiveComponent = (component) => {
    switch (component) {
      case 0:
        setActiveComponent(component);
        break;
      case 1:
        setActiveComponent(component);
        setCompletedSteps([...completedSteps])
        break;
      case 2:
        setActiveComponent(component);
        setCompletedSteps([...completedSteps, 1])
        break;
      case 3:
        setCondition(false)
        setActiveComponent(component);
        setCompletedSteps([...completedSteps, 2])
        break;
      case 4:
        setActiveComponent(component);
        setCompletedSteps([...completedSteps, 3])
        break;
      case 5:
        setActiveComponent(component);
        setCompletedSteps([...completedSteps, 4])
        break;
      default: toast.warn(`Please provide value`, { toastId: "warn" })
    }
  };

  const handlePlanningFormValidation = (milestones) => {
    setPlanningFormIsValid(true);
    setSubProject({ ...subProject, subprojectMilestones: [...milestones] })
  };

  const handleButtonClick = () => {
    // Logic for button click after form validation
    if (!subProject.subprojectMilestones?.length) toast("Please plan all milestone", { type: "warning", toastId: "milestoneWarn" })
    for (let obj of subProject.subprojectMilestones) {
      if (!obj.deliveryDate) {
        toast("Please plan all milestone", { type: "warning" })
        return null;
      }
    }
    sanetizeMilestonesLatLng();
    changeActiveComponent(CreateSubProjectScreenTypes.Summary)
  };

  const sanetizeMilestonesLatLng = () => {
    
    let updatedSubProject = {
      ...subProject, milestones: subProject.milestones.map(milestone => {
        return { ...milestone, areaLatLng: milestone.areaLatLng.filter(item => Boolean(item)) }
      })
    };
    setSubProject({ ...subProject, ...updatedSubProject });
  }

  useEffect(() => {
    console.log(location.id)
    getProjectById(location.id).then(result => {
      console.log("getProjectById", result)
      result.areaLatLng = result?.areaLatLng?.split("--") || []
      setProject(result)
      setSubProject({ ...subProject, projectId: result.id })
    }).catch(err => {
      console.log(err)
    });

    getProjectSubContractor(location.id).then(result => {
      console.log("subContractors", result)
      setAllProjectSubcontractor(result);
    }).catch(err => {
      console.log(err)
    });

  }, [])

  const submitSubProject = () => {
    console.log('Submit sub project called', subProject)
    createSubProject({ ...subProject, projectId: project.id }).then(result => {
      console.log("Project Submitted!", result);
      try {
        if (result.id) {
          subProject.milestones?.forEach(async (milestone, i) => {
            //Creating Milestones
            await createMileStones({ ...milestone, subprojectId: result.id });
          })
          navigate(`/project/${project.id}/sub-project/${result.id}`)
        }

      } catch (err) {
        console.log("error while creating milestone", err)
      }

    }).catch(err => {
      console.log(err)
    });
  }
  return (
    <>
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="">
                <a href="#">Projects <span>{`${" >"}`}</span></a>
              </li>
              <li className="breadcrumb-item">
                <a href="#"> Renovation of Highland Ave 13 <span>{`${" >"}`}</span> </a>
              </li>
              <li className="active">New subproject {activeComponent === 5 ? `:${subProject.title}` : null}</li>
            </ol>
          </nav>
        </div>
        {activeComponent !== 5 ? <div className="page-content">
          <div className="row">
            <div className="col-md-12">
              <h3 className="page-title">Subproject configuration</h3>
              <div className="d-flex align-items-center justify-content-between">
                <div className="tab-container my-4 mx-0">
                  {Object.keys(CreateSubProjectScreenTypes).map((key) => (
                    CreateSubProjectScreenTypes[key] !== CreateSubProjectScreenTypes.Summary && (
                      <button
                        key={CreateSubProjectScreenTypes[key]}
                        type="button"
                        className={`btn btn-default ${activeComponent === CreateSubProjectScreenTypes[key]
                          ? "active"
                          : ""
                          }`}
                        disabled={!completedSteps.includes(Object.keys(CreateSubProjectScreenTypes).indexOf(key))}
                        onClick={() => { changeActiveComponent(CreateSubProjectScreenTypes[key]) }}
                      >
                        {key}
                      </button>
                    )
                  ))}
                </div>
                <div>
                  {(activeComponent == CreateSubProjectScreenTypes.Milestones) ?
                    <button
                      disabled={!moveToPlanningBtn}
                      className={`${moveToPlanningBtn ? 'finalize-btn-active' : 'finalize-btn'}`}
                      onClick={(e) => { e.preventDefault(); setCondition(true);}}
                    // style={{backgroundColor: `${moveToPlanningBtn?"red":"green"}`}}
                    >
                      {!moveToPlanningBtn ? <img src={LockBtn} alt="icon" /> : null}
                      {!moveToPlanningBtn ? "Finalize all milestone" : "Proceed to planning"} 
                    </button>
                    :
                    null
                  }
                  {(activeComponent == CreateSubProjectScreenTypes.Planning) ?
                    <button className={!planningFormIsvalid ? `finalize-btn` : `finalize-btn-active`} onClick={handleButtonClick} disabled={!planningFormIsvalid}>
                      {!planningFormIsvalid ? <img src={LockBtn} alt="icon" /> : null} {!planningFormIsvalid ? 'Finalize all milestone' : 'Confirm Planning'}
                    </button>
                    :
                    null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
          : null}
        {(() => {
          switch (activeComponent) {
            case 1:
              return <SubProjectDetails props={{
                project: project,
                subProject: subProject,
                addProjectDetails: addSubProjectDetails,
                changeActiveComponent: changeActiveComponent,
                allProjectSubcontractor: allProjectSubcontractor
              }} />
            case 2:
              return <Area props={{
                subProject: subProject,
                addSubProjectDetails: addSubProjectDetails,
                project: project,
                changeActiveComponent: changeActiveComponent
              }} />
            case 3:
              return <Milestones props={{
                "addSubProjectDetails": addSubProjectDetails,
                "subProject": subProject,
                mainProjectDetails: project,
                "condition": condition,
                "setMoveToPlanningBtn": setMoveToPlanningBtn,
                allProjectSubcontractor: allProjectSubcontractor
              }} />
            case 4:
              return <Planning
                props={{ "onFormValidation": handlePlanningFormValidation, "subProject": subProject }}
              />
            case 5:
              return <SubProjectSummary props={{
                subProject: subProject,
                submitSubProject: submitSubProject,
                changeActiveComponent: changeActiveComponent,
              }} />
            default:
              return null
          }
        })()}
      </main>
    </>
  )
}

export default CreateSubProject;