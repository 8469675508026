import React, { useState, useEffect, useRef } from "react";
import DateIcon from "../../assests/image/date-icon.svg";
import YumConstruction from "../../assests/image/yum-contruction-icon.svg";
import { CreateProjectScreenTypes } from "../../shared/enums/screenTypeEnum";
import {
    GoogleMap,
    OverlayView,
    useJsApiLoader,
} from "@react-google-maps/api";
import { MAP_KEY } from "../../shared/constants/mapKeyConstant";
import { getSubcontractor } from "../../services/projectServices"


function ProjectSummary({ props }) {
    const libraries = ["places", "drawing", "geometry"];

    const { projectTitle, projectReference, internalReference, externalReference, description, currentZoom, areaLatLng, projectMap, imageLeft, imageRotation, imageSize, imageTop, endDate, startDate, projectSubcontractor, lat, lng,areaType
    } = props.project;

    console.log("props.project", props.project);

    const changeActiveComponent = props.changeActiveComponent;
    const submitProject = props.submitProject;
    const [showOverlayView, setShowOverlayView] = useState(true)
    const [center, setCenter] = useState({
        lat: parseFloat(lat),
        lng: parseFloat(lng),
    });
    const [path, setPath] = useState();
    const [polygonCenter, setPolygonCenter] = useState({});
    const [markerInstance, setMarkerInstance] = useState(null);
    const [zoom, setZoom] = useState(14);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [allProjectSubcontractor, setAllProjectSubcontractor] = useState([]);
    const mapRef = useRef();
    const polygonRefs = useRef([]);

    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: MAP_KEY,
        libraries,
    });

    const containerStyle = {
        width: "100%",
        height: "100vh",
    };

    const geometryOptions = {
        fillOpacity: 0.3,
        fillColor: "#FCC331",
        strokeColor: "#FCC331",
        strokeWeight: 5,
    };
    
    const imageScale = 1;


    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setImageLoaded(true);
        };
        img.src = projectMap;
    }, []);


    const onLoadMap = (map) => {
        map.setMapTypeId("satellite");
        mapRef.current = map;
        map.setOptions({
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapTypeControlOptions: {
                mapTypeIds: [], // To remove options of map view
            },
        });

        // If there are coordinates in the path array, create a Polygon or Polyline component
        if (path && path.length > 0) {
            if (areaType== 'Point') {
                // Single point, create a marker
                const newMarkerInstance = new window.google.maps.Marker({
                    position: center,
                    map: mapRef.current, // Use the map reference from useRef
                    title: "Marker Title", // Optionally, set a title for the marker
                });
                setMarkerInstance(newMarkerInstance);
            } else if (path.length > 1) {
                // Path or area, create a Polygon or Polyline component
                if (areaType === "Path") {
                    // Path, create a Polyline component
                    const polyline = new window.google.maps.Polyline({
                        path: path,
                        map: mapRef.current,
                        options: geometryOptions,
                    });
                    polygonRefs.current.push(polyline);
                    setMarkerInstance(polyline)
                } else if (path.length > 2) {
                    // Area, create a Polygon component
                    const polygon = new window.google.maps.Polygon({
                        paths: path,
                        map: mapRef.current,
                        options: geometryOptions,
                    });
                    polygonRefs.current.push(polygon);
                    setMarkerInstance(polygon)
                }
            }
        }
    };

    const getCoordinates = () => {
        if (areaLatLng && areaLatLng.length > 0) {

            if (areaLatLng.length == 1) {
                let arr = []
                const [lat, lng] = areaLatLng[0].split(", ")
                arr.push({ lat: parseFloat(lat) - 0.005, lng: parseFloat(lng) - .005 })
                arr.push({ lat: parseFloat(lat) - 0.005, lng: parseFloat(lng) + 0.005 })
                arr.push({ lat: parseFloat(lat) + 0.005, lng: parseFloat(lng) + 0.005 })
                arr.push({ lat: parseFloat(lat) + 0.005, lng: parseFloat(lng) - 0.005 })
                return arr;
            }


            return areaLatLng
                .map((latlng, index) => {
                    if (typeof latlng === "string") {
                        const [lat, lng] = latlng.split(", ");
                        if (lat && lng) {
                            return { lat: parseFloat(lat), lng: parseFloat(lng) };
                        }
                    }
                    return null;
                })
                .filter(Boolean);
        }
        return [];
    };

    useEffect(() => {
        if (areaLatLng) {
            const coordinates = getCoordinates();
            setPath(coordinates);
            const center = coordinates.reduce((acc, cur) => {
                acc.lat += cur.lat;
                acc.lng += cur.lng;
                return acc;
            }, { lat: 0, lng: 0 });
            center.lat /= coordinates.length;
            center.lng /= coordinates.length;
            setPolygonCenter(center)
        }
    }, [areaLatLng]);

    const getPolygonBounds = (path) => {
        const bounds = new window.google.maps.LatLngBounds();
        path.forEach((point) => bounds.extend(point));
        return bounds;
    };

    const handleZoomChanged = () => {
        if (mapRef.current) {
            setZoom(mapRef.current.getZoom());
        }
    }

    useEffect(() => {
        if (markerInstance) {
            setShowOverlayView(true);
        }
    }, [markerInstance])


    useEffect(() => {
        getSubcontractor().then(result => {
            console.log("subContractors", result)
            setAllProjectSubcontractor(result);
        })
    }, [])

    const createProject = () => {
        submitProject({ status: 'CREATED' });
    }


    return (
        <>
            <div className="page-content">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <h3 className="page-title">Confirm new Project: {projectTitle}</h3>
                    </div>
                    <div>
                        <p>
                            <a href="#" className="underline-text" onClick={() => changeActiveComponent(CreateProjectScreenTypes.Details)}>
                                Edit project details
                            </a>
                            <button type="submit" className="btn create-btn" onClick={() => createProject()}>
                                <span>Create project</span>
                            </button>
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">
                    <div className="confirm-new-project-box">
                        <div className="Project-details-text">Project details</div>
                        <div className="project-reference">
                            <div>
                                <h3>Project reference</h3>
                                <h4>{projectReference}</h4>
                            </div>
                            <div>
                                <h3>int. reference</h3>
                                <h4>{internalReference}</h4>
                            </div>
                            <div>
                                <h3>Ext. reference</h3>
                                <h4>{externalReference}</h4>
                            </div>
                        </div>
                        <hr className="space-hr"></hr>
                        <div className="title-detail">
                            <div>
                                <h3>Title</h3>
                                <p className="renovation-avenue">{projectTitle}</p>
                            </div>
                            <div>
                                <h3>Start date</h3>
                                <p><img src={DateIcon} alt="icon" />{startDate}</p>
                            </div>
                            <div>
                                <h3>End date</h3>
                                <p><img src={DateIcon} alt="icon" /> {endDate}</p>
                            </div>
                        </div>
                        <div className="discription-project-detail">
                            <h2>Description</h2>
                            <p>{description}</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5">
                    <div className="confirm-new-project-box h-100">
                        <div className="Project-details-text">Contractors</div>

                        {projectSubcontractor.map((item) => {
                            let detail = allProjectSubcontractor.find((psc) => psc.id === item);
                            return (
                                < div className="contractors" >
                                    <div>
                                        <div className="yum-construction">
                                            <p>
                                                <img src={YumConstruction} alt="icon" />  {detail?.firstName} {detail?.lastName}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ground-work">
                                        <p> {detail?.type}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 project-map-top">
                    <div className="confirm-new-project-box h-100">
                        <div className="Project-details-text">Project map & location</div>
                        <div className="map-project-area">
                            {isLoaded && path && projectMap ? (
                                <GoogleMap
                                    zoom={currentZoom}
                                    onZoomChanged={handleZoomChanged}
                                    center={center}
                                    onLoad={onLoadMap}
                                    mapContainerStyle={containerStyle}
                                    onTilesLoaded={() => { setCenter(null); setPolygonCenter(null) }}
                                >
                                    {showOverlayView && imageLoaded && (
                                        <OverlayView
                                            bounds={getPolygonBounds(path)}
                                            mapPaneName={OverlayView.OVERLAY_LAYER}
                                            getPixelPositionOffset={(width, height) => ({
                                                // x: -width / 2,
                                                // y: -height / 2,
                                                x: 0,
                                                y: 0
                                            })}
                                        >
                                            <div
                                                style={{
                                                    transform: `rotate(${imageRotation}deg) scale(${imageScale})`,
                                                    cursor: "pointer",
                                                    position: "absolute",
                                                    top: `${imageTop}%`,
                                                    left: `${imageLeft}%`,
                                                    width: `${imageSize}%`,
                                                    height: `${imageSize}%`,
                                                    zIndex: 110,
                                                    backgroundImage: `url(${projectMap})`,
                                                    backgroundSize: 'contain',
                                                    backgroundPosition: 'center',
                                                    backgroundRepeat: 'no-repeat'
                                                }}
                                            >
                                            </div>
                                        </OverlayView>
                                    )}
                                </GoogleMap>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default ProjectSummary;
