import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../shared/sharedComponents/sidebar";
import LightArrow from "../../assests/image/lightArrow.svg";
import calendar from "../../assests/image/calendar-mile.svg";
import subcontractor from "../../assests/image/sub-contractor-mile.svg";
import WhiteCheck from "../../assests/image/mile-white-check.svg";
import Cross from "../../assests/image/mile-cross.svg";
import VideoTag from "../../assests/image/video-on.svg";
import PlayIcon from '../../assests/image/play-button.svg';
import UnverifiedGps from "../../assests/image/unverfied-gps.svg";
import lockFinishInspection from "../../assests/image/lock-finish-inspection.svg";
import VectorImage from "../../assests/image/vector.svg";
import StretchIcon from '../../assests/image/stretch.svg';
import CrossIcon from '../../assests/image/cross.svg';
import NonConfirmitiesModal from "../../shared/sharedComponents/NonConfirmitiesModal";
import ReplanFollowUpModal from "../../shared/sharedComponents/ReplanFollowUpModal";
import MilestoneTimelineModal from "../../shared/sharedComponents/MilestoneTimelineModal";
import userLogo from "../../assests/image/userlogo.svg"
import { getSubContractorById } from '../../services/subContractorServices';
import { getMilestoneDetailById, getMilestoneNonConformities, getMileStoneActivitiesById, updateMilestoneDetail } from "../../services/milestoneServices";
import moment from "moment";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const Milestone = () => {
    const handle = useFullScreenHandle();
    const videoRef = useRef()
    const { subProjectId, id } = useParams()
    const [modalShow, setModalShow] = useState(false);
    const [replanModalShow, setReplanModalShow] = useState(false);
    const [milestoneTimelineModal, setMilestoneTimelineModal] = useState(false);
    const [milestoneDetail, setMileStoneDetail] = useState({
        name: "",
        description: "",
        deliveryDate: "",
        nonConformitive: "",
        fullInspectionVideo: "",
        status: ""
    })
    const [subContractorName, setSubContractorName] = useState("");
    const [videoDetails, setVideoDetails] = useState({
        duration: "00:00",
        name: ""
    })
    const [videoError, setVideoError] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [nonConformities, setNonConformities] = useState([])
    const [activeNonConformities, setActiveNonConformities] = useState(null)
    const [fullScreenImage, setFullScreenImage] = useState(false)
    const [addNonConformitiesActivityData, setAddNonConformitiesActivityData] = useState({
        nonConformitiesId: null,
        confirmExtraWork: null,
        image: null
    })
    const [milestoneActivityList, setMilestoneActivityList] = useState([])
    const [activityStatusList, setActivityStatusList] = useState([])
    const [inspectionViewed, setInspectionViewed] = useState(false)
    const [allExtraWorkHandled, setAllExtraWorkedHandled] = useState(true)
    useEffect(() => {
        getMileStoneDetail()
        getNonConformitiesDetails()
        getMileStoneActivities()
    }, [])

    const handleLoadedMetadata = (value) => {
        let obj = { ...videoDetails }
        if (value.target?.duration) {
            let duration = moment.duration(15.04, 'seconds');
            let minutes = Math.floor(duration.asMinutes());
            let seconds = Math.floor(duration.seconds());
            let formattedDuration = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            obj.duration = formattedDuration;
        }
        if (value.target?.currentSrc) {
            let arr = value.target?.currentSrc?.split("/")
            let videoName = arr[arr.length - 1]
            obj.name = videoName
        }
        setVideoDetails({ ...obj })
    }

    const handlePlayPause = () => {
        const video = videoRef.current;
        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }
        setIsPlaying(!isPlaying);
    };
    const getMileStoneDetail = () => {
        if (!id) return;
        try {
            getMilestoneDetailById(id).then(result => {
                let obj = {};
                if (result.id) {
                    obj = { ...result, deliveryDate: moment(result.deliveryDate || new Date).format("DD-MM-YYYY"), status: result.actual_status }
                    getSubContractorDetail(result.subcontractorId)
                    if (!result.fullInspectionVideo) setVideoError(true)
                }
                if (result.inspectionViewed) setInspectionViewed(true)
                setMileStoneDetail(obj)
            }).catch(err => {
                console.error(err);
            })
        } catch (err) {
            console.log(err)
        }
    }
    const getMileStoneActivities = () => {
        if (!id) return;
        try {
            getMileStoneActivitiesById(id).then(result => {
                if (Array.isArray(result) && result.length) {
                    setMilestoneActivityList(result)
                }
            }).catch(err => {
                console.error(err);
            })
        } catch (err) {
            console.log(err)
        }
    }
    const getNonConformitiesDetails = () => {
        if (!id) return;
        try {
            getMilestoneNonConformities(id).then(result => {
                if (Array.isArray(result) && result?.length) {
                    let activities = [];
                    let extraWorkHandled = true;
                    for (let item of result) {
                        if (item.extraWork) {
                            if (item.confirmExtraWork == null) extraWorkHandled = false;
                            switch (item.confirmExtraWork) {
                                case 0: activities = [...activities, { status: 0, message: `"${item.title}" denied` }];
                                    break;
                                case 1: activities = [...activities, { status: 1, message: `"${item.title}" confirmed` }];
                                    break;
                                default: activities = [...activities, { status: null, message: `handle "${item.title}"` }]
                            }
                        }
                    }
                    setAllExtraWorkedHandled(extraWorkHandled)
                    if (activities.length) setActivityStatusList([...activities])
                    setNonConformities(result)
                }
            }).catch(err => {
                console.error(err);
            })
        } catch (err) {
            console.log(err)
        }
    }
    const getSubContractorDetail = (id) => {
        try {
            getSubContractorById(id).then(res => {
                if (res.firstName) setSubContractorName(`${res.firstName} ${res.lastName}`);
            }).catch(err => {
                console.error(err);
            })
        } catch (err) {
            console.log(err)
        }
    }

    const updateInspectionViewedAPI = () => {
        let payload = {
            milestoneId: id,
            inspectionViewed: 1
        }
        try {
            updateMilestoneDetail(payload).then(res => {
                if (res.id) {
                    setInspectionViewed(true)
                }
            }).catch(err => {
                console.error(err);
            })
        } catch (err) {
            console.log(err)
        }
    }
    const handleNonConformities = (item, type) => {
        setAddNonConformitiesActivityData({
            nonConformitiesId: item.id,
            confirmExtraWork: type,
            image: item.mediaUrl || ""
        })
        setModalShow(true);
    }

    const proceedToPlanning = (desc) => {
        console.log(desc)
        setReplanModalShow(false);
        setMilestoneTimelineModal(true);
    }

    return (
        <>
            <Sidebar />
            <main id="main" className="main milestone-back-color">

                <NonConfirmitiesModal
                    show={modalShow}
                    onHide={() => { setModalShow(false); setAddNonConformitiesActivityData({}) }}
                    data={addNonConformitiesActivityData}
                    handleSuccess={() => { getNonConformitiesDetails(); setModalShow(false) }}
                />

                <ReplanFollowUpModal
                    show={replanModalShow}
                    onHide={() => setReplanModalShow(false)}
                    proceedToPlanning={proceedToPlanning}
                />

                <MilestoneTimelineModal
                    show={milestoneTimelineModal}
                    onHide={() => setMilestoneTimelineModal(false)}
                    handleSuccess={() => { getMileStoneDetail(); setMilestoneTimelineModal(false) }}
                />



                <div className="milestone-one">
                    <div className="d-flex gap-2 milestone-breadcrumb">
                        <div className="milestone-breadcrumb-name">
                            <Link><p>Project</p></Link>
                        </div>
                        <div className="milestone-breadcrumb-arrow">
                            <img src={LightArrow} alt="icon" />
                        </div>
                        <div className="milestone-breadcrumb-name">
                            <Link><p>Subproject</p></Link>
                        </div>
                        <div className="milestone-breadcrumb-arrow">
                            <img src={LightArrow} alt="icon" />
                        </div>
                        <div className="milestone-breadcrumb-name active">
                            <Link><p>Milestone</p></Link>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between milestone-head-one">
                        <div className="milestone-inspection-one">
                            <p>Finish milestone inspection:</p>
                            <div className="activity-list">
                                <button className={`btn btn-milestone-view-footage ${inspectionViewed ? "blue-background" : ""}`}>
                                    {inspectionViewed ? <img src={WhiteCheck} className="" /> : null}
                                    <span className={inspectionViewed ? "text-white" : ""}>{inspectionViewed ? "Milestone footage viewed" : "View milestone footage"}</span>
                                </button>
                                {activityStatusList?.map((item, i) => (
                                    <button className={`btn btn-milestone-view-footage ${item.status !== null ? "blue-background" : ""}`} key={i}>
                                        {item.status !== null ? <img src={WhiteCheck} className="" /> : null}
                                        <span className={item.status !== null ? "text-white" : ""}>{item.message}</span>
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className="d-flex gap-2 milestone-inspection-two">
                            <button className="btn btn-milestone-follow-up" onClick={() => setReplanModalShow(true)}>Replan follow-up</button>
                            {(inspectionViewed && allExtraWorkHandled) ? <button className="btn btn-milestone-confirm milestone-confirm">Finish milestone</button>
                                : <button className="btn btn-milestone-confirm"><img src={lockFinishInspection} alt="icon" /> Finish inspection to confirm</button>}
                        </div>
                    </div>

                    <div className="milestone-head-two d-flex justify-content-between p-3">
                        <div className="col-md-7 d-flex justify-content-between milestone-head-title">
                            <div>
                                <p>Milestone title</p>
                                <h4 className="milestone-title">{milestoneDetail?.name}</h4>
                            </div>
                            <div className="d-flex justify-content-between gap-2">
                                <div>
                                    <p>Sub-contractor</p>
                                    <div className="d-flex gap-2 justify-content-center align-items-center milestone-sub-contractor">
                                        <p><img src={subcontractor} alt="icon" />{subContractorName}</p></div>
                                </div>
                                <div>
                                    <p>Date</p>
                                    <div className="d-flex gap-2 justify-content-center align-items-center milestone-sub-contractor">
                                        <p><img src={calendar} alt="icon" />{milestoneDetail?.deliveryDate}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-5 d-flex justify-content-between gap-2 ">
                            <div className="col-md-8 milestone-gps-verification">
                                <p>Verification</p>
                                <div className=""><img src={UnverifiedGps} alt="icon" />GPS unverified</div>
                            </div>
                            <div className="col-md-4 milestone-status">
                                <p>Status</p>
                                <button className="btn btn-milestone-status">{milestoneDetail?.status}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row milestone-details-two">

                    <div className="col-md-8 milestone-footage-details">
                        {(!activeNonConformities || activeNonConformities.mediaType === "video") ?
                            <>
                                <div className="milestone-footage" >
                                    {!isPlaying ? <button onClick={handlePlayPause}><img src={PlayIcon} /></button> : null}
                                    <span >{videoDetails.name}</span>
                                    <video
                                        ref={videoRef}
                                        src={!activeNonConformities ? milestoneDetail.fullInspectionVideo : activeNonConformities.mediaUrl}
                                        height={"100%"}
                                        width={"100%"}
                                        controls={true}
                                        onLoadedMetadata={handleLoadedMetadata}
                                        onPause={() => setIsPlaying(false)}
                                        onPlay={() => {
                                            console.log("on play ")
                                            if (!inspectionViewed) updateInspectionViewedAPI()
                                            setIsPlaying(true)
                                        }}
                                        onError={(value) => {
                                            console.log("error while playing video", value)
                                        }}
                                        className={`${videoError ? "video-error" : ""}`}
                                    />
                                </div>

                                {!activeNonConformities ? <div className="milestone-description">
                                    <h6>Milestone details</h6>
                                    <p className="milestone-description-heading">DESCRIPTION</p>

                                    <div className="milestone-paragraph">
                                        <p className="milestone-paragraph-one">
                                            {milestoneDetail.description}
                                        </p>
                                    </div>
                                </div>
                                    : <div className="milestone-description">
                                        <h6>{activeNonConformities.title}</h6>
                                        <p className="milestone-description-heading">DESCRIPTION</p>

                                        <div className="milestone-paragraph">
                                            <p className="milestone-paragraph-one">
                                                {activeNonConformities.description}
                                            </p>
                                        </div>

                                    </div>
                                }
                            </>
                            : <>
                                <section className="active-non-conformities">
                                    {activeNonConformities.mediaUrl ?
                                        activeNonConformities.mediaType === "image" ?
                                            <div>
                                                <button onClick={() => { handle.enter(); setFullScreenImage(true) }} className="stretch-button">
                                                    <img src={StretchIcon} />
                                                </button>
                                                <FullScreen handle={handle} onChange={(state, handle) => {
                                                    if (!state) setFullScreenImage(state)
                                                }}>
                                                    <div className="fullscreen-section">
                                                        {fullScreenImage ? <button onClick={() => { handle.exit(); setFullScreenImage(false) }} className="close-button">
                                                            <img src={CrossIcon} />
                                                        </button> : null}
                                                        <img src={activeNonConformities?.mediaUrl} className={fullScreenImage ? "image-fullscreen" : ""} />
                                                    </div>
                                                </FullScreen>
                                            </div> : null : null}
                                    <div className="title">

                                    </div>
                                    <div className="milestone-description">
                                        <h6>{activeNonConformities.title}</h6>
                                        <p className="milestone-description-heading">DESCRIPTION</p>

                                        <div className="milestone-paragraph">
                                            <p className="milestone-paragraph-one">
                                                {activeNonConformities.description}
                                            </p>
                                        </div>

                                    </div>
                                </section>
                            </>}
                        {(milestoneActivityList && Array.isArray(milestoneActivityList)) ? milestoneActivityList?.map((item, j) => (
                            <div className="extra-work-denied">
                                <div className="d-flex gap-2 align-items-center">
                                    <img src={userLogo} alt="icon" />
                                    {[0, 1].includes(item.confirmExtraWork)} <div className={`milestone-deny-accept-name ${item.confirmExtraWork === 1 ? "milestone-confirm" : ""}`}>
                                        <p>{item.confirmExtraWork === 1 ? "Extra work confirmed" : "Extra work denied"} </p>
                                    </div>
                                    <div className="deny-accept-date">
                                        <p className="m-0">{moment(item.createdAt)?.format("DD-MM-YYYY")}</p>
                                    </div>
                                </div>
                                <div className="milestone-deny-accept-desc">
                                    <p>
                                        {item.feedback}
                                    </p>
                                </div>
                            </div>
                        )) : null}
                    </div>
                    <div className="col-md-4">
                        <div
                            className={`d-flex milestone-inspection ${activeNonConformities == null ? "active-activity" : ""}`}
                            onClick={() => setActiveNonConformities(null)}
                        >
                            <div className="d-flex justify-content-between align-items-center gap-2 w-100">
                                <p className="milestone-full-inspection-title">
                                    <span className="p-2"> <img src={VideoTag} alt="icon" /></span>
                                    Full inspection
                                </p>
                                <span className="mx-2">{videoDetails.duration}</span>
                            </div>
                            <div className="milestone-current-footage-thumbnail" height={20} width={107}>
                            </div>
                        </div>

                        <p className="milestone-non-confirmities-title">Non conformities</p>
                        <hr></hr>
                        {!nonConformities?.length ?
                            <div className="milestone-non-confirmities-none">
                                <p>No non conformities reported</p>
                            </div>
                            :
                            <>
                                {Array.isArray(nonConformities) && nonConformities?.map((item, i) => (
                                    <div className={`milestone-have-non-confirmities ${(activeNonConformities && activeNonConformities.id === item.id) ? "active-activity" : ""}`} key={item.id}>
                                        <div>
                                            <div onClick={() => setActiveNonConformities(item)} className="cursor-pointer">
                                                <div className="d-flex justify-content-between mb-3">
                                                    <div className="milestone-non-confirmities-title">{item.title}</div>
                                                    {item.extraWork ? <button className="btn btn-milestone-extra-work">Extra work</button> : null}
                                                </div>
                                                <p className="milestone-non-confirmities-description">
                                                    {item.description}
                                                </p>
                                            </div>
                                            {item.mediaUrl ? <div className="non-conformities-image">
                                                {item.mediaType === "image" ? <>
                                                    <img className="vector" src={VectorImage} />
                                                    <img src={item.mediaUrl} />
                                                </> : null}
                                            </div> : null}
                                            {item.extraWork ? <>
                                                <hr></hr>
                                                <div className="d-flex gap-2">
                                                    <button
                                                        disabled={item.confirmExtraWork === 1}
                                                        className={`btn btn-milestone-extra-confirm d-flex align-items-center ${item.confirmExtraWork === 0 ? 'd-none' : 'w-100'}`}
                                                        onClick={() => handleNonConformities(item, 1)}
                                                    >
                                                        <img src={WhiteCheck} alt="icon" />
                                                        <span className="mx-1">{item.confirmExtraWork === 1 ? "Extra work confirmed" : "Confirm extra work"}</span>
                                                    </button>
                                                    <button
                                                        disabled={item.confirmExtraWork === 0}
                                                        className={`btn btn-milestone-extra-deny d-flex align-items-center ${item.confirmExtraWork === 1 ? 'd-none' : 'w-100'}`}
                                                        onClick={() => handleNonConformities(item, 0)}
                                                    >
                                                        <img src={Cross} className="gap-2" alt="icon" />
                                                        <span className="mx-1">Denied extra work</span>
                                                    </button>

                                                </div>
                                            </> : null}
                                        </div>
                                    </div>
                                ))}

                            </>
                        }
                        {/* <div className="milestone-have-non-confirmities">
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="milestone-non-confirmities-title">Installation issue</div>
                                </div>
                                <p className="milestone-non-confirmities-description">
                                    The pipe is not firmly mounted to our system, this could be a problem in the future
                                </p>
                                <image src={Imagewrapper} alt="icon" />
                            </div> */}


                    </div>
                </div>
            </main >
        </>
    )
};
export default Milestone;   