import React, { useEffect} from "react";
import { toast } from 'react-toastify';

function SubContractorCompleteDetails({ props }) {

    useEffect(() => {
        { toast.success(`Subcontractor is sucessfull created`, { toastId: "warn" }) }
    }, [])

    return (
        <div>
         
        </div>
    )

}

export default SubContractorCompleteDetails;