const CreateProjectScreenTypes = Object.freeze({
    Details: 1,
    Location: 2,
    Map: 3,
    SubContractors: 4,
    Summary: 5
})

const CreateSubProjectScreenTypes = Object.freeze({
    Details: 1,
    Area: 2,
    Milestones: 3,
    Planning: 4,
    Summary: 5
})

const AllProjectsScreenTypes = Object.freeze({
    A: 1,
})

export { CreateProjectScreenTypes, CreateSubProjectScreenTypes, AllProjectsScreenTypes }