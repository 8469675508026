import React, { useEffect, useState } from "react";
import IconSelect from "../../assests/image/icon-select.svg";
import IconCalender from "../../assests/image/icon-calender.svg";
import DropFileInput from "../../shared/sharedComponents/documentUploader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SubContractor from "../../assests/image/sub-contractor.svg";
import { toast } from 'react-toastify';
import moment from "moment";
import { getProjectOwner } from "../../services/projectServices";
import { getSubProjectCategories } from "../../services/subProjectCategories";

function ProjectDetails({ props }) {
    const project = props.project;
    const addProjectDetails = props.addProjectDetails;
    const changeActiveComponent = props.changeActiveComponent;
    const subProject = props.subProject;
    const allProjectSubcontractor = props.allProjectSubcontractor;
    const [formData, setFormData] = useState(subProject);
    const [projectOwners, setProjectOwners] = useState([]);
    const [subProjectCategories, setSubProjectCategories] = useState([]);


    console.log("project", project);
    console.log("formData", formData);

    useEffect(() => {
        projectOwner();
        subProjectCategory();
    }, [])

    const projectOwner = () => {
        getProjectOwner().then(result => {
            console.log("ProjectOwners", result)
            setProjectOwners(result);
        });
    }

    const subProjectCategory = () => {
        getSubProjectCategories().then(result => {
            setSubProjectCategories(result);
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        // Check validation before submitting the form
        if (checkValidation()) {
            addProjectDetails(formData);
            console.log(formData);
            changeActiveComponent(2);
        }
    }

    const checkValidation = () => {
        // Check if all required fields are filled
        const requiredFields = {
            "title": "Title is required",
            "subcontractorId": "Subcontractor is required",
            "productOwnerId": "Product owner is required",
            "startDate": "Start date is required",
            "endDate": "End date is required",
            "category": "Category is required",

        };

        for (const field of Object.keys(requiredFields)) {
            if (!formData[field]) {
                toast.warn(requiredFields[field], { toastId: "warn" })
                return false;
            }
        }
        return true;
    };

    const addProjectFiles = (files) => {
        //Below we are adding condition by which as user added file it will render on page
        setFormData({ ...formData, subprojectFiles: files })
    };
    const formatDate = (date) => {
        if (!date) return null;
        return moment(date).format('YYYY-MM-DD')
    }

    return (
        < div className='row' >
            <div className='col-md-6'>
                <div className='box-primary'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h5 className='form-heading'>Describe the Subproject </h5>
                        </div>
                    </div>
                    <form onSubmit={(e) => onSubmit(e)}>

                        {/* Title */}
                        <div className='row mt-3'>
                            <div className='col-md-6'>
                                <label className='form-label'>Title<span className="text-danger">*</span></label>
                                <input className='form-control' name='title' value={formData.title} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className='col-md-6'>
                                <label className='form-label'>Project reference</label>
                                <input className='form-control' name='projectReference' value={formData.projectReference} onChange={(e) => handleChange(e)} />
                            </div>
                        </div>

                        {/* Sub contractor */}
                        <div className='row mt-3'>
                            <div className='col-md-6  relative-select'>
                                <label className='form-label'>Sub contractor<span className="text-danger">*</span></label>
                                {/* <input className='form-control' name='subContractorId' value={formData.subContractor} onChange={(e) => { handleChange(e) }} /> */}
                                <select
                                    className="form-select form-control"
                                    name="subcontractorId"
                                    aria-label="Default select example"
                                    value={formData.subContractorId}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                >
                                    <option hidden={true}>Select Sub-contractor </option>
                                    {allProjectSubcontractor && allProjectSubcontractor.length > 0 &&
                                        allProjectSubcontractor.map(psc => (
                                            <option key={psc.subcontractorId} value={psc.subcontractorId}> {psc.firstName} {psc.lastName}</option>
                                        ))}
                                </select>
                                <img src={SubContractor} alt="icon" className="icon-select" />
                            </div>
                            <div className='col-md-6 relative-select'>
                                <label className='form-label'>Product owner<span className="text-danger">*</span></label>
                                {/* <input className='form-control' name='productOwner' value={formData.productOwner} onChange={(e) => { handleChange(e) }} /> */}
                                <select
                                    className="form-select form-control"
                                    name="productOwnerId"
                                    aria-label="Default select example"
                                    value={formData.productOwnerId}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                >
                                    <option hidden={true}>Select Product owner</option>
                                    {projectOwners && projectOwners.length > 0 && projectOwners.map(projectOwner => (
                                        <option key={projectOwner.id} value={projectOwner.id}> {projectOwner.name}</option>
                                    ))}

                                </select>
                                <img src={IconSelect} alt="icon" className="icon-select" />
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col-md-12'>
                                <label className='form-label'>Category<span className="text-danger">*</span></label>
                                {/* <input className='form-control' name='category' value={formData.category} onChange={(e) => handleChange(e)} /> */}
                                <select
                                    className="form-select form-control"
                                    name="category"
                                    aria-label="Default select example"
                                    value={formData.category}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                >
                                    <option hidden={true}>Select Category</option>
                                    {subProjectCategories && subProjectCategories.length > 0 && subProjectCategories.map(categories => (
                                        <option key={categories.id} value={categories.id}> {categories.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Description */}
                        <div className='row mt-3'>
                            <div className='col-md-12'>
                                <label className='form-label'>Description</label>
                                <textarea className='form-control textarea-height' name='description' value={formData.description} onChange={(e) => { handleChange(e) }}></textarea>
                            </div>
                        </div>

                        {/* Start date */}
                        <div className='row mt-3'>
                            <div className='col-md-6  relative-select'>
                                <label className='form-label start-date'>Start date<span className="text-danger">*</span></label>
                                <DatePicker
                                    name="startDate"
                                    className='form-control'
                                    dateFormat="dd.MM.yyyy"
                                    autoComplete="off"
                                    selected={formData.startDate} //sub-project start-date
                                    minDate={project.startDate}   //project start-date
                                    maxDate={project.endDate}     //project end-date
                                    onChange={(value) => handleChange({ target: { name: 'startDate', value: formatDate(value) } })}
                                    onKeyDown={(e) => e.preventDefault()}
                                />
                                <img src={IconCalender} alt="icon" className="icon-select" />
                            </div>

                            {/* End Date */}
                            <div className='col-md-6  relative-select'>
                                <label className='form-label start-date'>End date<span className="text-danger">*</span></label>
                                <DatePicker
                                    name="endDate"
                                    className='form-control'
                                    dateFormat="dd.MM.yyyy"
                                    autoComplete="off"
                                    selected={formData.endDate}   //sub-project end-date
                                    minDate={formData.startDate}  //sub-project start-date
                                    maxDate={project.endDate}     //sub-project end-date
                                    onChange={(value) => handleChange({ target: { name: 'endDate', value: formatDate(value) } })}
                                    onKeyDown={(e) => e.preventDefault()}
                                />
                                <img src={IconCalender} alt="icon" className="icon-select" />
                            </div>
                        </div>

                        {/* Submit Form */}
                        <div className='row configure-project'>
                            <div className='col-md-12'>
                                <button type='submit' className='btn-primary btn-lg w-100 configure-project-btn'>Go to area configuration</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

            {/* Document Upload */}
            <div className='col-md-6'>
                <div className='box-primary drag-drop'>
                    <DropFileInput onFileChange={(files) => addProjectFiles(files)} projectFiles={formData.subprojectFiles} />
                </div>
            </div>
        </div >
    )
}

export default ProjectDetails;