import React, { useEffect, useState, useRef } from "react";
import { GoogleMap, Polygon, Polyline, Marker, useJsApiLoader, OverlayView } from '@react-google-maps/api';
import MainProject from "../../assests/image/main-project-area.svg";
import PlusMarker from '../../assests/image/PlusMarker.svg';
import { MAP_KEY } from "../../shared/constants/mapKeyConstant";
import { toast } from "react-toastify";
import Switch from 'react-switch';
function ProjectLocation({ props }) {
    const subProject = props.subProject;
    const addSubProjectDetails = props.addSubProjectDetails;
    const changeActiveComponent = props.changeActiveComponent;
    const project = props.project;
    let areaTypes = ['Point', 'Path', 'Area'];
    const projectAreaType = project.areaType;
    if (projectAreaType == "Path") areaTypes = ['Point', 'Path']
    if (projectAreaType == "Point") areaTypes = ['Point']
    const [areaType, setAreaType] = useState(subProject.areaType || "Point");
    const [selectedInput, setSelectedInput] = useState(-1);
    const [showError, setShowError] = useState([]);
    const [showStartModal, setShowStartModal] = useState(true);
    const [confirmProjectAreaButton, setConfirmProjectAreaButton] = useState(false);
    const [showOverlayView, setShowOverlayView] = useState(true)
    const mapRef = useRef();
    const currentListenerRef = useRef(null);
    const polygonRef = useRef(null);
    const polylineRef = useRef(null);
    const [zoom, setZoom] = useState(15);
    let defaultCenter = {
        lat: 22.7196,
        lng: 75.8577,
    };
    const libraries = ['places', 'drawing', 'geometry'];
    if (project.areaLatLng?.length) {
        let lastCoordinate = project.areaLatLng[project.areaLatLng?.length - 1]?.split(",")
        defaultCenter = {
            lat: Number(lastCoordinate[0]),
            lng: Number(lastCoordinate[1])
        }
    }
    const geometryOptions = {
        fillOpacity: 0.3,
        fillColor: "#35f6de",
        strokeColor: "#35f6de",
        strokeWeight: 5,
    };
    const projectGeometryOptions = {
        fillOpacity: 0.3,
        fillColor: "#FCC331",
        strokeColor: "#FCC331",
        strokeWeight: 5,
    }
    const [center, setCenter] = useState(defaultCenter);
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: MAP_KEY,
        libraries
    });
    const [formData, setFormData] = useState({
        projectLocation: '',
        lat: null,
        lng: null,
        areaType: '',
        areaLatLng: []
    });

    const [markerPosition, setMarkerPosition] = useState(defaultCenter)
    useEffect(() => {
        changeAreaType(areaType);
        if (subProject.areaLatLng?.length) {
            setFormData({ ...formData, areaLatLng: subProject.areaLatLng, areaType: subProject.areaType })
            setShowStartModal(false)
        }
    }, []);

    const changeAreaType = (areaType) => {
        if (currentListenerRef.current) {
            currentListenerRef.current.remove();
        }
        setShowError([]);
        setSelectedInput(-1)
        setAreaType(areaType);
        setConfirmProjectAreaButton(false);
        let areaLatLng = [];
        // switch (areaType) {
        //     case "Point":
        //         areaLatLng.push("");
        //         break;
        //     case "Path":
        //         areaLatLng.push("");
        //         areaLatLng.push("");
        //         break;
        //     case "Area":
        //         areaLatLng.push("");
        //         areaLatLng.push("");
        //         areaLatLng.push("");
        //         break;
        //     default:
        //         areaLatLng = [];
        //         break;
        // }

        setFormData({
            ...formData,
            areaType,
            areaLatLng,
        });
    };

    // const editInputField = (index) => {
    //     setSelectedInput(index);
    //     if (currentListenerRef.current) {
    //         currentListenerRef.current.remove();
    //     }

    //     currentListenerRef.current = mapRef.current.addListener('click', (event) => {
    //         const clickedLatLng = {
    //             lat: event.latLng.lat(),
    //             lng: event.latLng.lng()
    //         };
    //         if (project.areaType == "Area") {
    //             let existingArea = new window.google.maps.Polygon({ paths: overlayPath });
    //             const isInside = window.google.maps.geometry.poly.containsLocation(clickedLatLng, existingArea)
    //             if (!isInside) {
    //                 toast("Please locate coordinates inside area", { type: "warning" })
    //                 return null;
    //             }

    //         }
    //         else if (project.areaType == "Path") {
    //             let existingLine = new window.google.maps.Polyline({ path: overlayPath });
    //             //  0.00001 is value that determine how much far point will considered on polyline
    //             const isInside = window.google.maps.geometry.poly.isLocationOnEdge(clickedLatLng, existingLine, 0.00001)
    //             if (!isInside) {
    //                 toast("Please locate coordinates near line", { type: "warning" })
    //                 return null;
    //             }

    //         }
    //         setMarkerPosition(clickedLatLng)
    //         addLatLng(`${event.latLng.lat().toFixed(5)}, ${event.latLng.lng().toFixed(5)}`, index, formData);
    //     })

    //     let input = document.getElementById(`latLngInput${index}`);
    //     if (input) {
    //         input.focus();
    //     } else {
    //         console.error("Input field with ID '" + input + "' not found.");
    //     }
    // }

    const addInputField = (e, formData) => {
        e.preventDefault();
        formData.areaLatLng.push("");
        setFormData({ ...formData });
        onFocus();
    };

    const onFocus = (index) => {
        if (!index) {
            var index = formData.areaLatLng?.length - 2;
        }
        let input = document.getElementById(`latLngInput${index}`);
        if (input) {
            input.focus();
        } else {
            console.error("Input field with ID '" + input + "' not found.");
        }
    };

    const addLatLng = (latLng, index, formData) => {
        let areaLatLng = formData.areaLatLng
        areaLatLng[index] = latLng;
        setFormData({ ...formData, areaLatLng: areaLatLng });
    };

    const onSave = (e, index) => {
        setSelectedInput(-1);
        currentListenerRef.current.remove();

        let latLng = document.getElementById(`latLngInput${index}`).value;

        if (latLng && latLng != '' && latLng !== null) {
            let validation = validationCheck(latLng);
            if (!validation) {
                console.error("error: Validate lat lng");
                setShowError(current => [...current, index])
            } else {
                setShowError(oldValues => {
                    return oldValues.filter(showError => showError !== index)
                })
            }
        }

    }

    const validationCheck = (latLng) => {
        const regex = /^(?=.*?,)(?=(?:[^.]*?\.){2}[^.]*?$).*$/;
        return regex.test(latLng);
    };

    const clearInputField = (e, index, formData) => {
        e.preventDefault();
        // formData.areaLatLng[index] = ""
        formData.areaLatLng.splice(index, 1)
        setFormData({ ...formData });

        setShowError((oldValues) => {
            return oldValues.filter((showError) => showError !== index);
        });
    };

    const confirmSubProjectArea = (e) => {
        e.preventDefault();
        if (!path.length) return toast("Please select area", { type: "warning" })

        addSubProjectDetails(formData);
        changeActiveComponent(3);
    };

    const onLoadMap = (map) => {
        map.setMapTypeId("satellite");
        mapRef.current = map;
        map.setOptions({
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapTypeControlOptions: {
                mapTypeIds: [], // To remove options of map view
            },
        });
    };

    const containerStyle = {
        width: "100%",
        height: "90vh",
    };

    const getCoordinates = () => {
        if (formData.areaLatLng.length > 0) {
            return formData.areaLatLng
                .map((latlng, index) => {
                    if (typeof latlng === "string") {
                        const [lat, lng] = latlng.split(", ");
                        if (lat && lng) {
                            return { lat: parseFloat(lat), lng: parseFloat(lng) };
                        }
                    }
                    return null;
                })
                .filter(Boolean);
        }
        return [];
    };

    const path = getCoordinates();

    // Call getCoordinates to retrieve path


    const getOverlayCoordinates = () => {
        if (project.areaLatLng.length > 0) {
            return project.areaLatLng
                .map((latlng, index) => {
                    if (typeof latlng === "string") {
                        const [lat, lng] = latlng.split(", ");
                        if (lat && lng) {
                            return { lat: parseFloat(lat), lng: parseFloat(lng) };
                        }
                    }
                    return null;
                })
                .filter(Boolean);
        }
        return [];
    }
    const overlayPath = getOverlayCoordinates();

    //Below function help us to validate Confirm-Project-Area-Button i.e. when to disable and when to enable it
    const validateConfirmProjectAreaButton = () => {
        let isValid = false;
        switch (areaType) {
            case "Point":
                isValid =
                    formData.areaLatLng.length == 1 &&
                    showError.length == 0 &&
                    formData.areaLatLng.every((value) => value.trim() !== "");
                break;

            case "Path":
                isValid =
                    formData.areaLatLng.length >= 2 &&
                    showError.length == 0 &&
                    formData.areaLatLng.every((value) => value.trim() !== "");
                break;

            case "Area":
                //In case of areaType=area we checking starting 3 values of array i.e. they should not be blank
                isValid =
                    formData.areaLatLng.length >= 3 &&
                    showError.length == 0 &&
                    formData.areaLatLng.slice(0, 3).every((value) => value.trim() !== "");
                break;

            default:
                break;
        }
        setConfirmProjectAreaButton(isValid);
    };

    useEffect(() => {
        if (formData) {
            validateConfirmProjectAreaButton();
        }
    }, [formData, showError, areaType]);


    useEffect(() => {
        if (formData && path && path.length > 0) {
            calculateArea(path);
        }
    }, [formData.areaLatLng])


    // By below we can are able to get area of polygon
    const calculateArea = (polygonCoordiantes) => {

        console.log("polygonCoordiantes", polygonCoordiantes);

        switch (areaType) {
            case 'Area':
                if (window.google) {
                    const area = window.google.maps.geometry.spherical.computeArea(polygonCoordiantes);
                    setFormData({ ...formData, boundry: area });

                } else {
                    console.error('Google Maps API not loaded');
                }
                break
            case 'Path':
                if (window.google && path) {
                    const pathLength = window.google.maps.geometry.spherical.computeLength(
                        path.map(point => new window.google.maps.LatLng(point.lat, point.lng))
                    );
                    setFormData({ ...formData, boundry: pathLength });

                } else {
                    console.error('Google Maps API not loaded');
                }
                break
            case 'Point':
                if (window.google && path) {
                    setFormData({ ...formData, boundry: null });
                } else {
                    console.error('Google Maps API not loaded');
                }
                break
            default:
                return null;
        }
    };

    const getOverviewBounds = (path) => {
        const bounds = new window.google.maps.LatLngBounds();
        path.forEach((point) => bounds.extend(point));
        return bounds;
    }
    const handleMapClick = (event) => {
        console.log("handleMapClick called")
        const clickedLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        };
        if (project.areaType == "Area") {
            let existingArea = new window.google.maps.Polygon({ paths: overlayPath });
            const isInside = window.google.maps.geometry.poly.containsLocation(clickedLatLng, existingArea)
            if (!isInside) {
                toast("Please locate coordinates inside area", { type: "warning" })
                return null;
            }

        }
        else if (project.areaType == "Path") {
            let existingLine = new window.google.maps.Polyline({ path: overlayPath });
            //  0.00001 is value that determine how much far point will considered on polyline
            const isInside = window.google.maps.geometry.poly.isLocationOnEdge(clickedLatLng, existingLine, 0.00001)
            if (!isInside) {
                toast("Please locate coordinates near line", { type: "warning" })
                return null;
            }

        }

        if (areaType === "Point") {
            setMarkerPosition(clickedLatLng)
            addLatLng(`${clickedLatLng.lat?.toFixed(5)}, ${clickedLatLng.lng?.toFixed(5)}`, 0, formData);
        } else if (areaType === "Path") {
            setMarkerPosition(clickedLatLng)
            addLatLng(`${clickedLatLng.lat?.toFixed(5)}, ${clickedLatLng.lng?.toFixed(5)}`, formData.areaLatLng?.length, formData);
        } else if (areaType === "Area") {
            setMarkerPosition(clickedLatLng)
            addLatLng(`${clickedLatLng.lat?.toFixed(5)}, ${clickedLatLng.lng?.toFixed(5)}`, formData.areaLatLng?.length, formData);
        }
    }
    return (
        <div>
            <form className='map-body'>
                <div className="black-ribbon d-flex align-items-center justify-content-end">
                    <div className="d-flex align-items-center h-100 sub-project-switch">
                        <label>Show map:</label>
                        <Switch
                            checked={showOverlayView}
                            onChange={() => setShowOverlayView(!showOverlayView)}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={0}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 1px 5px rgba(35, 192, 232, 0.6)"
                            height={17}
                            width={30}
                            className="react-switch"
                            id="material-switch"
                        />
                    </div>
                    <button
                        type="submit"
                        className={`btn ${confirmProjectAreaButton == true ? null : "disabled"}`}
                        onClick={(e) => confirmSubProjectArea(e)}
                    >
                        {!confirmProjectAreaButton ? <span className="bi bi-lock-fill"></span> : null}
                        <span className="mx-1">Confirm Sub Project Area</span>
                    </button>
                </div>
                <div className="blur-bg"></div>
                <div className="map-container">
                    {isLoaded
                        ?
                        <>
                            {showStartModal ?
                                <GoogleMap
                                    zoom={zoom}
                                    center={center}
                                    onLoad={onLoadMap}
                                    mapContainerStyle={containerStyle}
                                    onTilesLoaded={() => setCenter(null)}
                                >
                                    <div className="dark-overlay area-backdrop"></div>
                                    <div className="define-the-subproject">
                                        <p className="d-flex">
                                            <img src={MainProject} alt='icon' style={{ width: "43px", height: "43px" }} />
                                            <span className=" define-pera">
                                                Define the subproject area within the project map
                                            </span>
                                        </p>
                                        <p className="pera-text">You can add location based deliverable within  this area later.</p>
                                        <a onClick={() => setShowStartModal(false)} className="start-btn">Start</a>
                                    </div>
                                </GoogleMap>
                                :
                                <GoogleMap
                                    zoom={zoom}
                                    center={center}
                                    onLoad={onLoadMap}
                                    mapContainerStyle={containerStyle}
                                    onTilesLoaded={() => setCenter(null)}
                                    onClick={handleMapClick}
                                >

                                    <div className="dark-overlay"></div>
                                    <Marker
                                        position={markerPosition}
                                        icon={{
                                            url: PlusMarker,
                                            anchor: new window.google.maps.Point(12, 12),
                                            origin: new window.google.maps.Point(0, 0),
                                            scaledSize: new window.google.maps.Size(24, 24)
                                        }}
                                    />
                                    {areaType === "Path" && (
                                        <Polyline path={path}
                                            options={{ ...geometryOptions, editable: true, zIndex: 1000 }}
                                            onMouseUp={(event) => {
                                                const newPaths = polylineRef.current.getPath().getArray().map(coord => (
                                                    `${coord.lat()?.toFixed(5)}, ${coord.lng()?.toFixed(5)}`
                                                ));
                                                setFormData({ ...formData, areaLatLng: newPaths })
                                                console.log(newPaths)
                                                console.log(event, "polygon event")
                                            }}
                                            onLoad={(poly) => {
                                                polylineRef.current = poly
                                            }}
                                        />
                                    )}
                                    {areaType === "Area" && (
                                        <Polygon paths={path}
                                            options={{ ...geometryOptions, editable: true, zIndex: 1000 }}
                                            onMouseUp={(event) => {
                                                const newPaths = polygonRef.current.getPath().getArray().map(coord => (
                                                    `${coord.lat()?.toFixed(5)}, ${coord.lng()?.toFixed(5)}`
                                                ));
                                                setFormData({ ...formData, areaLatLng: newPaths })
                                                console.log(newPaths)
                                                console.log(event, "polygon event")
                                            }}
                                            onLoad={(poly) => {
                                                polygonRef.current = poly
                                            }}
                                        />
                                    )}
                                    {project.areaType === "Area" &&
                                        <Polygon paths={overlayPath} options={{ ...projectGeometryOptions, zIndex: 100, clickable: true }} onClick={(event) => {
                                            handleMapClick(event)
                                            // const clickedLatLng = {
                                            //     lat: event.latLng.lat(),
                                            //     lng: event.latLng.lng()
                                            // };

                                            // setMarkerPosition(clickedLatLng)
                                            // addLatLng(`${event.latLng.lat().toFixed(5)}, ${event.latLng.lng().toFixed(5)}`, selectedInput, formData);

                                        }} />
                                    }
                                    {project.areaType === "Path" &&

                                        <Polyline path={overlayPath} options={{ ...projectGeometryOptions, zIndex: 100, clickable: true }}
                                            onClick={(event) => {
                                                handleMapClick(event)

                                                // console.log("called")
                                                // const clickedLatLng = {
                                                //     lat: event.latLng.lat(),
                                                //     lng: event.latLng.lng()
                                                // };
                                                // setMarkerPosition(clickedLatLng)
                                                // addLatLng(`${event.latLng.lat().toFixed(5)}, ${event.latLng.lng().toFixed(5)}`, selectedInput, formData);

                                            }}
                                        />
                                    }
                                    {showOverlayView && (<OverlayView
                                        bounds={getOverviewBounds(overlayPath)}
                                        mapPaneName={OverlayView.OVERLAY_LAYER}
                                        getPixelPositionOffset={(width, height) => ({
                                            x: 0,
                                            y: 0
                                        })}
                                    >
                                        <div
                                            style={{
                                                transform: `rotate(${project?.imageRotation}deg) scale(${1})`,
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: `${project.imageTop}%`,
                                                left: `${project.imageLeft}%`,
                                                width: `${project.imageSize}%`,
                                                height: `${project.imageSize}%`,
                                                backgroundImage: `url(${process.env.REACT_APP_BASE_URL + "/" + project.projectMap})`,
                                                backgroundSize: 'contain',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat'
                                            }}
                                        >
                                        </div>
                                    </OverlayView>)
                                    }
                                    <div className="d-flex justify-content-between">
                                        <div className="Legend">
                                            <h3>Legend</h3>
                                            <p className="d-flex align-items-center"><span className="yellow-line"></span> Project area</p>
                                            <p className="d-flex align-items-center"><span className="blue-line"></span> Subproject area</p>
                                        </div>
                                        <div className="map-zoom">
                                            <button type="button" className="btn zoom-btn" onClick={(e) => { e.preventDefault(); setZoom(zoom + 1) }}>
                                                +
                                            </button>

                                            <button type="button" className="btn zoom-btn" onClick={(e) => { e.preventDefault(); setZoom(zoom - 1) }}>
                                                -
                                            </button>
                                        </div>
                                    </div>

                                    <div className="map-area map-overlay">
                                        <div className="yellow-line blue-line"></div>
                                        <span className='form-heading'>
                                            <img src={MainProject} alt='icon' />
                                            Sub project area
                                        </span>

                                        <label className="mt-3 d-block form-label area-type">Area type</label>
                                        <div className="area-tabs">
                                            {areaTypes.map(areaType => (
                                                <button
                                                    key={areaType}
                                                    type="button"
                                                    className={`btn btn-lg ${formData.areaType === areaType ? 'active' : ''}`}
                                                    onClick={() => changeAreaType(areaType)}
                                                >
                                                    {areaType}
                                                </button>
                                            ))}
                                        </div>

                                        <div className={areaType !== "Point" ? `overflow` : null}>
                                            <label className="mt-4 d-block form-label text-yellow subproject-green">Define subproject area</label>
                                            {formData.areaLatLng.map((latLng, index) => (
                                                <div
                                                    key={index}
                                                    className={`poi-input ${latLng == "" ? "empty" : null} ${index === selectedInput ? "editable" : ""
                                                        } ${showError.includes(index) ? "show-error" : null}`}
                                                >
                                                    <span className="prefix">
                                                        {(() => {
                                                            switch (areaType) {
                                                                case "Point":
                                                                    return "POI";
                                                                case "Path":
                                                                    return `POINT ${index + 1}`;
                                                                // switch (index) {
                                                                //     case 0:
                                                                //         return "PATH A";
                                                                //     case 1:
                                                                //         return "PATH B";
                                                                //     default:
                                                                //         return null;
                                                                // }
                                                                case "Area":
                                                                    return `POINT ${index + 1}`;
                                                                default:
                                                                    return null;
                                                            }
                                                        })()}

                                                        {latLng ? <span className="dot-circle"></span> : null}
                                                    </span>

                                                    <input
                                                        type="text"
                                                        id={`latLngInput${index}`}
                                                        value={latLng}
                                                        name="latLng"
                                                        autocomplete="off"
                                                        onChange={(e) => addLatLng(e.target.value, index, formData)}
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={(e) => onSave(e, index)}
                                                        className="btn save-marker-btn"
                                                    >
                                                        <span class="bi bi-save"></span>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        // onClick={() => editInputField(index)}
                                                        onClick={() => setSelectedInput(index)}
                                                        className="btn enable-marker-btn"
                                                    >
                                                        Locate on map or enter coordinates
                                                    </button>
                                                    <button
                                                        type="button"
                                                        // onClick={() => editInputField(index)}
                                                        onClick={() => setSelectedInput(index)}
                                                        className="btn edit-btn"
                                                    >
                                                        <span class="bi bi-pencil"></span>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={(e) => clearInputField(e, index, formData)}
                                                        className="btn delete-btn"
                                                    >
                                                        <span class="bi bi-x"></span>
                                                    </button>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="d-flex gap-2">
                                            {/* Button to add input field */}
                                            {areaType !== "Point" && (
                                                <button
                                                    type="button"
                                                    className="btn main-project-area"
                                                    onClick={(e) => addInputField(e, formData)}
                                                >
                                                    <i className="bi bi-plus-circle"></i>
                                                    Enter coordinates
                                                </button>
                                            )}
                                        </div>

                                    </div>
                                </GoogleMap>
                            }
                        </>
                        :
                        null}
                </div>
            </form>
        </div>
    )
}

export default ProjectLocation;